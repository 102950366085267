<template>
  <v-container fluid>
    <SiteConfigForm
      v-model:allConfigsProp="allConfigs"
      :createMode="createMode"
      :dialog="dialog"
      @dialog="dialogBehaviour"
    ></SiteConfigForm>
    <v-row>
      <v-col cols="12">
        <v-card style="min-height: 25vh" class="text-left mt-2">
          <v-card-title><h1 class="h1">Website Configs</h1></v-card-title>
          <v-row>
            <v-col cols="6">
              <v-card-actions>
                <v-autocomplete
                  id="configs"
                  clearable
                  v-model="config"
                  :items="allConfigs"
                  item-title="name"
                  label="Select Site Config"
                  density="compact"
                  rounded="0"
                  variant="outlined"
                  return-object
                ></v-autocomplete> </v-card-actions
            ></v-col>
            <v-col cols="6">
              <v-card-actions>
                <v-btn
                  color="success"
                  variant="elevated"
                  tile
                  @click="dialogBehaviour(true, true)"
                  aria-label="Create New Site Config"
                >
                  Create New Site Config
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SiteConfigForm from "@/components/SiteConfigForm";

export default {
  name: "SiteConfig",
  computed: {
    config: {
      get() {
        return this.$store.state.siteConfigAdmin;
      },
      set(value) {
        this.$store.commit("setSiteConfigAdmin", value);
      },
    },
  },
  data: () => ({
    dialog: false,
    createMode: false,
    loading: false,
    allConfigs: [],
  }),
  components: {
    SiteConfigForm,
  },
  props: {
    model: null,
    item: {},
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.getAllConfigs();
  },
  methods: {
    dialogBehaviour(position, createMode = false) {
      if (!position) {
        this.config = null;
      }

      this.dialog = position;
      this.createMode = createMode;
    },
    getAllConfigs() {
      this.$axios
        .get("/site-config")
        .then(
          function (response) {
            // handle success
            this.allConfigs = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
  },
  watch: {
    config: {
      handler(val) {
        if (val) {
          this.dialogBehaviour(true, false);
        }
      },
    },
  },
};
</script>

<style scoped></style>
