<template>
  <v-card-text class="text-left">
    <div>Catalogue</div>
  </v-card-text>
</template>
<script>
export default {
  name: "DatastoreCatalogue",
  data: () => ({}),
};
</script>
