<template>
  <div>
    <v-dialog
      :max-width="maxWidth"
      v-model="showDynamicDialog"
      activator="parent"
    >
      <v-card class="pa-0 h-100" rounded="0">
        <!-- title bar -->
        <v-card-title
          ref="testref"
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6 pl-1 justify-center align-center"
        >
          <v-btn
            icon="mdi-close"
            variant="text"
            tile
            @click="
              showDynamicDialog = false;
              $emit('dialogCancel');
            "
            aria-label="Dialog title"
            class="mr-4"
          />
          <slot name="title">Dialog Title</slot>
        </v-card-title>
        <!-- content -->
        <v-card-text :style="'min-height:' + minHeight + 'px; overflow: auto'">
          <slot name="content">Dialog content</slot>
        </v-card-text>
        <v-divider></v-divider>
        <!-- footer -->
        <v-card-actions>
          <slot name="footer">
            <v-btn
              ref="cancelBtn"
              :color="cancelBtnColor"
              tile
              @click="
                showDynamicDialog = false;
                $emit('dialogCancel');
              "
              variant="elevated"
              :title="cancelBtnLabel"
              :aria-label="cancelBtnLabel"
            >
              <div>
                <slot name="cancelBtnTitle">CANCEL</slot>
              </div>
            </v-btn>
            <v-spacer />
            <v-btn
              ref="okBtn"
              :color="okBtnColor"
              tile
              variant="elevated"
              :title="okBtnLabel"
              :aria-label="okBtnLabel"
              @click="$emit('dialogOk')"
              :disabled="okBtnDisabled"
              :loading="okBtnSpinner"
            >
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span>
              </template>
              <div>
                <slot name="okBtnTitle">OK</slot>
              </div>
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * v-dialog with slots for title, content and standard ok/cancel buttons
 * use to cut down on markup in other components.  Currently used in DiscoveryToolReportDialog.
 * could expanded to be more adaptable for use across LI dialogs
 */
export default {
  name: "DynamicDialog",
  data: () => ({
    okBtnLabel: "",
    cancelBtnLabel: "",
  }),
  emits: ["update:showDynamicDialog", "dialogOk", "dialogCancel"],
  props: {
    /**
     * visibility of dialog passed from parent
     */
    show: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: undefined,
    },
    minHeight: {
      type: String,
      default: undefined,
    },
    okBtnDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    okBtnSpinner: {
      type: Boolean,
      required: false,
      default: false,
    },
    okBtnColor: {
      type: String,
      default: "success",
      required: false,
    },
    cancelBtnColor: {
      type: String,
      default: "error",
      required: false,
    },
  },
  methods: {},
  computed: {
    showDynamicDialog: {
      get: function () {
        //get parent v-model state
        return this.show;
      },
      set: function (newVal) {
        //update parent v-model state
        this.$emit("update:showDynamicDialog", newVal);
      },
    },
  },
  watch: {
    //watch visibility of dialog component
    show(visible) {
      if (visible) {
        //dialog is visible
        this.$nextTick(() => {
          //update button titles/aria labels to match dynamic button content
          this.okBtnLabel = this.$refs.okBtn.$el.textContent.toLowerCase();
          this.cancelBtnLabel =
            this.$refs.cancelBtn.$el.textContent.toLowerCase();
        });
      }
    },
  },
};
</script>

<style scoped></style>
