<template>
  <div>
    <v-btn
      id="createNewReportTheme"
      color="primary"
      rounded="0"
      variant="elevated"
      @click="dialog = true"
      aria-label="Create New"
    >
      Create New
    </v-btn>
    <v-dialog v-model="dialog" scrollable persistent max-width="800px">
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h6 text-center"
          max-height="70px"
          ><v-toolbar-title class="text-left"
            >Create New Report Theme</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-form ref="form" v-model="validation" lazy-validation>
            <v-container>
              <v-row style="padding-top: 15px !important">
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                  v-for="(item, key) in newReportTheme"
                  :key="key"
                  style=""
                >
                  <Field
                    :schema="fieldSchema[key]"
                    v-model:value="newReportTheme[key]"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card>
          <v-card-actions>
            <v-btn
              color="error"
              id="closeCreateThemeDialog"
              class="ml-2"
              rounded="0"
              variant="elevated"
              @click="dialog = false"
              aria-label="Close"
            >
              Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disable="this.$store.state.busy"
              class="mr-2"
              id="confirmCreate"
              color="success"
              rounded="0"
              variant="elevated"
              @click="createNewReportTheme()"
              aria-label="Create"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Field from "@/components/Fields.vue";
export default {
  name: "NAME",
  data: () => ({
    fieldSchema: {},
    dialog: false,
    newReportTheme: {},
    validation: false,
  }),
  components: {
    Field,
  },
  computed: {},
  props: {
    schema: {},
  },
  mounted() {
    // clone the schema as we do not want any changes (api errors etc) propergating anywhere where this schema is being used
    this.fieldSchema = this.$cloneDeep(this.schema);
    if (this.$store.state.config.siteConfig.site_country === "aus") {
      this.fieldSchema.country.selectItems = [
        { text: "Australia", value: "A" },
      ];
    }
  },
  methods: {
    createNewReportTheme() {
      this.resetApiValidationErrors();
      // if this passes validation then call the api
      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          // let the user know what's happening
          this.emit.emit("systemMessage", {
            title: "Creating new Theme...",
            message: "Please wait",
            timeout: -1,
            colour: "warning",
          });
          this.emit.emit("systemBusy", true);
          // make the call!
          this.$axios
            .post("/reports-themes", this.newReportTheme)
            .then(() => {
              this.emit.emit("systemMessage", {
                title: "Report Theme Creation Complete",
                message: "Success!",
                timeout: 4000,
                colour: "green",
              });

              this.$emit("newReportThemeCreated");
              this.newReportTheme = {};
              // close the dialogue
              this.dialog = false;
              this.emit.emit("systemBusy", false);
            })
            .catch((error) => {
              this.emit.emit("systemBusy", false);
              this.emit.emit("systemMessage", {
                title: "Error! Report Theme Creation Failed",
                message: error.response.data.message,
                timeout: -1,
                colour: "error",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
    buildNewReportTheme() {
      for (const key in this.fieldSchema) {
        if (this.fieldSchema[key].useInCreationMode) {
          this.newReportTheme[key] = "";
        }
      }
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog && Object.keys(this.newReportTheme).length === 0) {
          this.buildNewReportTheme();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
