<template>
  <v-dialog
    v-model="forceAggregatinoDialog"
    width="600px"
    min-height="50vh"
    max-height="900px"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        color="purple"
        size="small"
        rounded="0"
        variant="elevated"
        v-bind="props"
        @click="forceAggregatinoDialog = true"
        aria-label="force aggregation"
        class="mx-2"
      >
        force aggregation</v-btn
      >
    </template>
    <v-card>
      <v-toolbar
        :style="
          'background-color: ' + $store.state.config.siteConfig.toolbar_colour
        "
        class="text-h6"
      >
        <v-btn
          tile
          icon="mdi-close"
          class="mr-2"
          dark
          @click="forceAggregatinoDialog = false"
          aria-label="Close"
        />
        <v-toolbar-title> Force Aggregation </v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text v-if="loading" class="text-center">
          <v-spacer>
            <v-progress-circular
              :size="210"
              :width="2"
              color="#51627C"
              indeterminate
              class="text-center my-10"
            >
              Aggregating...this may take a while
            </v-progress-circular>
          </v-spacer>
        </v-card-text>
        <v-card-text v-else-if="aggregationProcessFinished">
          <v-alert style="margin: 50px 0" dense type="success"> Done </v-alert>
        </v-card-text>
        <v-card-text v-else class="instructions">
          <strong style="color: black">Instructions</strong>
          <p>
            Via this tool, you can manually run aggregation for specific areas
            and indicators of your choice.
          </p>
          <p>
            To specify the desired indicators, insert <u>their IDs</u> into a
            table called <strong>indicators_to_aggregate</strong>.
          </p>
          <p>
            To specify the areas for which you want to run the aggregation,
            insert their <u>area codes</u> into a table called
            <strong>areas_to_aggregate</strong>. Areas must be of the same
            timepoint that you specify below.
          </p>
          <p>
            Aggregation process will be ran for all the areas and indicators
            stored in those tables
          </p>
          <p>
            Once all values are set in the tables, click <strong>Start</strong>.
            Please note, it may take up to several hours if you have a large
            number of indicators or areas.
          </p>
          <p>
            You can leave this tab and continue using the site in another one
            while waiting. If something goes wrong and you encounter an error,
            don’t worry — the system will safely roll back the data to the point
            where you started, so free to run the aggregation again with the
            same or different parameters.
          </p>
          <p>Over the aggregation, it cleans up the tables for future jobs.</p>
          <v-text-field
            v-model="timepoint"
            variant="outlined"
            density="compact"
            class="pt-4"
            width="40%"
            rounded="0"
            label="Timepoint of areas"
          ></v-text-field>
        </v-card-text>
        <v-card v-if="!loading">
          <v-card-actions v-if="aggregationProcessFinished" class="text-center">
            <v-spacer>
              <v-btn
                color="purple"
                variant="elevated"
                @click="
                  aggregationProcessFinished = false;
                  timepoint = null;
                "
                rounded="0"
                width="200px"
                >go back</v-btn
              >
            </v-spacer>
          </v-card-actions>
          <v-card-actions v-else class="text-center">
            <v-spacer>
              <v-btn
                color="warning"
                variant="elevated"
                :disabled="!timepoint"
                @click="runAggregation()"
                rounded="0"
                width="200px"
                >start</v-btn
              >
            </v-spacer>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ForceAggregation",
  data() {
    return {
      forceAggregatinoDialog: false,
      timepoint: null,
      aggregationProcessFinished: false,
      loading: false,
    };
  },
  methods: {
    runAggregation() {
      this.loading = true;

      this.$axios
        .get(`/aggregate-new-type/${this.timepoint}`)
        .then(() => {
          this.aggregationProcessFinished = true;

          this.emit.emit("systemMessage", {
            title: "Success!",
            message: "Aggregation proccess finished",
            timeout: 5000,
            colour: "success",
          });
        })
        .catch((error) => {
          console.error(error.response.data);
          this.emit.emit("systemMessage", {
            title: "Something went wrong...",
            message: error.response.data?.message,
            timeout: -1,
            colour: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.instructions p {
  padding: 6px 0px;
}
</style>
