<template>
  <v-dialog
    v-model="computedBulkCustomAreaDialog"
    scrollable
    persistent
    max-width="1500px"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        variant="elevated"
        v-if="userHasAccess"
        class="mx-4"
        color="primary"
        id="areaCodesButton"
        tile
        v-bind="props"
        aria-label="Import Area Codes"
      >
        Import Area Codes
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h5"
        max-height="64px"
      >
        <v-btn
          variant="text"
          id="closeAreaCodesDialogButton"
          icon="mdi-close"
          dark
          @click="closeDialog"
          aria-label="Close"
        />
        <v-toolbar-title>Import Custom Area List</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="height: 95vw">
        <v-card-subtitle class="text-h6"
          >Bulk Upload Custom Areas
        </v-card-subtitle>
        <div class="ml-4 mt-3">
          Upload a CSV of Custom Areas and their constituent areas
        </div>
        <ul class="ml-9">
          <li><b>Headers: </b> Do not include headers within the file</li>
          <li>
            <b>Column 1 - Area Codes: </b> The first column should be the area
            codes for the custom area - these can be any area codes
          </li>
          <li>
            <b>Column 2 - Custom Area Name: </b> The second column should be the
            name of the Custom Area - please note that the custom area should be
            next to <b>each</b> area code that you wish be be included
          </li>
        </ul>
        <div class="mt-4">
          Once you have imported your csv, you will see each of your Custom
          Areas, with their constituent areas below. When you Validate & Import
          your areas, we will check that each Custom Area name is no longer than
          100 characters, and does not already exist. We will then check that
          each area code for that area is valid, and that you have no errors
          which may cause erroneous data issues, such as nested areas, or wards
          mixed in with other area types.
        </div>
        <div class="mt-4">
          If a Custom Area is free of errors, it will be created. If there are
          any issues we detect with the area, we will return it with a message
          describing the issue. Some issues such as duplication, or bad names,
          can be fixed and re-imported here. Others, such as a bad mix of areas
          can be downloaded and fixed within the file.
        </div>
        <v-card-actions class="mt-6">
          <v-row>
            <v-col cols="5">
              <v-file-input
                id="fileImport"
                v-model="importFile"
                accept=".csv"
                density="compact"
                rounded="0"
                clearable
                variant="outlined"
                label="Select File"
                class="fields"
                :error-messages="fileError"
                @change="fileError = ''"
                @click:clear="importedData = []"
              ></v-file-input>
            </v-col>
            <v-col cols="7">
              <v-btn
                variant="elevated"
                color="success"
                tile
                class="mr-4"
                :loading="importingAreas"
                @click="importCustomAreaCSV"
                aria-label="import csv"
              >
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
                Import CSV</v-btn
              >
              <v-btn
                variant="elevated"
                color="success"
                tile
                class="mr-4"
                :loading="validatingAreas"
                :disabled="canValidate"
                @click="validateAreas"
                aria-label="validate areas"
              >
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
                Validate & Import Areas</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-alert density="compact" rounded="0" :type="alertType" width="100%">
          <div class="alert-style">
            {{ alertText }}
            <v-btn
              variant="elevated"
              v-if="anyFailedValidation"
              color="info"
              tile
              @click="downloadAreas"
              aria-label="Download Areas"
              >Download Areas</v-btn
            >
          </div>
        </v-alert>
        <div class="scrollable-expansion-panels" v-if="importedData.length > 0">
          <v-row>
            <v-col cols="5">
              <v-select
                v-model="selectedCategory"
                :items="categories"
                clearable
                item-title="name"
                item-value="id"
                label="Custom Area Category"
                class="fields mt-6"
                prepend-icon="mdi-selection-multiple"
                density="compact"
                rounded="0"
                variant="outlined"
                @update:model-value="setCategories()"
              ></v-select>
            </v-col>
            <v-col cols="7" class="mt-8">
              Select a Category for the Custom Areas
            </v-col>
          </v-row>
          <v-expansion-panels v-if="!validatingAreas">
            <v-expansion-panel
              v-for="(customArea, index) in importedData"
              :key="index"
            >
              <v-expansion-panel-title
                :class="{
                  'success-header': customArea.validate_status === 'passed',
                  'error-header': customArea.validate_status === 'failed',
                }"
              >
                <div class="d-flex align-center">
                  <div>
                    <v-icon
                      v-if="customArea.validate_status === 'passed'"
                      color="green"
                      class="mr-2 mb-4 v-alert__wrapper"
                    >
                      mdi-check
                    </v-icon>
                    <v-icon
                      v-else-if="customArea.validate_status === 'failed'"
                      color="red"
                      class="mr-2 mb-4 v-alert__wrapper"
                    >
                      mdi-alert
                    </v-icon>
                    <v-icon v-else class="mr-2 mb-4 v-alert__wrapper">
                      mdi-vector-polygon
                    </v-icon>
                  </div>
                  <v-text-field
                    v-model="customArea.name"
                    class="custom-text-field"
                    counter="100"
                    required
                    density="compact"
                    rounded="0"
                    variant="outlined"
                  >
                  </v-text-field>
                </div>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-alert
                  density="compact"
                  rounded="0"
                  type="error"
                  width="100%"
                  class="mt-2"
                  v-if="customArea.errors.length > 0"
                >
                  <div class="alert-style">
                    <ul>
                      <li
                        v-for="(error, index) in customArea.errors"
                        :key="index"
                      >
                        {{ error }}
                      </li>
                    </ul>
                    <v-btn
                      variant="elevated"
                      v-if="customArea.errorAreas.length > 0"
                      aria-label="remove bad areas"
                      color="warning"
                      tile
                      @click="() => removeErrorAreas(customArea)"
                    >
                      Remove Bad Areas
                    </v-btn>
                  </div>
                </v-alert>
                <v-chip-group column>
                  <v-chip
                    v-for="(areaCode, i) in customArea.areaCodes"
                    label
                    :ripple="false"
                    :key="i"
                    :class="{
                      'error-chip': isErrorArea(customArea, areaCode),
                      'validated-chip': isValidatedArea(customArea, areaCode),
                    }"
                    style="pointer-events: none"
                    >{{ areaCode }}</v-chip
                  >
                </v-chip-group>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <v-card-actions
          v-if="validatingAreas"
          class="align-center justify-center"
        >
          <v-progress-circular
            :size="150"
            :width="3"
            color="primary"
            indeterminate
            >Importing...
          </v-progress-circular>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import Field from "@/components/Fields.vue";

export default {
  name: "BulkCustomArea",
  data: () => ({
    importFile: null,
    fileError: "",
    importingAreas: false,
    validatingAreas: false,
    importedData: [],
    selectedCategory: null,
  }),
  components: {
    // Field,
  },
  computed: {
    computedBulkCustomAreaDialog: {
      get() {
        return this.bulkCustomAreaDialog;
      },
      set(val) {
        this.$emit("update:bulkCustomAreaDialog", val);
      },
    },
    userHasAccess() {
      // check the user is ocsi
      const pattern = new RegExp(`@ocsi.co.uk$`, "i");
      return pattern.test(this.$store.state.config.userProfile.email);
    },
    canValidate() {
      if (this.importedData.length > 0) {
        return false;
      }
      return true;
    },
    alertType() {
      switch (true) {
        case this.importedData.length === 0 ||
          (this.allUnvalidated && !this.anyFailedValidation):
          return "info";
        case this.importedData.length > 0 &&
          !this.allUnvalidated &&
          this.anyFailedValidation:
          return "warning";
        case this.importedData.length > 0 &&
          !this.allUnvalidated &&
          !this.anyFailedValidation:
          return "success";
        default:
          return "success";
      }
    },
    alertText() {
      switch (true) {
        case this.importedData.length === 0:
          return "Import a CSV to start";
        case this.validatingAreas:
          return "Importing areas...";
        case this.importedData.length > 0 && this.allUnvalidated:
          return "Validate & import your areas";
        case this.importedData.length > 0 &&
          !this.allUnvalidated &&
          this.anyFailedValidation:
          return "Some areas failed validation";
        default:
          return "Bulk Import areas";
      }
    },
    allUnvalidated() {
      return this.importedData.every((item) => item.validate_status === null);
    },
    anyFailedValidation() {
      return this.importedData.some(
        (item) => item.validate_status === "failed",
      );
    },
  },
  props: {
    bulkCustomAreaDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    schema: {},
    categories: Array,
  },
  mounted() {},
  methods: {
    downloadAreas() {
      const downloadData = [];
      this.importedData.forEach((area) => {
        area.areaCodes.forEach((areaCode) => {
          // add any errors for the area in
          const rowData = [areaCode, area.name].concat(area.errors || []);
          downloadData.push(rowData);
        });
      });

      // make a date string in some recognisable way
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1;
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "_" + mm + "_" + yyyy;

      let fileTitle = "bulk_area_import_" + formattedToday;
      this.exportCSVFile(null, downloadData, fileTitle);
    },
    removeErrorAreas(customArea) {
      // remove duplicates
      customArea.areaCodes = [...new Set(customArea.areaCodes)];

      // remove those marked as duplicate from the error list
      customArea.errorAreas = customArea.errorAreas.filter(
        (areaCode) => !customArea.duplicateAreas.includes(areaCode),
      );

      // remove other error areas
      customArea.areaCodes = customArea.areaCodes.filter(
        (areaCode) => !customArea.errorAreas.includes(areaCode),
      );

      //empty out error arrays
      customArea.errorAreas = [];
      customArea.duplicateAreas = [];
      customArea.childParentError = [];

      // remove the error messages to that would have spawned these errorAreas
      customArea.errors = customArea.errors.filter((error) => {
        // error messages to do with the areas
        const areaErrors = [
          "Unkown Areas Detected",
          "Duplicate Areas Detected",
          "Areas Out of Range",
          "Child area code included with parent",
        ];

        // Check if the current error message is in the values to remove
        return !areaErrors.includes(error);
      });

      if (customArea.errors.length == 0) {
        customArea.validate_status = "passed";
      }
    },
    isErrorArea(customArea, areaCode) {
      return customArea.errorAreas.includes(areaCode);
    },
    isValidatedArea(customArea, areaCode) {
      if (
        !customArea.errorAreas.includes(areaCode) &&
        customArea.validate_status != null
      ) {
        return true;
      }
    },
    setCategories() {
      this.importedData.forEach((item) => {
        item.custom_area_category_id = this.selectedCategory;
      });
    },
    importCustomAreaCSV() {
      this.importingAreas = true;
      // if no file selected then return
      if (!this.importFile) {
        this.fileError = "Please select a file";
        this.importingAreas = false;
        return;
      }

      this.importingAreas = true;
      // make a new reader and turn the contents into an array we need
      const reader = new FileReader();
      reader.onload = () => {
        const csvText = reader.result;
        this.csvToArray(csvText);
        this.importingAreas = false;
      };

      reader.onerror = () => {
        console.error("Error reading the file.");
        this.importingAreas = false;
      };

      reader.readAsText(this.importFile);

      this.importingAreas = false;
    },
    csvToArray(csvText) {
      // break it into rows and remove any empty lines
      const rows = csvText.split("\n").filter((line) => line.trim() !== "");

      // get something to store our working out in
      const groupedCustomAreas = [];

      // start of an index counter
      let index = 0;

      // run through each line and take out the area code & name
      rows.forEach((row) => {
        // get out the values in the row, allow commas in the strings, so break up values encased in ""
        const values = row
          .match(/(?:"([^"]*(?:""[^"]*)*)"|[^",]+)(?=\s*,|\s*$)/g)
          .map((value) => value.trim().replace(/^"(.*)"$/, "$1"));

        // make sure we have at least 2 values for the row
        if (values.length >= 2) {
          // only take the first 2, as the values we need
          const [areaCode, name] = values;

          // check if the name already exists in the groupedCustomAreas array
          const existingEntry = groupedCustomAreas.find(
            (entry) => entry.name === name,
          );

          // if it exists, add the areaCode to the existing entry
          if (existingEntry) {
            existingEntry.areaCodes.push(areaCode);
          } else {
            // if it doesn't exist, create a new entry with an index and add it to the array
            groupedCustomAreas.push({
              importIndex: index++,
              name: name,
              description: null,
              custom_area_category_id: null,
              areaCodes: [areaCode],
              area_ids: [],
              type_id: 1,
              validate_status: null,
              saved: false,
              errors: [],
              errorAreas: [],
            });
          }
        } else {
          console.error("Invalid row:", row);
        }
      });

      // remove any areas that have either no name, or no areas
      const filteredCustomAreas = groupedCustomAreas.filter((customArea) => {
        return customArea.name && customArea.areaCodes.length > 0;
      });

      // set it to use
      this.importedData = filteredCustomAreas;
    },
    validateAreas() {
      // validate the areas, any that pass validation will be imported and not returned
      this.validatingAreas = true;

      this.$axios
        .post("/validate-bulk-areas", this.importedData)
        .then(
          function (response) {
            this.validatingAreas = false;
            // update our data with the results
            this.importedData = response.data.failedValidationAreas;
            this.importCount = response.data.importedCount;
            if (response.data.failedValidationAreas.length == 0) {
              // Refresh custom areas table & close
              this.$emit("getCustomAreasByCategory");
              this.closeDialog();
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            this.validatingAreas = false;
            console.error(error);
          }.bind(this),
        );
    },
    closeDialog() {
      this.importFile = null;
      this.importedData = [];
      this.computedBulkCustomAreaDialog = false;
      this.selectedCategory = null;
    },
  },
  watch: {},
};
</script>

<style>
.scrollable-expansion-panels {
  min-height: 20%;
  max-height: 60%;
  overflow-y: auto;
  overflow-x: hidden;
}
.success-header {
  background-color: rgba(76, 175, 80, 0.12);
}
.error-header {
  background-color: rgba(244, 67, 54, 0.12);
}
.error-chip {
  background-color: rgba(244, 67, 54, 0.12) !important;
}
.validated-chip {
  background-color: rgba(76, 175, 80, 0.12) !important;
}
.alert-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-text-field {
  width: 200px;
}
</style>
