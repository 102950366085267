<template>
  <div id="headerModule">
    <!--  Header  -->
    <v-card
      :style="{
        padding: padding,
      }"
      elevation="3"
      class="d-flex justify-center align-center"
      :class="{ 'pb-0': tabs?.length }"
    >
      <v-card-text :style="{ width: '100%', maxWidth: width }" class="pa-0">
        <!-- Apply the width to max widht only so we can handle small devices too -->
        <v-card-actions>
          <v-card-actions v-if="icon" class="mr-1">
            <v-icon size="23" :icon="`mdi-${icon}`" />
          </v-card-actions>
          <v-card-actions class="px-0">
            <div v-if="title" style="font-size: 24px; font-weight: 600">
              {{ title }}
            </div>
            <i class="pl-2" style="font-size: 14px">
              {{ description }}
            </i>
          </v-card-actions>
        </v-card-actions>

        <!-- Tab headers -->
        <v-card-actions v-if="tabs?.length" class="pb-0">
          <v-tabs v-model="activeTab" color="primary">
            <v-tab v-for="(tab, index) in tabs" :key="index" :value="tab.value">
              <div style="font-weight: 500">{{ tab?.title }}</div>
              <v-icon v-if="tab.icon" :icon="`mdi-${tab.icon}`" class="ml-2" />
            </v-tab>
          </v-tabs>
        </v-card-actions>
      </v-card-text>
    </v-card>

    <!--  Content of tabs  -->
    <v-card
      v-if="tabs?.length"
      class="d-flex justify-center align-center mt-2 pa-0 w-100"
      elevation="0"
    >
      <v-card-text :style="{ maxWidth: width }">
        <v-tabs-window v-model="activeTab">
          <v-tabs-window-item
            v-for="(tab, index) in tabs"
            :key="index"
            :value="tab.value"
          >
            <slot :name="tab.value" />
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "HeaderModule",
  props: {
    title: String,
    icon: String,
    description: String,
    width: {
      type: String,
      default: "100%",
    },
    padding: {
      type: String,
      default: "10px",
    },
    // example: [ { title: "Catalogue", value: "catalogue", icon: "view-dashboard", link: "https://example.com" }, ]
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    activeTab: null,
  }),
  methods: {},
  watch: {
    activeTab(newVal, oldVal) {
      const newTab = this.tabs.find((t) => t.value === newVal);

      // check if this is a link tab and redirect to the link
      if (newTab.link) {
        window.open(newTab.link, "_blank");

        // go back to the previous tab
        this.activeTab = this.tabs.find((t) => t.value === oldVal).value;
      }
    },
  },
};
</script>

<style scoped>
* {
  color: #4d4d4d;
  font-family: "Inter", sans-serif;
}
</style>
