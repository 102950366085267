<template>
  <HeaderModule
    title="Datastore"
    icon="view-dashboard"
    description="Search all indicators from the OCSI database"
    width="1224px"
    padding="10px"
    :tabs="tabs"
  >
    <template #catalogue>
      <DatastoreCatalogue />
    </template>

    <template #queryBuilder>
      <DatastoreQueryBuilder />
    </template>

    <template #favourites>
      <DatastoreFavourites />
    </template>

    <template #documentation>
      <DatastoreDocumentation />
    </template>
  </HeaderModule>
</template>
<script>
import HeaderModule from "@/components/HeaderModule.vue";
import DatastoreCatalogue from "@/components/DatastoreCatalogue.vue";
import DatastoreQueryBuilder from "@/components/DatastoreQueryBuilder.vue";
import DatastoreFavourites from "@/components/DatastoreFavourites.vue";
import DatastoreDocumentation from "@/components/DatastoreDocumentation.vue";

export default {
  name: "DatastorePage",
  components: {
    HeaderModule,
    DatastoreCatalogue,
    DatastoreQueryBuilder,
    DatastoreFavourites,
    DatastoreDocumentation,
  },
  data: () => ({
    tabs: [
      { title: "Catalogue", value: "catalogue" },
      { title: "Query builder", value: "queryBuilder" },
      { title: "Favourites", value: "favourites" },
      { title: "Documentation", value: "documentation" },
    ],
  }),
};
</script>

<style scoped></style>
