<template>
  <v-card>
    <v-dialog v-model="removeServicesDialog" width="500">
      <v-card>
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6 text-white"
        >
          <div>Remove Services</div>
        </v-card-title>
        <v-card-text justify-center class="text-center mt-4">
          <b
            >Are you sure you want to remove all services? This action cannot be
            undone.</b
          >
        </v-card-text>
        <v-card-actions>
          <v-btn
            tile
            color="error"
            variant="elevated"
            @click="remove"
            aria-label="Remove"
            >Remove</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            tile
            color="info"
            variant="elevated"
            @click="removeServicesDialog = false"
            aria-label="Cancel"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text :style="'height:' + height + 'vh'" class="overflow-y-auto">
      <v-row>
        <v-col cols="12" lg="8" md="10" sm="12">
          <v-alert
            text
            :icon="
              countDetails.serviceCount ? 'mdi-map-marker' : 'mdi-alert-circle'
            "
            variant="tonal"
            :color="countDetails.serviceCount ? 'success' : 'info'"
            class="text-start mb-4 d-flex"
          >
            <v-row class="align-center">
              <v-col class="text-start">
                <div v-if="countDetails.serviceCount">
                  You currently have {{ countDetails.serviceCount }} services in
                  {{ countDetails.typeCount }} Service Types
                </div>
                <div v-else>
                  You currently have no Services. Import some to get started.
                </div>
              </v-col>
              <v-col class="d-flex justify-end">
                <div v-if="countDetails.serviceCount" class="d-flex">
                  <v-btn
                    tile
                    class="ma-2"
                    color="success"
                    variant="elevated"
                    :loading="downloading"
                    @click="download"
                    aria-label="download"
                  >
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                    Download
                  </v-btn>
                  <v-btn
                    tile
                    class="ma-2"
                    color="error"
                    variant="elevated"
                    :loading="removing"
                    @click="removeServicesDialog = true"
                    aria-label="remove"
                  >
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                    Remove
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn
                    tile
                    color="info"
                    variant="elevated"
                    @click="openFileImporter"
                    aria-label="Find CSV"
                  >
                    Find CSV
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-alert>

          <v-card-subtitle class="text-h6 text-start"
            >Import Your Services</v-card-subtitle
          >
          <v-card-text class="text-start">
            Import your service data to begin. Imported data will replace all
            existing services, service types, and icons. This cannot be undone
            once imported.
          </v-card-text>
          <v-card-subtitle class="text-h6 text-start"
            >Columns checklist</v-card-subtitle
          >
          <v-card-text class="text-start ml-8">
            <ul
              v-if="this.$store.state.config.siteConfig.site_country === 'aus'"
            >
              <li>
                <b>Column A is for addresses:</b> You can have multiple services
                in the same address as each row in the CSV represents an
                individual point location.
              </li>
              <li>
                <b>Column B is for latitude. Column C is for longitude:</b>
                These take priority over addresses if both are provided. This is
                automatically filled in from the provided address if left empty.
              </li>
              <li>
                <b>Column D is for service types:</b>You can have an unlimited
                number of service types, each service type can be represented by
                a different icon.
              </li>
            </ul>
            <ul v-else>
              <li>
                <b>Column A is for postcodes:</b> The postcode column is the
                only required field. You can have multiple point locations in
                the same postcode as each row in the CSV represents an
                individual point location
              </li>
              <li>
                <b>Column B is for service types:</b>You can have an unlimited
                number of service types, each service type can be represented by
                a different icon.
              </li>
            </ul>
          </v-card-text>
          <v-card-subtitle class="text-h6 text-start"
            >Adding labels to your services
          </v-card-subtitle>

          <v-card-text class="text-start">
            If you would like to add additional information to individual point
            locations you can add up to 5 extra columns of data.
            <ul class="mt-2 ml-8">
              <li>
                Within these 5 extra columns the first row in the CSV will act
                as a header where you can label the data you will be adding
              </li>
              <li>
                Each row can then include text under any of your extra headers
              </li>
              <li>
                Each row can include information for any, all or none of these
                fields
              </li>
              <li>
                For each row, you can then add unique information relating to
                that point location. This is a free text field.
              </li>
            </ul>
          </v-card-text>
          <v-card-actions class="d-flex align-center mt-3">
            <v-row class="">
              <v-file-input
                id="fileImport"
                v-model="importFile"
                accept=".csv"
                clearable
                density="compact"
                rounded="0"
                variant="outlined"
                label="Select File"
                class="fields mr-3"
                style="max-width: 450px"
                :error-messages="fileError"
                @change="fileError = ''"
              ></v-file-input>

              <v-btn
                tile
                class="mt-1"
                color="success"
                variant="elevated"
                :loading="importingProcess"
                @click="importServices"
                aria-label="import services"
              >
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
                Import
              </v-btn>
            </v-row>
          </v-card-actions>

          <!--   CSV data issues dialog  -->
          <ImportServiceIssues
            v-model:dialog="serviceIssuesDialogue"
            :unmatchedPostcodes="unmatchedPostcodes"
            :importedCount="importedCount"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ImportServiceIssues from "@/components/ImportServiceIssues.vue";
import { useDisplay } from "vuetify";
import { exportCSVFile } from "@/mixins/ExportCSVFile";

export default {
  name: "ImportExportServices",
  data: () => ({
    breakpoint: useDisplay().name,
    downloading: false,
    serviceIssuesDialogue: false,
    removeServicesDialog: false,
    removing: false,
    importFile: null,
    countDetails: [],
    importingProcess: false,
    unmatchedPostcodes: [],
    fileError: "",
    importedCount: 0,
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    refreshServiceTypes: false,
  }),
  components: {
    ImportServiceIssues,
  },
  computed: {
    height() {
      switch (this.breakpoint) {
        case "xs":
          return 55;
        case "sm":
          return 60;
        case "md":
          return 65;
        case "lg":
          return 68;
        case "xl":
          return 80;
        default:
          return 72;
      }
    },
  },
  props: {},
  mounted() {
    this.getCurrentDetails();
  },
  methods: {
    remove() {
      this.removing = true;
      this.$axios
        .delete("/remove-services")
        .then(() => {
          this.emit.emit("systemMessage", {
            title: "Services removed",
            message: "Success!",
            timeout: 4000,
            colour: "green",
          });
          this.getCurrentDetails();
          this.removing = false;
        })
        .catch((error) => {
          this.removing = false;
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error removing services",
            timeout: -1,
            colour: "red",
          });
        });
      this.removeServicesDialog = false;
    },
    download() {
      this.downloading = true;
      this.$axios
        .get("/download-services")
        .then((response) => {
          var d = new Date();
          var filename =
            "Services_" +
            d.getDate() +
            "_" +
            this.months[d.getMonth()] +
            "_" +
            d.getFullYear();
          exportCSVFile(response.data.headers, response.data.rows, filename);
          this.downloading = false;
        })
        .catch((error) => {
          this.downloading = false;
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error downloading services",
            timeout: -1,
            colour: "red",
          });
        });
    },
    openFileImporter() {
      this.fileError = null;
      document.getElementById("fileImport").click();
    },
    importServices() {
      // if no file selected then return
      if (!this.importFile) {
        this.fileError = "Please select a file";
        return;
      }

      this.importingProcess = true;

      // set up import data
      let formData = new FormData();
      formData.append("file", this.importFile);

      this.emit.emit("systemBusy", true);

      // make the call!
      this.$axios
        .post("/import-services", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(
          function (response) {
            // handle success
            this.unmatchedPostcodes = response.data.unmatched_postcodes;
            this.importedCount = response.data.import_count;
            this.importFile = null;

            // if there are then show the warning dialog
            if (this.unmatchedPostcodes.length) {
              this.serviceIssuesDialogue = true;
            } else {
              // otherwise show a success message
              this.emit.emit("systemMessage", {
                title: "Services imported",
                message: "Success!",
                timeout: -1,
                colour: "green",
              });
            }
            // Update displayed number of services and types on page
            this.getCurrentDetails();
            this.importingProcess = false;
            this.emit.emit("systemBusy", false);
            this.$emit("refresh");
          }.bind(this),
        )
        .catch((error) => {
          // handle error
          this.emit.emit("systemBusy", false);
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error with service import",
            timeout: -1,
            colour: "red",
          });
          this.importingProcess = false;
        });
    },
    getCurrentDetails() {
      this.$axios
        .get("/get-current-details")
        .then((response) => {
          this.countDetails = response.data;
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error loading service details",
            timeout: -1,
            colour: "red",
          });
        });
    },
  },
};
</script>

<style scoped>
.v-alert :deep(.v-alert__prepend) {
  align-self: center;
}
.v-alert :deep(.v-alert__content) {
  width: 100%;
}
</style>
