<template>
  <v-dialog v-model="computedDialog" fullscreen persistent scrollable>
    <v-card>
      <v-toolbar
        class="pr-2"
        :style="
          'background-color: ' + $store.state.config.siteConfig.toolbar_colour
        "
      >
        <v-btn
          tabindex="-1"
          variant="text"
          icon="mdi-close"
          @click="computedDialog = false"
          title="close comparator selection"
          aria-label="close comparator selection"
          label="close comparator selection"
        />
        <v-toolbar-title v-if="computedDialog" id="primaryComparitorName">
          <div v-if="!selectedAreas.length && primaryComparitor">
            Select your report comparators for
            {{ primaryComparitor ? primaryComparitor.name : "" }}
          </div>
          <div v-else-if="selectedAreasForPublicReport.length">
            Select report comparators for
            {{ selectedAreasForPublicReport.length }} custom area{{
              dynamicEnding(selectedAreasForPublicReport)
            }}
          </div>
          <div v-else>You can only select {{ maximumSelectionLimit }}</div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="selectedAreasForPublicReport.length"
          :disabled="selectedAreas.length <= 1"
          color="success"
          tile
          @click="savePublicReports"
          aria-label="create public report"
          variant="elevated"
        >
          create public report{{ dynamicEnding(selectedAreasForPublicReport) }}
        </v-btn>
        <v-btn
          v-else
          :disabled="selectedAreas.length <= 1"
          color="success"
          tile
          variant="elevated"
          width="150"
          id="reportNextButton"
          @click="getReport(selectedAreas)"
          aria-label="next"
          :tabindex="2001"
        >
          next
        </v-btn>
      </v-toolbar>
      <v-card-text style="height: 95vh">
        <!-- loading UX -->
        <v-card
          v-if="areaLevels.length === 0"
          tile
          elevation="0"
          class="progress"
        >
          <v-card-actions>
            <div class="progressText">
              <v-progress-circular
                class="spinner"
                :size="200"
                :width="3"
                color="#51627C"
                indeterminate
                >Loading Levels</v-progress-circular
              >
            </div>
          </v-card-actions>
        </v-card>
        <v-row v-else elevation="0">
          <v-col cols="4">
            <!-- areaLevels -->
            <v-card-text
              class="overflow-y-auto mt-0 pt-0"
              style="max-height: 89vh"
            >
              <v-row
                v-if="areaLevels.length > 0"
                id="areaLevels"
                no-gutters
                style="margin-top: 0"
              >
                <v-col
                  v-for="(level, index) in areaLevels"
                  :key="level.id"
                  cols="12"
                  lg="6"
                  md="10"
                  sm="12"
                >
                  <v-card
                    :disabled="searchingAreas"
                    :tabindex="level.tabIndex"
                    :style="
                      selectedAreaLevel === level.area_level_name_abr
                        ? 'cursor: pointer; background-color: rgb(0 0 0 / 12%);'
                        : 'cursor: pointer;'
                    "
                    class="areaLevel ma-2 text-center"
                    elevation="1"
                    variant="outlined"
                    tile
                    height="150"
                    @click="handleAreaLevelClick(level)"
                    @keyup.space="handleAreaLevelClick(level)"
                  >
                    <div
                      :title="level.area_level_name"
                      class="levelAreaName"
                      :id="'areaLevelTitle_' + index"
                    >
                      {{
                        level.area_level === 92
                          ? country.area_name
                          : level.area_level_name
                      }}
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col v-if="areasList" cols="4" id="reportCustomAreaCol">
            <v-card-actions class="ma-0 pa-0 mt-3 searchField">
              <v-autocomplete
                no-data-text="no results"
                v-model="selectedAreaSearch"
                :loading="searching"
                :items="searchResultItemsComp"
                v-model:search="search"
                id="searchAreas"
                return-object
                item-title="area_name"
                item-value="area_name"
                clearable
                class="fields pa-0 ma-0 ml-4 mr-8"
                variant="outlined"
                density="compact"
                rounded="0"
                :label="'Search All ' + this.selectedAreaLevelTitle"
                :tabindex="areaLevels.length + 2"
              ></v-autocomplete>
            </v-card-actions>
            <v-card-actions
              v-if="
                this.selectedAreaLevel === 'MSOA' ||
                this.selectedAreaLevel === 'LSOA'
              "
              class="ma-0 pa-0"
            >
              <v-select
                no-data-text="no filters available"
                v-model="selectedAreaFilter"
                :items="areaFilters"
                :label="'Filter ' + this.selectedAreaLevel + '\'s'"
                item-title="area_name"
                item-value="area_name"
                :return-object="true"
                variant="outlined"
                density="compact"
                rounded="0"
                :loading="filtering"
                class="fields pa-0 ma-0 ml-4 mr-8"
                :tabindex="areaLevels.length + 3"
              >
              </v-select>
            </v-card-actions>
            <v-card-text
              id="reportAreaList"
              class="overflow-y-auto mt-0 pt-0"
              style="max-height: 76vh"
              @scroll="lazyLoad"
            >
              <div
                v-if="customAreasToHide.length === areasList.length"
                class="text-center"
              >
                <!-- You've selected all custom areas so you can't add any -->
              </div>
              <v-list dense>
                <v-list-item
                  v-for="(area, index) in areasList"
                  :key="area.id"
                  :value="area"
                  class="v-list-item"
                  @click="addRemoveAreas(area)"
                >
                  <template v-slot:prepend="{}">
                    <v-list-item-action
                      start
                      :tabindex="areaLevels.length + 4 + index"
                    >
                      <v-checkbox
                        v-model="area.selected"
                        :disabled="
                          !area.selected &&
                          selectedAreas.length === maximumSelectionLimit
                            ? true
                            : false
                        "
                        @click.stop="addRemoveAreas(area)"
                        :id="'ReportComparatorCustomAreaCheckBox_' + area.id"
                        hide-details
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                  <v-list-item-title
                    :for="'ReportComparatorCustomAreaCheckBox_' + area.id"
                    :id="'ReportComparatorCustomArea_' + area.id"
                    class="areaName"
                    >{{ area.area_name }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-col>
          <v-col v-else cols="4" class="mt-2">
            <v-card-actions id="loadingAreas" class="areasPlaceholder">
              <div class="loadingAreas">
                <v-progress-circular
                  :size="120"
                  :width="2"
                  color="#51627C"
                  indeterminate
                  >Loading</v-progress-circular
                >
              </div>
            </v-card-actions>
          </v-col>
          <v-col cols="4" v-if="selectedAreas">
            <v-row>
              <v-spacer />
              <v-btn
                tile
                append-icon="mdi-trash-can-outline"
                title="clear list"
                aria-label="clear list"
                @click="clearList()"
                variant="outlined"
                :tabindex="1000"
              >
                Clear List
                <template v-slot:append>
                  <v-icon color="error"></v-icon>
                </template>
              </v-btn>
            </v-row>
            <v-row>
              <v-list-subheader
                :title="
                  !selectionErrorMessage
                    ? 'A Minimum of 2 comparators need to be selected'
                    : selectionErrorMessage
                "
                :style="
                  !selectionErrorMessage
                    ? 'cursor: default; margin-top: 7px'
                    : 'cursor: default; margin-top: 7px; color: red'
                "
              >
                {{
                  !selectionErrorMessage
                    ? "Selected Areas " +
                      selectedAreas.length +
                      "/" +
                      maximumSelectionLimit
                    : selectionErrorMessage
                }}
                <p id="selected-list-description" class="text-grey-darken-1">
                  Drag selected areas to reorder
                </p>
              </v-list-subheader>
            </v-row>
            <v-row>
              <v-card-text
                class="overflow-y-auto mt-0 pt-0"
                style="max-height: 85vh"
              >
                <v-list density="compact" rounded="0" lines="two">
                  <Draggable v-model="selectedAreas" item-key="id">
                    <template #item="{ element, index }">
                      <v-list-item
                        :key="element.id"
                        :value="element"
                        color="primary"
                        class="pa-0 ma-0"
                        :subtitle="element.level"
                        :title="`${index + 1}. ${element.area_name}`"
                      >
                        <template v-slot:prepend>
                          <v-btn
                            :id="'draggable_' + index"
                            class="drag-handle"
                            variant="flat"
                            icon
                            aria-describedby="selected-list-description"
                            aria-label="drag to re-order"
                            label="drag to re-order"
                            title="drag to re-order"
                            @keydown="updateOrderByKey($event, index)"
                          >
                            <v-icon>mdi-drag</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:append>
                          <v-btn
                            icon="mdi-trash-can-outline"
                            @click="addRemoveAreas(element)"
                            title="Remove Area"
                            aria-label="Remove Area"
                            variant="flat"
                            :tabindex="1000 + index"
                          />
                        </template>
                      </v-list-item>
                    </template>
                  </Draggable>
                </v-list>
              </v-card-text>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  name: "SearchAreasFullscreen",
  data: () => ({
    selectedAreaFilter: { area_name: "No Filter", value: "No Filter" },
    areaFilters: [{ area_name: "No Filter", value: "No Filter" }],
    appliedFilters: { MSOA: ["No Filter"], LSOA: ["No Filter"] },
    searching: false,
    filtering: false,
    searchingAreas: false,
    searchResults: [1, 2, 3],
    areaLevels: [],
    selectedAreaLevel: null,
    selectedAreaLevelsAreas: [],
    areasList: null,
    selectedAreas: [],
    selectedAreaSearch: null,
    searchResultItems: null,
    search: null,
    selectionErrorMessage: null,
    customAreas: [],
    deBounce: null,
  }),
  components: {
    Draggable,
  },
  props: {
    metadata: {},
    theme: {},
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    primaryComparitor: {
      type: Object,
      required: false,
      default: null,
    },
    maximumSelectionLimit: {
      type: Number,
      required: false,
      default: 3,
    },
    minimumSelectionLimit: {
      type: Number,
      required: false,
      default: 0,
    },
    selectedAreasForPublicReportProp: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  computed: {
    theseLevelsOnly: {
      get() {
        return JSON.parse(
          process.env.VUE_APP_AVAILABLE_REPORT_COMPARATOR_LEVELS,
        )[this.$store.state.config.siteConfig.site_country];
      },
    },
    defaultLevel: {
      get() {
        return JSON.parse(
          process.env.VUE_APP_AVAILABLE_REPORT_COMPARATOR_DEFAULT,
        )[this.$store.state.config.siteConfig.site_country];
      },
    },
    country: {
      get() {
        return JSON.parse(process.env.VUE_APP_COUNTRY_COMPARATOR)[
          this.$store.state.config.siteConfig.site_country
        ];
      },
    },
    selectedAreasForPublicReport: {
      get() {
        return this.selectedAreasForPublicReportProp;
      },
      set(val) {
        this.$emit("update:selectedAreasForPublicReportProp", val);
      },
    },
    viewReport: {
      get() {
        return this.$store.state.viewReport;
      },
      set(value) {
        this.$store.commit("setViewReport", value);
      },
    },
    searchResultItemsComp: {
      get() {
        var searchResultItems = [];
        if (this.searchResultItems === null) {
          return [];
        } else if (this.selectedAreaLevel === "CSTM") {
          // remove primary comparitor from results (so user cannot select it twice)
          for (var i = 0; i < this.searchResultItems.length; i++) {
            if (
              !this.customAreasToHide.includes(
                this.searchResultItems[i].area_code,
              )
            ) {
              searchResultItems.push(this.searchResultItems[i]);
            }
          }
          return searchResultItems;
        } else {
          return this.searchResultItems;
        }
      },
    },
    computedDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
    customAreasToHide: {
      // here we're just filtering the custom areas we're dealing with, to prevent from showing up in the list
      get() {
        if (this.primaryComparitor) {
          return [this.primaryComparitor.area_code];
        } else {
          let areaCodes = [];

          for (
            let index = 0;
            index < this.selectedAreasForPublicReport.length;
            index++
          ) {
            areaCodes.push(
              "CSTM" + this.selectedAreasForPublicReport[index].id,
            );
          }
          return areaCodes;
        }
      },
    },
    selectedAreaLevelTitle: {
      get() {
        let areaLevel = this.areaLevels.find(
          (l) => l.area_level_name_abr == this.selectedAreaLevel,
        );

        return areaLevel.area_level_name;
      },
    },
  },
  mounted() {},
  unmounted() {},
  methods: {
    getReport(selectedAreas) {
      if (this.minimumSelectionLimit) {
        this.viewReport = false;
        this.$nextTick(() => {
          // check we have selected the minimum
          if (this.selectedAreas.length < this.minimumSelectionLimit) {
            // show error
            this.selectionErrorMessage =
              "A Minimum of " +
              this.minimumSelectionLimit +
              " comparators need to be selected";
            setTimeout(
              function () {
                this.selectionErrorMessage = null;
              }.bind(this),
              5000,
            );
          } else {
            //send a copy of reactive array selectedAreas to Report.vue
            this.$emit("next", [...selectedAreas]);
            this.computedDialog = false;
            this.clearList();
          }
        });
      } else {
        //send a copy of reactive array selectedAreas to Report.vue
        this.$emit("next", [...selectedAreas]);
        this.computedDialog = false;
        this.clearList();
      }
    },
    applyAreaFilter(response = false) {
      if (response) {
        this.selectedAreaLevelsAreas[this.selectedAreaLevel] = [
          ...this.selectedAreaLevelsAreas[this.selectedAreaLevel],
          ...response.data,
        ];
        // stop duplications
        const uniqueAreas = [];
        this.selectedAreaLevelsAreas[this.selectedAreaLevel] =
          this.selectedAreaLevelsAreas[this.selectedAreaLevel].filter(
            (element) => {
              const isDuplicate = uniqueAreas.includes(element.area_code);
              if (!isDuplicate) {
                uniqueAreas.push(element.area_code);
                return true;
              }
              return false;
            },
          );
        // sort by name
        this.selectedAreaLevelsAreas[this.selectedAreaLevel] =
          this.selectedAreaLevelsAreas[this.selectedAreaLevel].sort((a, b) =>
            a.area_name.localeCompare(b.area_name),
          );
      }
      // apply the filter
      if (this.selectedAreaFilter.area_name !== "No Filter") {
        this.areasList = [];
        for (
          var i = 0;
          i < this.selectedAreaLevelsAreas[this.selectedAreaLevel].length;
          i++
        ) {
          if (
            this.selectedAreaLevelsAreas[this.selectedAreaLevel][
              i
            ].area_name.includes(this.selectedAreaFilter.area_name)
          ) {
            this.areasList.push(
              this.selectedAreaLevelsAreas[this.selectedAreaLevel][i],
            );
          }
        }
      } else {
        // no filter required
        this.areasList = this.selectedAreaLevelsAreas[this.selectedAreaLevel];
      }
      this.filtering = false;
    },
    clearList() {
      if (this.selectedAreas.length > 0) {
        this.addRemoveAreas(this.selectedAreas[0], true);
      }
    },
    debounceSearch(v, filterSearch = false) {
      clearTimeout(this.deBounce);
      this.deBounce = setTimeout(
        function () {
          this.searchAreas(v, filterSearch);
        }.bind(this),
        500,
      );
    },
    searchAreas(v, filterSearch) {
      var limit = 100;
      if (filterSearch) {
        limit = 200;
        this.filtering = true;
      } else {
        this.searching = true;
      }
      this.$axios
        .get(
          "/search-areas-by-level/" +
            this.selectedAreaLevel +
            "/" +
            v +
            "/" +
            limit,
        )
        .then(
          function (response) {
            // handle success
            if (filterSearch) {
              this.appliedFilters[this.selectedAreaLevel].push(
                this.selectedAreaFilter,
              );
              this.applyAreaFilter(response);
            } else {
              this.searchResultItems = response.data;
            }
            this.searching = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.searching = false;
          }.bind(this),
        );
    },
    addRemoveAreas(area, clearList = false) {
      //sets display name for singular area item e.g. "Region", "Local Authority"
      area["level"] = this.areaLevels.find(
        (l) => l.area_level_name_abr == this.selectedAreaLevel,
      )["area_level_name_singular"];
      //sets selected property for area object
      if (typeof area.selected === "undefined") {
        area["selected"] = true;
      } else {
        area["selected"] = !area["selected"];
      }

      //add area to list
      if (area.selected) {
        this.selectedAreas.push(area);
      }
      //remove item from list
      if (!area.selected) {
        this.selectedAreas.splice(this.selectedAreas.indexOf(area), 1);
      }

      // are we clearing the selection list?
      if (clearList) {
        return this.clearList();
      }
      // check we are within the maximum selection allowed
      if (this.selectedAreas.length > this.maximumSelectionLimit) {
        // show error
        this.selectionErrorMessage =
          "Maximum of " +
          this.maximumSelectionLimit +
          " comparators can be selected";
        setTimeout(
          function () {
            this.selectionErrorMessage = null;
          }.bind(this),
          5000,
        );
        this.addRemoveAreas(area);
      }
    },
    getAreaLevels() {
      if (this.areaLevels.length === 0) {
        this.$axios
          .get("/list-area-levels")
          .then(
            function (response) {
              // handle success
              // sneak the custom areas in
              response.data.push({
                area_level_name: "Custom Areas",
                area_level_name_abr: "CSTM",
                area_level_name_singular: "Custom Area",
                entity_code: 0,
                id: 0,
              });
              // only display the required levels
              if (typeof this.theseLevelsOnly !== "undefined") {
                for (var i = 0; i < response.data.length; i++) {
                  if (
                    this.theseLevelsOnly.includes(
                      response.data[i].area_level_name_abr,
                    )
                  ) {
                    this.areaLevels.push(response.data[i]);
                  }
                }
              } else {
                this.areaLevels = response.data;
              }
              this.correctAreaLevelsOrder(
                this.areaLevels,
                this.theseLevelsOnly,
              );
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                title: "Error! Failed to get Area Levels",
                message: error.response.data.message,
                timeout: -1,
                colour: "error",
              });
            }.bind(this),
          );
      }
      // load up the areas for the default level
      if (this.defaultLevel !== null) {
        this.selectedAreaLevel = this.defaultLevel;
      } else {
        this.selectedAreaLevel = this.theseLevelsOnly[0];
      }
    },
    correctAreaLevelsOrder(inputArray, orderArray) {
      // Create a map to store the index of each area_level_name_abr
      const orderMap = {};
      orderArray.forEach((level, index) => {
        orderMap[level] = index;
      });

      // Sort the inputArray based on the index of area_level_name_abr in orderMap
      inputArray.sort((a, b) => {
        const orderA =
          orderMap[a.area_level_name_abr] !== undefined
            ? orderMap[a.area_level_name_abr]
            : Number.MAX_SAFE_INTEGER;
        const orderB =
          orderMap[b.area_level_name_abr] !== undefined
            ? orderMap[b.area_level_name_abr]
            : Number.MAX_SAFE_INTEGER;
        return orderA - orderB;
      });

      inputArray.forEach((item, index) => {
        item.tabIndex = index + 1;
      });

      this.areaLevels = inputArray;
    },
    mixInCustomAreas() {
      // format them
      for (var i = 0; i < this.customAreas.length; i++) {
        this.customAreas[i].area_code = "CSTM" + this.customAreas[i].id;
        this.customAreas[i].area_name = this.customAreas[i].name;
      }
      this.selectedAreaLevelsAreas.CSTM = this.customAreas;
      this.areasList = this.selectedAreaLevelsAreas[this.selectedAreaLevel];
      this.searchingAreas = false;
    },
    getAreaFilters() {
      if (this.areaFilters.length < 2) {
        this.$axios
          .get("/areas-by-area-level/6")
          .then(
            function (response) {
              // handle success
              this.areaFilters = [...this.areaFilters, ...response.data];
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                title: "Error! Failed to get Area Filters",
                message: error.response.data.message,
                timeout: -1,
                colour: "error",
              });
            }.bind(this),
          );
      }
    },
    getAreaLevelsAreas() {
      if (!this.searchingAreas) {
        this.searchingAreas = true;
        this.$axios
          .get(
            "/list-areas-by-level-paginated/" +
              this.selectedAreaLevel +
              "/" +
              this.selectedAreaLevelsAreas[this.selectedAreaLevel].length +
              "/" +
              100,
          )
          .then(
            function (response) {
              // handle success
              this.selectedAreaLevelsAreas[this.selectedAreaLevel] = [
                ...this.selectedAreaLevelsAreas[this.selectedAreaLevel],
                ...response.data,
              ];
              // stop duplications (caused when users use filters and or the search function)
              const uniqueAreas = [];
              this.selectedAreaLevelsAreas[this.selectedAreaLevel] =
                this.selectedAreaLevelsAreas[this.selectedAreaLevel].filter(
                  (element) => {
                    const isDuplicate = uniqueAreas.includes(element.area_code);
                    if (!isDuplicate) {
                      uniqueAreas.push(element.area_code);
                      return true;
                    }
                    return false;
                  },
                );
              // reset this
              this.areasList =
                this.selectedAreaLevelsAreas[this.selectedAreaLevel];
              this.searchingAreas = false;
            }.bind(this),
          )
          .catch(
            function (error) {
              this.searchingAreas = false;
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                title: "Error! Failed to get Area Levels",
                message: error.response.data.message,
                timeout: -1,
                colour: "error",
              });
            }.bind(this),
          );
      }
    },
    getCustomAreas() {
      if (!this.selectedAreaLevelsAreas["CSTM"].length) {
        this.$axios
          .get("/custom-areas-by-category-lite")
          .then(
            function (response) {
              // handle success
              this.customAreas = response.data;
              this.mixInCustomAreas();
            }.bind(this),
          )
          .catch(
            function (error) {
              // handle error
              console.error(error);
              this.emit.emit("systemMessage", {
                title: "Error! Failed to get custom areas",
                message: error.response.data.message,
                timeout: -1,
                colour: "error",
              });
            }.bind(this),
          );
      } else {
        this.areasList = this.selectedAreaLevelsAreas[this.selectedAreaLevel];
        this.searchingAreas = false;
      }
    },
    lazyLoad: function (event) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
          event.target.scrollHeight - 1000 &&
        this.selectedAreaFilter.area_name === "No Filter"
      ) {
        this.getAreaLevelsAreas();
      }
    },
    dynamicEnding(array, ending = "s") {
      if (array.length > 1) return ending;
    },
    savePublicReports() {
      if (!this.selectedAreasForPublicReport.length) return;

      this.emit.emit("systemMessage", {
        title: "Saving " + this.selectedAreasForPublicReport.length + " areas",
        message: "Saving public summary reports",
        timeout: -1,
        colour: "warning",
      });
      this.$axios
        .post(
          "/public-custom-area-summary-report/" +
            this.selectedAreas[0].area_code +
            "/" +
            this.selectedAreas[1].area_code,
          this.selectedAreasForPublicReport,
        )
        .then(() => {
          // handle success
          this.emit.emit("systemMessage", {
            title:
              "Saved " +
              this.selectedAreasForPublicReport.length +
              " public reports",
            message: "Public summary reports saved",
            timeout: 4000,
            colour: "success",
          });

          // reset values
          this.$emit("clear");
        })
        .catch((error) => {
          console.error(error);
          this.emit.emit("systemMessage", {
            title: "Error! Failed to get custom areas",
            message: error.response.data.message,
            timeout: -1,
            colour: "error",
          });
        });
    },
    reorderArray(arr, index, direction) {
      if (index < 0 || index >= arr.length) {
        return arr;
      }
      if (direction !== "up" && direction !== "down") {
        return arr;
      }
      if (direction === "up" && index === 0) {
        return arr;
      }
      if (direction === "down" && index === arr.length - 1) {
        return arr;
      }
      const elementToMove = arr.splice(index, 1)[0];
      if (direction === "up") {
        arr.splice(index - 1, 0, elementToMove);
      } else if (direction === "down") {
        arr.splice(index + 1, 0, elementToMove);
      }
      return arr;
    },
    updateOrderByKey(event, index) {
      this.selectedAreasClone = JSON.parse(JSON.stringify(this.selectedAreas));
      var direction = false;
      switch (event.keyCode) {
        case 38:
          direction = "up";
          break;
        case 40:
          direction = "down";
          break;
      }
      if (direction) {
        this.selectedAreasClone = this.reorderArray(
          this.selectedAreas,
          index,
          direction,
        );
        this.selectedAreas = this.selectedAreasClone;
        if (direction == "up") {
          setTimeout(() => {
            document
              .getElementById(
                index - 1 < 0
                  ? "draggable_" + index
                  : "draggable_" + (index - 1),
              )
              .focus();
          }, 0);
        } else {
          setTimeout(() => {
            document
              .getElementById(
                index + 1 > this.selectedAreas.length - 1
                  ? "draggable_" + index
                  : "draggable_" + (index + 1),
              )
              .focus();
          }, 0);
        }
      }
    },
    handleAreaLevelClick(level) {
      const isCountryLevel = level.area_level === 92;

      // if it's a country, add it straight to comparators otherwise show areas for the level
      if (isCountryLevel) {
        this.addRemoveAreas(this.country);
      } else {
        this.selectedAreaLevel = level.area_level_name_abr;
      }
    },
  },
  watch: {
    computedDialog: {
      handler(val) {
        if (val) {
          this.clearList();
          this.getAreaLevels();
        }
      },
    },
    selectedAreaFilter: {
      handler(val) {
        if (val) {
          if (
            this.selectedAreaLevel === "MSOA" ||
            this.selectedAreaLevel === "LSOA"
          ) {
            this.filtering = true;
            // no need for an api call if we have already got these
            if (
              !this.appliedFilters[this.selectedAreaLevel].includes(
                this.selectedAreaFilter,
              )
            ) {
              this.debounceSearch(this.selectedAreaFilter.area_name, true);
            } else {
              this.applyAreaFilter();
            }
          }
        }
      },
    },
    selectedAreaSearch: {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (newVal !== oldVal && newVal !== null) {
            // check this area does not already exists locally
            for (
              var i = 0;
              i < this.selectedAreaLevelsAreas[this.selectedAreaLevel].length;
              i++
            ) {
              if (
                this.selectedAreaLevelsAreas[this.selectedAreaLevel][i]
                  .area_code === newVal.area_code
              ) {
                this.addRemoveAreas(
                  this.selectedAreaLevelsAreas[this.selectedAreaLevel][i],
                );
                return;
              }
            }
            // if we've hit here then we need to add it
            this.selectedAreaLevelsAreas[this.selectedAreaLevel].unshift(
              newVal,
            );
            this.areasList =
              this.selectedAreaLevelsAreas[this.selectedAreaLevel];
            this.addRemoveAreas(newVal);
          }
        });
      },
      deep: true,
    },
    search(val) {
      val && val !== this.selectedAreaSearch && this.debounceSearch(val);
    },
    selectedAreaLevel(val) {
      this.selectedAreaFilter = { area_name: "No Filter", value: "No Filter" };
      if (val) {
        this.areasList = null;
        this.searchResultItems = null;
        if (typeof this.selectedAreaLevelsAreas[val] === "undefined") {
          this.selectedAreaLevelsAreas[val] = [];
          this.getAreaLevelsAreas(val);
        } else {
          this.areasList = this.selectedAreaLevelsAreas[this.selectedAreaLevel];
        }
        // do we need to get these filters?
        if (
          this.selectedAreaLevel === "MSOA" ||
          this.selectedAreaLevel === "LSOA"
        ) {
          this.getAreaFilters();
        }
      }
    },
  },
};
</script>

<style scoped>
.v-list-item {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  min-height: 60px;
}
.areaName {
  cursor: pointer;
}
.levelAreaName {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  word-break: normal;
  font-size: 1.25rem;
  font-weight: 500;
  -webkit-hyphens: auto;
  hyphens: auto;
  letter-spacing: 0.0125em;
  padding: 3px;
}
.spinner {
  top: 0vh;
}
.areasPlaceholder {
  height: 80vh;
  border: 1px solid rgb(0 0 0 / 12%);
}
.loadingAreas {
  top: 35vh;
  width: 100%;
  text-align: center;
  font-size: 14px;
}
.progressText {
  width: 100%;
  text-align: center;
  font-size: 14px;
}
.progress {
  top: 10vh;
  width: 30vw;
  left: 35vw;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.v-card.on-hover.theme--dark {
  background-color: rgba(#fff, 0.8);
}
.v-card__text {
  color: #000;
}

#primaryComparitorName {
  flex: auto;
}

#primaryComparitorName div {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
