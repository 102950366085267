<template>
  <div class="text-center">
    <MapCustomAreaInfo v-model:customAreaInfoDialog="customAreaInfoDialog" />
    <v-dialog
      v-model="computedCustomAreasDialog"
      width="700px"
      scrollable
      id="customAreasDialog"
    >
      <v-card>
        <v-toolbar
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6"
        >
          <v-btn
            tile
            icon="mdi-close"
            @click="
              computedCustomAreasDialog = false;
              search = '';
            "
            aria-label="Close"
          />
          <v-toolbar-title id="customAreasDialogTitle">
            Custom Areas
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <div style="width: 50%">
            <v-text-field
              :single-line="true"
              variant="outlined"
              density="compact"
              rounded="0"
              clearable
              hide-details="true"
              class="fields area-search mr-4"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Filter custom area name"
            ></v-text-field>
          </div>
        </v-toolbar>
        <v-card-text style="height: 500px" class="overflow-y-auto mt-0 pt-0">
          <v-card-actions class="sticky">
            <v-alert
              type="info"
              width="100%"
              v-if="markerCount === 0"
              text="Select an individual area or category"
              style="z-index: 1000"
            >
            </v-alert>
            <v-alert
              :type="maxType"
              width="100%"
              text="You can show a maximum of 5 Custom Areas while displaying Services"
              v-else
            >
            </v-alert>
          </v-card-actions>
          <v-card-text v-if="search && !searchHits"
            >No custom area matches your search term</v-card-text
          >
          <v-list
            class="ma-0 pa-0"
            density="compact"
            rounded="0"
            id="customAreaCategoriesList"
            style="z-index: 900"
            :opened="opened"
          >
            <v-list-item
              style="padding: 10px 10px 10px 15px"
              id="selectAll"
              @click="selectAll"
              density="compact"
              rounded="0"
            >
              <v-list-item-action>
                <v-layout style="display: flex; align-items: center">
                  <v-checkbox
                    :model-value="areAllAreasSelected"
                    density="compact"
                    class="mx-2"
                    rounded="0"
                  ></v-checkbox>
                  <span>Select All</span></v-layout
                >
              </v-list-item-action>
            </v-list-item>
            <div v-for="category in allAreasForMap" :key="category.id">
              <v-list-group
                ref="expansionPanel"
                no-action
                :value="category.id"
                v-model="category.expanded"
                v-if="!category.hidden"
                density="compact"
                rounded="0"
                id="customAreaCategories"
              >
                <template v-slot:activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    style="padding: 10px 10px 10px 15px"
                    v-model="category.expanded"
                    :value="category.expanded"
                    density="compact"
                    rounded="0"
                    :id="'cat_' + category.id"
                  >
                    <v-list-item-action>
                      <v-layout style="display: flex; align-items: center">
                        <v-checkbox
                          v-model="category.checked"
                          @click.stop="toggleCustomAreaCategory(category)"
                          density="compact"
                          class="mx-2"
                          rounded="0"
                          id="categoryCheckbox"
                        ></v-checkbox>
                        <span>{{ category.name }}</span></v-layout
                      >
                    </v-list-item-action>
                  </v-list-item>
                </template>

                <template v-if="category.customAreas.length">
                  <template
                    v-for="(area, i) in category.customAreas"
                    :key="`area-${i}`"
                  >
                    <v-list-item
                      v-if="!area.hidden"
                      density="compact"
                      rounded="0"
                      id="customArea"
                      @click.stop="toggleCustomArea(area, category)"
                    >
                      <v-list-item-action>
                        <v-layout style="display: flex; align-items: center">
                          <v-checkbox
                            v-model="area.checked"
                            density="compact"
                            class="mx-2"
                            rounded="0"
                            id="customAreaCheckbox"
                          ></v-checkbox>
                          <span>{{ area.name }}</span>
                        </v-layout>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </template>
                <template v-else>
                  <v-list-item density="compact" rounded="0">
                    <v-list-item-action>
                      <span>No areas in this category</span>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list-group>
            </div>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            tile
            color="error"
            variant="elevated"
            @click="
              computedCustomAreasDialog = false;
              search = '';
            "
          >
            close
          </v-btn>
          <v-spacer></v-spacer>
          <v-badge
            v-if="selectedCustomAreas.length"
            color="red"
            overlap
            :value="true"
            class="mr-4"
          >
            <template v-slot:badge>
              <span class="custom-badge" id="selectedCount">{{
                selectedCustomAreas.length
              }}</span>
            </template>
            <v-btn
              tile
              @click="
                resetCheckboxes();
                selectedCustomAreas = [];
              "
              class="ml-3"
              color="warning"
              title="Clear the selected custom areas"
              variant="elevated"
            >
              clear
            </v-btn>
          </v-badge>
          <v-btn
            color="success"
            tile
            variant="elevated"
            :loading="loading"
            :disabled="canLoadAreas"
            @click="loadCustomAreas"
            id="viewCustomAreasBtn"
          >
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
            View Custom Areas</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MapCustomAreaInfo from "@/components/MapCustomAreaInfo";

export default {
  name: "MAPCUSTOMAREAS",
  data: () => ({
    allAreasForMap: [],
    customAreaInfoDialog: false,
    searchHits: 0,
    search: "",
    loading: false,
    selectedCustomAreas: [],
    opened: [],
  }),
  computed: {
    computedCustomAreasDialog: {
      get() {
        return this.mapCustomAreasDialog;
      },
      set(val) {
        this.$emit("update:mapCustomAreasDialog", val);
      },
    },
    canLoadAreas() {
      if (this.markerCount > 0 && this.selectedCustomAreas.length > 5) {
        return true;
      }
      return false;
    },
    maxType() {
      if (this.markerCount > 0 && this.selectedCustomAreas.length > 5) {
        return "error";
      }
      return "info";
    },
    areAllAreasSelected() {
      return this.allAreasForMap.every((category) => category.checked);
    },
  },
  components: {
    MapCustomAreaInfo,
  },
  props: {
    mapCustomAreasDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    markerCount: {
      type: Number,
      required: true,
      default: 0,
    },
    clearDialogs: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    this.getAllAreas();
  },
  methods: {
    getAllAreas() {
      this.loading = true;
      this.$axios
        .get("/custom-areas-by-category-complete")
        .then(
          function (response) {
            // handle success
            this.allAreasForMap = response.data;
            this.loading = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.loading = false;
          }.bind(this),
        );
    },
    toggleCustomArea(area, category) {
      area.checked = !area.checked;

      this.$nextTick().then(() => {
        if (area.checked) {
          this.selectedCustomAreas.push(area.id);

          // if that makes all areas in the category checked, check the category
          if (category.customAreas.every((item) => item.checked === true)) {
            category.checked = true;
          }
        } else {
          this.selectedCustomAreas = this.selectedCustomAreas.filter(
            (item) => item !== area.id,
          );

          // if not all of the areas are checked, uncheck the category
          if (category.checked) {
            category.checked = false;
          }
        }
      });
    },
    toggleCustomAreaCategory(selectedCat) {
      selectedCat.checked = !selectedCat.checked;

      this.$nextTick().then(() => {
        // if it's checked, add all the areas to be shown
        if (selectedCat.checked) {
          selectedCat.customAreas.forEach((area) => {
            area.checked = true;
            if (!this.selectedCustomAreas.includes(area.id)) {
              this.selectedCustomAreas.push(area.id);
            }
          });
          // otherwise, hide all the areas
        } else {
          selectedCat.customAreas.forEach((area) => {
            area.checked = false;
            // remove all of the custom area ids from the selectedCustomAreas array
            this.selectedCustomAreas = this.selectedCustomAreas.filter(
              (item) => item !== area.id,
            );
          });
        }
      });
    },
    resetCheckboxes() {
      this.allAreasForMap.forEach((category) => {
        category.checked = false;
        category.customAreas.forEach((area) => {
          area.checked = false;
        });
      });

      // Ensure Vue's reactivity system processes the changes
      this.$nextTick(() => {
        this.allAreasForMap = this.allAreasForMap.map((category) => ({
          ...category,
          checked: false,
        }));
      });
    },
    selectAll() {
      let action = !this.areAllAreasSelected;

      this.allAreasForMap.forEach((category) => {
        category.checked = action;

        category.customAreas.forEach((area) => {
          area.checked = action;

          if (area.checked && !this.selectedCustomAreas.includes(area.id)) {
            this.selectedCustomAreas.push(area.id);
          }
        });
      });

      if (!action) {
        this.selectedCustomAreas = [];
      }
    },
    loadCustomAreas() {
      this.$emit("selectedCustomAreas", this.selectedCustomAreas);
      this.computedCustomAreasDialog = false;
      this.search = "";
    },
  },
  watch: {
    selectedCustomAreas: function () {
      if (this.selectedCustomAreas.length == 0) {
        this.$emit("selectedCustomAreas", this.selectedCustomAreas);
        this.resetCheckboxes();
      }
    },
    clearDialogs(newValue) {
      if (newValue) {
        this.resetCheckboxes();
        this.selectedCustomAreas = [];
      }
    },
    computedCustomAreasDialog: function (val) {
      if (!val) {
        this.search = "";
      }
    },
    search: function (search) {
      if (search) {
        // For each category
        for (let i = 0; i < this.allAreasForMap.length; i++) {
          // Track whether to hide category and its custom areas
          var allAreasUnchecked = true;
          // For each custom area, set a hidden flag if it doesn't match search
          for (let j = 0; j < this.allAreasForMap[i].customAreas.length; j++) {
            if (
              this.allAreasForMap[i].customAreas[j].name
                .toLowerCase()
                .indexOf(search.toLowerCase()) == -1
            ) {
              this.allAreasForMap[i].customAreas[j].hidden = true;
            } else {
              this.allAreasForMap[i].customAreas[j].hidden = false;
              allAreasUnchecked = false;
            }
          }
          // Hide category
          if (allAreasUnchecked) {
            this.allAreasForMap[i].hidden = true;
          } else {
            this.allAreasForMap[i].hidden = false;
            this.allAreasForMap[i].expanded = true;
            // manually add the category id to the opened array if not there
            if (!this.opened.includes(this.allAreasForMap[i].id)) {
              this.opened.push(this.allAreasForMap[i].id);
            }
          }
          this.searchHits = this.allAreasForMap.filter(
            (cac) => !cac.hidden,
          ).length;
        }
      } else {
        // Reset visibility when there's nothing in the filter
        for (let i = 0; i < this.allAreasForMap.length; i++) {
          for (let j = 0; j < this.allAreasForMap[i].customAreas.length; j++) {
            this.allAreasForMap[i].customAreas[j].hidden = false;
          }
          this.allAreasForMap[i].hidden = false;
        }
        this.searchHits = 0;

        this.opened = [];
      }
    },
  },
};
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.area-search {
  background-color: white !important;
  color: rgb(81, 98, 124) !important;
}

#customArea:hover {
  background-color: #fcfcfc;
  transition: background-color 0.3s;
  cursor: pointer;
}

#selectAll:hover {
  background-color: #f4f4f4;
  transition: background-color 0.3s;
  cursor: pointer;
}
#customAreasDialogTitle {
  flex: auto;
}
</style>
<style>
#customAreaCategoriesList .v-input__details {
  display: none !important;
}
</style>
