<template>
  <div>
    <!-- name dialog -->
    <DynamicDialog
      :show="showNameDialog"
      maxWidth="500px"
      :okBtnDisabled="areaName.length === 0"
      @dialogOk="fetchSummaryReport()"
      @dialogCancel="errors = []"
      @update:showDynamicDialog="(state) => (showNameDialog = state)"
    >
      <template v-slot:title>Neighbourhood name</template>
      <template v-slot:content>
        <p class="pb-4">Give this Neighbourhood a name</p>
        <v-text-field
          v-model="areaName"
          label="Neighbourhood name"
          variant="outlined"
          :error-messages="errors"
        ></v-text-field>
      </template>
      <template v-slot:okBtnTitle>SHOW SUMMARY REPORT</template>
    </DynamicDialog>

    <!-- summary report dialog -->
    <DynamicDialog
      :okBtnDisabled="!summaryFetched"
      :okBtnSpinner="okBtnSpinner"
      :show="showSummaryDialog"
      minHeight="250px"
      maxWidth="80vw"
      @dialogOk="fetchReport()"
      @update:showDynamicDialog="(state) => (showNameDialog = state)"
    >
      <template v-slot:title>Summary Report</template>
      <template v-slot:content>
        <div v-if="!loading">
          <!-- summary report content (ReportViewer cust and paste)-->
          <h2 class="ml-4">{{ summaryTheme.title }}</h2>
          <v-card-text>
            <div
              v-html="summaryTheme.description"
              class="min-accessible-size text"
            />
          </v-card-text>
          <v-card-actions v-for="(item, j) in summaryTheme.config" :key="j">
            <!-- Text -->
            <v-card-text v-if="item.text">
              <div v-html="item.text" class="min-accessible-size text" />
            </v-card-text>
            <!-- Table -->
            <v-card-actions
              v-if="item.indicatorHTMLTableRef"
              :id="j.toString()"
            >
              <Tables
                :load="summaryFetched"
                :item="item"
                :indicatorValues="summaryIndicators"
                :comparators="selectedComparators"
                :comparatorsList="comparatorsList"
                :reportThemeObject="summaryTheme"
              />
            </v-card-actions>
            <!-- Chart -->
            <v-card-actions v-if="item.chartType" :id="j.toString()">
              <Charts
                :load="summaryFetched"
                :item="item"
                :indicatorValues="summaryIndicators"
                :comparators="selectedComparators"
                :comparatorsList="comparatorsList"
                :reportThemeObject="summaryTheme"
                chartWidth="73vw"
                chartHeight="400px"
              />
            </v-card-actions>
          </v-card-actions>
        </div>
        <div v-else>
          <v-layout justify-center>
            <v-progress-circular
              indeterminate
              :size="128"
              class="w-100"
              width="3"
              align="center"
              color="#51627C"
              ><span class="text-body-2"
                >Retrieving Report</span
              ></v-progress-circular
            ></v-layout
          >
        </div>
      </template>
      <template v-slot:okBtnTitle>VIEW FULL REPORT</template>
      <template v-slot:cancelBtnTitle>CLOSE</template>
    </DynamicDialog>
  </div>
</template>

<script>
import DynamicDialog from "@/components/DynamicDialog.vue";
import Charts from "@/components/Charts";
import Tables from "@/components/Tables";
import { systemMessages } from "@/mixins/SystemMessages";

export default {
  name: "DiscoveryToolReportDialog",
  data: () => ({
    areaName: "Selected Neighbourhoods",
    summaryFetched: false,
    okBtnSpinner: false,
    summaryTheme: null,
    summaryIndicators: null,
    errors: [],
    loading: false,
  }),
  emits: ["update:showDTReportDialog"],
  mixins: [systemMessages],
  components: { DynamicDialog, Charts, Tables },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    geographies: {
      type: Array,
      default: () => [],
    },
    areas: {
      type: Array,
      default: () => [],
    },
    indicators: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async fetchSummaryReport() {
      this.errors = [];
      this.summaryFetched = false;
      this.reportDownloaded = false;
      this.reportDownloading = false;
      if (this.areaName.length === 0) {
        this.errors.push("An area name is required");
      }

      if (this.errors.length === 0) {
        try {
          this.loading = true;
          const response = await this.$axios.post(
            "/get-summary-report-discovery-tool",
            {
              name: this.areaName,
              geographies: this.geographies,
              areas: this.areas,
              indicators: this.indicators,
              geography_type_id: this.geographyTypeId,
            },
          );
          this.summaryTheme = response.data.reportTheme;
          this.summaryIndicators = response.data.indicators;
          this.comparatorsList = response.data.comparators;
          this.selectedComparators = response.data.comparators.map((c) => {
            return c.area_code;
          });
          this.loading = false;
          this.summaryFetched = true;
        } catch (error) {
          this.loading = false;
          //catch backend name validation error
          if (
            Object.prototype.hasOwnProperty.call(error.response.data, "errors")
          ) {
            if (error.response.data.errors.name !== undefined) {
              this.errors.push(error.response.data.errors.name);
            }
          } else {
            //generic error
            this.errorMsg({
              title: "Summary Report Error",
              message: error.response.data.message,
            });
          }
        }
      }
    },

    async fetchReport() {
      if (this.reportDownloading || this.reportDownloaded) {
        this.viewReportDialog = true;
        this.viewReport = true;
        return;
      }
      this.reportMapPrimaryAreaCode = this.selectedComparators[0];
      this.viewReportDialog = false;
      this.viewReport = false;
      this.selectedThemes = [];
      this.reportType = "discovery";
      this.okBtnSpinner = true;
      this.$axios
        .get("/default-discovery-tool-report")
        .then(
          function (response) {
            // handle success
            this.defaultReportObject = response.data;
            this.reportID = this.defaultReportObject.id;
            this.viewReport = true;
            this.okBtnSpinner = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to load Discovery Report",
              message: error.response.data.message,
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },

    /** dialog has been closed and user has changed selection of area checkboxes */
    selectedAreasChanged() {
      this.summaryFetched = false;
    },

    /** user has changed the indicator selection */
    selectedIndicatorChanged() {
      this.summaryFetched = false;
    },
  },
  computed: {
    defaultReportObject: {
      get() {
        return this.$store.state.defaultReportObject;
      },
      set(value) {
        this.$store.commit("setDefaultReportObject", value);
      },
    },
    reportType: {
      get() {
        return this.$store.state.reportType;
      },
      set(value) {
        this.$store.commit("setReportType", value);
      },
    },
    viewReportDialog: {
      get() {
        return this.$store.state.viewReportDialog;
      },
      set(value) {
        this.$store.commit("setViewReportDialog", value);
      },
    },
    reportDownloading: {
      get() {
        return this.$store.state.reportDownloading;
      },
      set(value) {
        this.$store.commit("setReportDownloading", value);
      },
    },
    reportDownloaded: {
      get() {
        return this.$store.state.reportDownloaded;
      },
      set(value) {
        this.$store.commit("setReportDownloaded", value);
      },
    },
    aggregationProgressPercentage: {
      get() {
        return this.$store.state.aggregationProgressPercentage;
      },
      set(value) {
        this.$store.commit("setAggregationProgressPercentage", value);
      },
    },
    reportLoadingProgress: {
      get() {
        return this.$store.state.reportLoadingProgress;
      },
      set(value) {
        this.$store.commit("setReportLoadingProgress", value);
      },
    },
    reportID: {
      get() {
        return this.$store.state.reportID;
      },
      set(value) {
        this.$store.commit("setReportID", value);
      },
    },
    viewReport: {
      get() {
        return this.$store.state.viewReport;
      },
      set(value) {
        this.$store.commit("setViewReport", value);
      },
    },
    comparatorsList: {
      get() {
        return this.$store.state.comparatorsList;
      },
      set(value) {
        this.$store.commit("setComparatorsList", value);
      },
    },
    selectedComparators: {
      get() {
        return this.$store.state.selectedComparators;
      },
      set(value) {
        this.$store.commit("setSelectedComparators", value);
      },
    },
    reportMapPrimaryAreaCode: {
      get() {
        return this.$store.state.reportMapPrimaryAreaCode;
      },
      set(value) {
        this.$store.commit("setReportMapPrimaryAreaCode", value);
      },
    },
    whichCountryThemes: {
      get() {
        return this.$store.state.whichCountryThemes;
      },
      set(value) {
        this.$store.commit("setWhichCountryThemes", value);
      },
    },
    selectedThemes: {
      get() {
        return this.$store.state.selectedThemes;
      },
      set(value) {
        this.$store.commit("setSelectedThemes", value);
      },
    },
    showNameDialog: {
      get: function () {
        //get parent v-model state
        return this.show && this.summaryFetched === false;
      },
      set: function (newVal) {
        //update parent v-model state
        this.$emit("update:showDTReportDialog", newVal);
      },
    },
    showSummaryDialog: {
      get: function () {
        return (
          this.show && (this.summaryFetched === true || this.loading === true)
        );
      },
      set: function (newVal) {
        //update parent v-model state
        this.$emit("update:showDTReportDialog", newVal);
      },
    },
    geographyTypeId: {
      get: function () {
        return this.$store.state.DTSelectedStdGeography;
      },
    },
  },
};
</script>

<style scoped>
.text :deep(p) {
  margin-top: 1em;
}
</style>
