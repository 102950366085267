import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import store from "./store/index.js";
import { vuetifyProTipTap } from "@/tipTap/TipTap.js";
import mitt from "mitt";
import cloneDeep from "lodash/cloneDeep";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import * as styles from "vuetify/styles";

// declare these
const vuetify = createVuetify({
  components,
  directives,
  styles,
});
const app = createApp(App);

// global methods / props
app.config.globalProperties.$cloneDeep = cloneDeep;
app.config.globalProperties.emit = mitt();
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$axios.defaults.withCredentials = true;
app.config.globalProperties.$axios.defaults.baseURL =
  location.protocol + "//" + process.env.VUE_APP_API_PATH;

// add these to the app
app.use(router);
app.use(store);
app.use(vuetify);
app.use(vuetifyProTipTap);

// Log the user out if they try to access an API they don't have access to
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    //return enforced timeout/cancelled requests to caller
    if (
      Object.prototype.hasOwnProperty.call(error, "code") &&
      error.code === "ECONNABORTED"
    ) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      store.commit("resetConfig");
      axios
        .post("/logout")
        .catch(function (error) {
          // it would appear there is something wrong...probably an invalid bearer token
          console.error("(logout) invalid user details and or token" + error);
        })
        .then(function () {
          // clear the bearer cookie and reload the app
          document.cookie =
            "BEARER_TOKEN=; domain=.localinsight.org; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          router.go();
        });
    } else {
      return Promise.reject(error);
    }
  },
);

// open the portal!
app.mount("#app");
