<template>
  <div v-if="load" class="pt-0 fade-in-image">
    <IndicatorDetails
      v-model:mapAboutIndDialog="viewInfoDialog"
      :viewInfo="viewInfoObject"
    />
    <!-- Standard Table Type -->
    <v-card-text class="itemMetadata min-accessible-size">
      <p>{{ item.itemName }}</p>
    </v-card-text>
    <table
      v-if="!item.HTMLTableType || item.HTMLTableType == 1"
      class="blueTable"
    >
      <thead>
        <tr>
          <th style="width: 30vw">Indicator</th>
          <th
            v-for="(comparator, index) in comparators"
            :key="index"
            style="width: 10vw; max-width: 10vw; padding: 10px"
          >
            {{ comparitorName(comparator) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(indicator, index) in HTMLTableIndicators" :key="index">
          <td class="indicatorColumn">
            <span class="viewInfo" @click="viewIndicatorInformation(indicator)"
              >{{
                item.use_short_indicator_name && indicator.shortName !== null
                  ? indicator.shortName
                  : indicator.indicatorName
              }}
              {{
                item.concatenate_dates
                  ? " (" + indicator.dateCoverageText + ")"
                  : ""
              }}</span
            >
          </td>
          <td
            align="center"
            v-for="(comparator, index) in comparators"
            :key="index"
          >
            <span>
              {{
                rateOrValue(
                  comparator +
                    "." +
                    indicator.indicatorCode +
                    "." +
                    indicator.date +
                    "." +
                    indicator.indicatorName,
                )
              }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Rate & Count Table Type -->
    <table v-if="item.HTMLTableType == 2" class="blueTable">
      <thead>
        <tr>
          <th style="width: 20vw">Indicator</th>
          <th
            colspan="2"
            :style="
              'width:' +
              100 / (comparators.length + 1) +
              'px; max-width:' +
              100 / (comparators.length + 1) +
              'px; padding: 10px'
            "
            v-for="(comparator, index) in comparators"
            :key="index"
          >
            {{ comparitorName(comparator) }}
          </th>
        </tr>
        <tr>
          <th></th>
          <template v-for="comparator in comparators" :key="comparator.id">
            <th>Count</th>
            <th>Rate</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(indicator, index) in HTMLTableIndicators" :key="index">
          <td class="indicatorColumn">
            <span class="viewInfo" @click="viewIndicatorInformation(indicator)"
              >{{
                item.use_short_indicator_name && indicator.shortName !== null
                  ? indicator.shortName
                  : indicator.indicatorName
              }}
              {{
                item.concatenate_dates
                  ? " (" + indicator.dateCoverageText + ")"
                  : ""
              }}</span
            >
          </td>
          <template
            v-for="(comparator, index) in comparators"
            :key="'comparator-' + comparator + '-' + index"
          >
            <td align="center">
              {{
                indicatorValues[
                  comparator +
                    "." +
                    indicator.indicatorCode +
                    "." +
                    indicator.date +
                    "." +
                    (item.use_short_indicator_name &&
                    indicator.shortName !== null
                      ? indicator.shortName
                      : indicator.indicatorName)
                ]
              }}
            </td>
            <td align="center">
              {{
                indicatorValues[
                  comparator +
                    "." +
                    indicator.indicatorCode +
                    "." +
                    indicator.date +
                    "." +
                    (item.use_short_indicator_name &&
                    indicator.shortName !== null
                      ? indicator.shortName
                      : indicator.indicatorName) +
                    ".rate"
                ]
              }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <!-- Domain Table Type -->
    <table v-if="item.HTMLTableType == 3" class="blueTable">
      <thead>
        <tr>
          <th style="width: 20%">Indicator</th>
          <th style="width: 30%" align="center">
            {{ item.domainHTMLTableColOneText }}
          </th>
          <th style="width: 20%">Indicator</th>
          <th style="width: 30%" align="center">
            {{ item.domainHTMLTableColOneText }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>&nbsp;</td>
          <td>
            <table width="100%" border="0">
              <tr>
                <td
                  :style="
                    'width:' +
                    100 / comparators.length +
                    'px; max-width:' +
                    100 / comparators.length +
                    'px'
                  "
                  align="center"
                  v-for="(comparator, index) in comparators"
                  :key="index"
                >
                  <strong>{{ comparitorName(comparator) }}</strong>
                </td>
              </tr>
            </table>
          </td>
          <td>&nbsp;</td>
          <td>
            <table width="100%" border="0">
              <tr>
                <td
                  :style="
                    'width:' +
                    100 / comparators.length +
                    'px; max-width:' +
                    100 / comparators.length +
                    'px'
                  "
                  align="center"
                  v-for="(comparator, index) in comparators"
                  :key="index"
                >
                  <strong>{{ comparitorName(comparator) }}</strong>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table class="blueTable" style="width: 100% !important">
              <tbody>
                <tr
                  style="height: 50px"
                  v-for="(indicator, index) in domainHTMLTableColOne"
                  :key="index"
                >
                  <td class="indicatorColumn">
                    <span
                      class="viewInfo"
                      @click="viewIndicatorInformation(indicator)"
                      >{{
                        item.use_short_indicator_name &&
                        indicator.shortName !== null
                          ? indicator.shortName
                          : indicator.indicatorName
                      }}
                      {{
                        item.concatenate_dates
                          ? " (" + indicator.dateCoverageText + ")"
                          : ""
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table class="blueTable" style="width: 100% !important">
              <tbody>
                <tr
                  style="height: 50px"
                  v-for="(indicator, index) in domainHTMLTableColOne"
                  :key="index"
                >
                  <td>
                    <table width="100%" border="0">
                      <tr>
                        <td
                          :style="
                            'width:' +
                            100 / comparators.length +
                            'px; max-width:' +
                            100 / comparators.length +
                            'px'
                          "
                          align="center"
                          v-for="(comparator, index) in comparators"
                          :key="index"
                        >
                          {{
                            indicatorValues[
                              comparator +
                                "." +
                                indicator.indicatorCode +
                                "." +
                                indicator.date +
                                "." +
                                (item.use_short_indicator_name &&
                                indicator.shortName !== null
                                  ? indicator.shortName
                                  : indicator.indicatorName)
                            ]
                          }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table class="blueTable" style="width: 100% !important">
              <tbody>
                <tr
                  style="height: 50px"
                  v-for="(indicator, index) in domainHTMLTableColTwo"
                  :key="index"
                >
                  <td class="indicatorColumn">
                    <span
                      class="viewInfo"
                      @click="viewIndicatorInformation(indicator)"
                      >{{
                        item.use_short_indicator_name &&
                        indicator.shortName !== null
                          ? indicator.shortName
                          : indicator.indicatorName
                      }}
                      {{
                        item.concatenate_dates
                          ? " (" + indicator.dateCoverageText + ")"
                          : ""
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table class="blueTable" style="width: 100% !important">
              <tbody>
                <tr
                  style="height: 50px"
                  v-for="(indicator, index) in domainHTMLTableColTwo"
                  :key="index"
                >
                  <td>
                    <table width="100%" border="0">
                      <tr>
                        <td
                          :style="
                            'width:' +
                            100 / comparators.length +
                            'px; max-width:' +
                            100 / comparators.length +
                            'px'
                          "
                          align="center"
                          v-for="(comparator, index) in comparators"
                          :key="index"
                        >
                          {{
                            indicatorValues[
                              comparator +
                                "." +
                                indicator.indicatorCode +
                                "." +
                                indicator.date +
                                "." +
                                (item.use_short_indicator_name &&
                                indicator.shortName !== null
                                  ? indicator.shortName
                                  : indicator.indicatorName)
                            ]
                          }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
    <v-card-text class="itemMetadata min-accessible-size">
      <p style="margin: 0">Source: {{ item.itemSource }}</p>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2 button-icon-text"
        variant="elevated"
        color="blue-grey"
        @click="exportData"
        :aria-label="'Download ' + getFileName()"
        :title="'Download ' + getFileName()"
      >
        <v-icon size="large">mdi-microsoft-excel</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import IndicatorDetails from "@/components/IndicatorDetailsDialog";
import { exportCSVFile } from "@/mixins/ExportCSVFile";

export default {
  name: "TABLES",
  data: () => ({
    exportCSVFile: exportCSVFile,
    viewInfoDialog: false,
    viewInfoObject: null,
  }),
  components: {
    IndicatorDetails,
  },
  computed: {
    HTMLTableIndicators: {
      get() {
        let array = [];
        for (var i = 0; i < this.item.indicators.length; i++) {
          if (this.item.indicators[i].indicatorHTMLTableRef) {
            array.push(this.item.indicators[i]);
          }
        }
        return array;
      },
    },
    domainHTMLTableColOne: {
      get() {
        let array = [];
        for (var i = 0; i < this.HTMLTableIndicators.length; i++) {
          if (!this.HTMLTableIndicators[i].domainHTMLTableColTwo) {
            array.push(this.HTMLTableIndicators[i]);
          }
        }
        return array;
      },
    },
    domainHTMLTableColTwo: {
      get() {
        let array = [];
        for (var i = 0; i < this.HTMLTableIndicators.length; i++) {
          if (this.HTMLTableIndicators[i].domainHTMLTableColTwo) {
            array.push(this.HTMLTableIndicators[i]);
          }
        }
        return array;
      },
    },
  },
  props: {
    load: Boolean,
    indicatorValues: {
      type: Object,
      default: () => ({}),
    },
    comparators: {
      type: Array,
      default: () => [],
    },
    comparatorsList: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    reportThemeObject: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {},
  methods: {
    getFileName(underscores = false) {
      let filename =
        `Local Insight ${this.reportThemeObject.name} ${this.item.itemName || ""}`.trim();

      return underscores ? filename.replaceAll(" ", "_") : filename;
    },
    viewIndicatorInformation(indicator) {
      this.viewInfoObject = null;
      this.viewInfoDialog = true;
      // debugger;
      // call this
      this.$axios
        .get(
          "/standard-metadata/by-code-and-date/" +
            indicator.indicatorCode +
            "/" +
            indicator.indicatorDate,
        )
        .then(
          function (response) {
            // handle success
            this.viewInfoObject = { indicatorInfo: response.data };
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to search indicators",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    exportData() {
      // Build the export
      var data = [];
      var i;
      var j;
      var value;
      var row;
      var key;
      var filename = this.getFileName(true);

      if (!this.item.HTMLTableType || this.item.HTMLTableType == 1) {
        // First row of headers
        row = {
          indicator: "Indicator",
        };
        for (i = 0; i < this.comparators.length; i++) {
          row[this.comparators[i]] = this.comparitorName(this.comparators[i]);
        }
        data.push(row);

        // Indicator names and values

        // HTMLTableIndicators[i] is each indicator
        for (i = 0; i < this.HTMLTableIndicators.length; i++) {
          row = {};
          // Add date to all indicators
          row[this.HTMLTableIndicators[i].indicatorName] =
            '"' +
            this.HTMLTableIndicators[i].indicatorName +
            " (" +
            this.HTMLTableIndicators[i].dateCoverageText +
            ')"';

          // comparators[j] is each comparator
          for (j = 0; j < this.comparators.length; j++) {
            key =
              this.comparators[j] +
              "." +
              this.HTMLTableIndicators[i].indicatorCode +
              "." +
              this.HTMLTableIndicators[i].date +
              "." +
              this.HTMLTableIndicators[i].indicatorName;

            if (key.includes("*")) {
              key = key + ".rate";
            }

            value = this.indicatorValues[key];
            if (value) {
              value = value.replace(/[,]/g, "");
              row[this.comparators[j]] = value;
            } else {
              row[this.comparators[j]] = "";
            }
          }

          data.push(row);
        }
      } else if (this.item.HTMLTableType == 2) {
        // First row of headers
        row = {
          indicator: "Indicator",
        };
        for (i = 0; i < this.comparators.length; i++) {
          row[this.comparators[i] + "_rate"] = this.comparitorName(
            this.comparators[i],
          );
          row[this.comparators[i] + "_count"] = "";
        }
        data.push(row);

        // Second row of headers
        row = {
          indicator: " ",
        };

        for (i = 0; i < this.comparators.length; i++) {
          row[this.comparators[i] + "_rate"] = "Count";
          row[this.comparators[i] + "_count"] = "Rate";
        }
        data.push(row);

        // Indicator names and values

        // HTMLTableIndicators[i] is each indicator
        for (i = 0; i < this.HTMLTableIndicators.length; i++) {
          row = {};
          row[this.HTMLTableIndicators[i].indicatorName] =
            '"' +
            this.HTMLTableIndicators[i].indicatorName +
            " (" +
            this.HTMLTableIndicators[i].dateCoverageText +
            ')"';

          // comparators[j] is each comparator
          for (j = 0; j < this.comparators.length; j++) {
            var count =
              this.indicatorValues[
                this.comparators[j] +
                  "." +
                  this.HTMLTableIndicators[i].indicatorCode +
                  "." +
                  this.HTMLTableIndicators[i].date +
                  "." +
                  this.HTMLTableIndicators[i].indicatorName
              ];
            if (count) {
              count = count.replace(/[,]/g, "");
              row[this.comparators[j] + "_count"] = count;
            } else {
              row[this.comparators[j] + "_count"] = "";
            }

            var rate =
              this.indicatorValues[
                this.comparators[j] +
                  "." +
                  this.HTMLTableIndicators[i].indicatorCode +
                  "." +
                  this.HTMLTableIndicators[i].date +
                  "." +
                  this.HTMLTableIndicators[i].indicatorName +
                  ".rate"
              ];
            if (rate) {
              rate = rate.replace(/[,]/g, "");
              row[this.comparators[j] + "_rate"] = rate;
            } else {
              row[this.comparators[j] + "_rate"] = "";
            }
          }

          data.push(row);
        }
      } else if (this.item.HTMLTableType == 3) {
        // First row of headers
        row = {
          indicator_colone: "Indicator",
        };
        for (i = 0; i < this.comparators.length; i++) {
          row[this.comparators[i] + "_colone"] = this.comparitorName(
            this.comparators[i],
          );
        }
        row["indicator_coltwo"] = "Indicator";
        for (i = 0; i < this.comparators.length; i++) {
          row[this.comparators[i] + "_coltwo"] = this.comparitorName(
            this.comparators[i],
          );
        }
        data.push(row);

        // Indicator names and values

        // domainHTMLTableColOne[i] is each indicator for left column
        for (i = 0; i < this.domainHTMLTableColOne.length; i++) {
          row = {};
          row[this.domainHTMLTableColOne[i].indicatorName + "_colone"] =
            this.domainHTMLTableColOne[i].indicatorName +
            " (" +
            this.domainHTMLTableColOne[i].dateCoverageText +
            ")";

          // comparators[j] is each comparator
          for (j = 0; j < this.comparators.length; j++) {
            value =
              this.indicatorValues[
                this.comparators[j] +
                  "." +
                  this.domainHTMLTableColOne[i].indicatorCode +
                  "." +
                  this.domainHTMLTableColOne[i].date +
                  "." +
                  this.domainHTMLTableColOne[i].indicatorName
              ];
            if (value) {
              value = value.replace(/[,]/g, "");
              row[this.comparators[j] + "_colone"] = value;
            } else {
              row[this.comparators[j] + "_colone"] = "";
            }
          }

          // Check if there's an indicator on the right side for this row
          if (typeof this.domainHTMLTableColTwo[i] !== "undefined") {
            row[this.domainHTMLTableColTwo[i].indicatorName + "_coltwo"] =
              this.domainHTMLTableColTwo[i].indicatorName +
              " (" +
              this.domainHTMLTableColTwo[i].dateCoverageText +
              ")";

            // comparators[j] is each comparator
            for (j = 0; j < this.comparators.length; j++) {
              value =
                this.indicatorValues[
                  this.comparators[j] +
                    "." +
                    this.domainHTMLTableColTwo[i].indicatorCode +
                    "." +
                    this.domainHTMLTableColTwo[i].date +
                    "." +
                    this.domainHTMLTableColTwo[i].indicatorName
                ];
              if (value) {
                value = value.replace(/[,]/g, "");
                row[this.comparators[j] + "_coltwo"] = value;
              } else {
                row[this.comparators[j] + "_coltwo"] = "";
              }
            }
          }

          data.push(row);
        }
      }
      this.exportCSVFile(null, data, filename);
    },
    rateOrValue(key) {
      // if it's a standard HTML table and it's a dynamic indicater then return the rate and NOT the count
      if (key.includes("*")) {
        return this.indicatorValues[key + ".rate"];
      } else {
        return this.indicatorValues[key];
      }
    },
    comparitorName(comparator) {
      for (var i = 0; i < this.comparatorsList.length; i++) {
        if (this.comparatorsList[i].area_code === comparator) {
          return this.comparatorsList[i].area_name;
        }
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
table.blueTable {
  border: 0px solid #1c6ea4;
  background-color: #ffffff;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

table.blueTable td,
table.blueTable th {
  border: 1px solid;
  padding: 3px 10px;
  color: black !important;
}

table.blueTable tbody td {
  padding: 3px 10px;
  font-size: 12pt;
  border: 1px solid;
}

table.blueTable tr:nth-child(even) {
  background: #ffffff;
}

table.blueTable thead {
  background: #3edbcf;
  color: black !important;
  border: 1px solid;
}

table.blueTable thead th {
  font-size: 12pt;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
  border: 1px solid;
}

table.blueTable tfoot td {
  font-size: 12pt;
  border: 1px solid;
}

table.blueTable tfoot .links {
  text-align: right;
}

table.blueTable tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}

.indicatorColumn {
  padding: 3px 10px !important;
  border: 1px solid black;
}

.viewInfo {
  text-decoration: underline;
  cursor: pointer;
}

.itemMetadata {
  background: #e5e8e8;
}

.fade-in-image {
  width: 100%;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
