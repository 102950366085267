import {
  VuetifyTiptap,
  VuetifyViewer,
  createVuetifyProTipTap,
  defaultBubbleList,
} from "vuetify-pro-tiptap";
import {
  BaseKit,
  Image,
  Fullscreen,
  Table,
  TextAlign,
  Clear,
  TaskList,
} from "vuetify-pro-tiptap";
import "vuetify-pro-tiptap/style.css";

export const vuetifyProTipTap = createVuetifyProTipTap({
  // Set default lang
  lang: "en",
  // Set markdown theme
  markdownTheme: "github",
  // Global registration app.component
  components: {
    VuetifyTiptap,
    VuetifyViewer,
  },
  // Global registration extensions
  extensions: [
    BaseKit.configure({
      placeholder: {
        placeholder: "Enter some text...",
      },
      bubble: {
        // default config
        list: {
          image: [
            "float-left",
            "float-none",
            "float-right",
            "divider",
            "size-small",
            "size-medium",
            "size-large",
            "divider",
            "textAlign",
            "divider",
            "image",
            "textAlign",
            "image-aspect-ratio",
            "remove",
          ],
          text: [
            "bold",
            "italic",
            "underline",
            "strike",
            "divider",
            "color",
            "highlight",
            "textAlign",
            "divider",
            "link",
          ],
          video: ["video", "remove"],
        },
        defaultBubbleList: (editor) => {
          // You can customize the bubble menu here
          return defaultBubbleList(editor); // default customize bubble list
        },
      },
    }),
    Image.configure({
      // image configs
    }),
    Fullscreen.configure({
      // Generate a VSpacer after the button
      spacer: true,
    }),
    Table.configure({ divider: true }),
    TextAlign,
    Clear,
    TaskList,
  ],
});
