<template>
  <v-card-actions>
    <v-dialog v-model="open" max-width="600px">
      <template v-slot:activator="{ props }">
        <v-btn
          variant="elevated"
          v-bind="props"
          tile
          color="primary"
          title="Create New Custom Client Config Option"
          aria-label="Create"
        >
          Create new
        </v-btn>
      </template>
      <v-card tile>
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6 text-center pa-2"
          >Create New Custom Client Config Option</v-card-title
        >
        <v-card-text class="pa-0">
          <v-form
            ref="form"
            shape="round"
            color="#3498db"
            title="Create a New Custom Client Config Option"
            subtitle
          >
            <v-container>
              <v-text-field
                ref="name"
                type="text"
                v-model="newClientConfigOption.name"
                :error-messages="newClientConfigOptionErrors.name"
                label="Client config option name"
                :rules="rules.name"
                density="compact"
                rounded="0"
                variant="outlined"
              ></v-text-field>
              <v-text-field
                ref="slug"
                type="text"
                v-model="newClientConfigOption.slug"
                :error-messages="newClientConfigOptionErrors.slug"
                :rules="rules.slug"
                label="Slug"
                density="compact"
                rounded="0"
                variant="outlined"
              ></v-text-field>
              <v-select
                v-model="newClientConfigOption.fieldType"
                :items="['text', 'text-area', 'checkbox']"
                label="Field Type"
                density="compact"
                rounded="0"
                variant="outlined"
              >
              </v-select>
              <v-textarea
                v-model="newClientConfigOption.description"
                label="Description"
                :error-messages="newClientConfigOptionErrors.description"
                ref="description"
                :rules="rules.description"
                density="compact"
                rounded="0"
                variant="outlined"
              ></v-textarea>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card>
          <v-card-actions>
            <v-btn
              variant="elevated"
              color="error"
              tile
              @click="open = false"
              aria-label="Close"
            >
              Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              variant="elevated"
              tile
              color="success"
              @click="postNewClientConfigOption()"
              aria-label="Save Changes"
            >
              Save Changes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-card-actions>
</template>

<script>
export default {
  name: "ClientConfigOptionCreate",
  data: () => ({
    newClientConfigOption: {
      name: "",
      slug: "",
      fieldType: "text",
      description: "",
    },
    newClientConfigOptionErrors: {
      name: [],
      slug: [],
      description: [],
    },
    open: false,
    rules: {
      name: [(v) => !!v || "Name is required"],
      slug: [
        (v) => !!v || "Slug is required",
        (v) => (v && !/\s/g.test(v)) || "No Spaces Please!",
      ],
      description: [(v) => !!v || "Description is required"],
    },
  }),
  computed: {
    allClientConfigOptions: {
      get() {
        return this.$store.state.allClientConfigOptions;
      },
      set(value) {
        this.$store.commit("setAllClientConfigOptions", value);
      },
    },
  },
  methods: {
    postNewClientConfigOption() {
      this.resetApiValidationErrors();

      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          this.emit.emit("systemBusy", true);
          // make the call!
          this.$axios
            .post("/client-config-options", this.newClientConfigOption)
            .then((response) => {
              this.emit.emit("systemMessage", {
                message: "Custom Client config option created",
                title: "Success!",
                timeout: 4000,
                colour: "green",
              });
              // add the new one to allClientConfigOptions
              this.allClientConfigOptions.push(response.data);
              this.open = false;
              this.emit.emit("systemBusy", false);
            })
            .catch((error) => {
              this.emit.emit("systemBusy", false);
              this.emit.emit("systemMessage", {
                title: "Error",
                message: error.response.data.message,
                timeout: -1,
                colour: "error",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
    apiValidationErrors(errors) {
      // add the errors
      for (const field in errors) {
        this.newClientConfigOptionErrors[field] = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (const field in this.newClientConfigOptionErrors) {
        this.newClientConfigOptionErrors[field] = null;
      }
    },
  },
  watch: {
    open: {
      handler() {
        // Clear form and validation messages if closed with any empty textboxes
        if (!this.open) {
          this.resetApiValidationErrors();
          this.$refs.form.reset();
        }
      },
    },
  },
};
</script>

<style scoped></style>
