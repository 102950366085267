<template>
  <div class="text-center">
    <v-dialog v-model="manageReportDialogue" width="600">
      <v-card-title
        :style="
          'background-color: ' +
          this.$store.state.config.siteConfig.toolbar_colour
        "
        class="text-h6 pa-4"
      >
        <v-spacer>
          {{ manageReportTitle }}
        </v-spacer>
      </v-card-title>
      <!--  Share report  -->
      <v-card v-if="shareActions" tile>
        <v-card-text
          v-if="
            !savedReportsList[shareThis[0]].reports[shareThis[1]].public_report
          "
          class="ps-5 pb-3"
          >Important: Shared favourite reports can only be accessed by
          individuals who have both a Local Insight account and are members of
          your organisation
        </v-card-text>
        <v-card-actions class="pr-5 pl-5 pb-0 pt-5">
          <v-text-field
            v-model="shareLink"
            type="text"
            label="Report Link"
            readonly
            variant="outlined"
            density="compact"
            rounded="0"
            append-icon="mdi-content-copy"
            label:append-icon="Click to Copy Link"
            @click:append="copyLink"
            :hint="clipboardMessage"
            persistent-hint
          ></v-text-field>
        </v-card-actions>
        <v-divider></v-divider>
        <v-card class="pa-1 pb-2">
          <v-btn
            color="primary"
            tile
            variant="elevated"
            class="pa-2 mt-1 ml-2"
            title="back to list"
            @click="manageReportDialogue = false"
            aria-label="back"
          >
            back
          </v-btn>
        </v-card>
      </v-card>
      <!--  Confirm deletion  -->
      <v-card v-else-if="confirmDeletion" height="19vh" tile>
        <v-card-text
          class="text-center text-subtitle-1"
          style="display: flex; justify-content: center; align-items: center"
        >
          Are you sure you want to delete this report?
        </v-card-text>
        <v-card>
          <v-card-actions>
            <v-btn
              tile
              variant="elevated"
              color="success"
              @click="manageReportDialogue = false"
              aria-label="cancel"
              >cancel
            </v-btn>
            <v-spacer />
            <v-btn
              tile
              variant="elevated"
              color="error"
              @click="deleteSavedReport()"
              aria-label="delete"
            >
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span></template
              >
              delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
      <!--  View description  -->
      <v-card v-else-if="viewDescription" tile>
        <v-card-text style="height: 60vh; overflow: auto">
          <v-card-actions class="text-h6">
            This is a
            {{
              savedReportsList[reportDescription[0]].reports[
                reportDescription[1]
              ].summary_report
                ? "Summary"
                : "Custom"
            }}
            report for
            {{
              savedReportsList[reportDescription[0]].reports[
                reportDescription[1]
              ].description.title
            }}
          </v-card-actions>
          <v-card-actions style="color: gray"
            >{{
              savedReportsList[reportDescription[0]].reports[
                reportDescription[1]
              ].description.subTitle
            }}
          </v-card-actions>
          <v-card-actions>
            <ul class="ml-4">
              <li
                v-for="(item, index) in savedReportsList[reportDescription[0]]
                  .reports[reportDescription[1]].description.themes"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </v-card-actions>
        </v-card-text>
        <v-card>
          <v-card-actions>
            <v-btn
              color="primary"
              variant="elevated"
              tile
              class="ml-2"
              title="close"
              @click="manageReportDialogue = false"
              aria-label="close"
            >
              close
            </v-btn>
            <v-spacer />
            <v-btn
              color="success"
              variant="elevated"
              tile
              class="mr-2"
              title="load this report"
              @click="loadDescribedReport()"
              aria-label="Load Report"
            >
              Load Report
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="favPubDialogue" width="1100" persistent>
      <v-card-title
        :style="
          'background-color: ' + $store.state.config.siteConfig.toolbar_colour
        "
        class="text-h6 pa-1"
      >
        <v-card-actions class="mx-2">
          {{ label }}
          <v-spacer />
          <div v-if="!gettingSavedReports" style="width: 40%">
            <v-text-field
              v-model="searchSavedReports"
              single-line
              label="Filter reports by name"
              clearable
              density="compact"
              rounded="0"
              variant="solo"
              hide-details="true"
              class="text-black"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </div>
        </v-card-actions>
      </v-card-title>
      <!--   Loading reports   -->
      <v-card v-if="gettingSavedReports" height="80vh">
        <v-card-actions style="width: 100%; margin-top: 200px">
          <div class="progressText">
            <v-progress-circular
              :size="250"
              :width="2"
              color="#51627C"
              indeterminate
              >Loading {{ label }}
            </v-progress-circular>
          </div>
        </v-card-actions>
      </v-card>
      <!--   View list of reports -->
      <v-card v-else tile>
        <v-card-text class="pa-0" style="height: 74vh; overflow: auto">
          <v-card-text v-if="!savedReportsList.length" class="text-center"
            >You have not created any {{ this.label }}
          </v-card-text>
          <v-card-text
            v-else-if="searchSavedReports && !searchHits"
            class="text-center"
            >No custom area matches your search term
          </v-card-text>
          <v-data-table
            v-else
            :headers="savedReportsListHeaders"
            :items="savedReportsList"
            hover
            :items-per-page="-1"
            fixed-header
            class="elevation-1 py-2"
          >
            <template v-slot:item="{ item, index }">
              <tr
                v-if="!item.hidden"
                style="font-weight: bolder; background-color: #ddd"
              >
                <td colspan="5">
                  {{ item.category_name }}
                </td>
              </tr>
              <template v-for="(report, i) in item['reports']">
                <tr
                  :key="report.id"
                  v-if="!report.hidden"
                  @click="openFavPubReport(index, report.summary_report, i)"
                  style="cursor: pointer"
                >
                  <td style="max-width: 30vh">
                    {{ report.comparitorOneName }}
                  </td>
                  <td>{{ report.comparitorTwoName }}</td>
                  <td>{{ report.comparitorThreeName }}</td>
                  <td>
                    <template v-if="report.summary_report"> Summary </template>
                    <template v-else>
                      Custom<br />
                      ({{ report.assigned_themes.length }} theme{{
                        dynamicEnding(report.assigned_themes)
                      }})
                    </template>
                  </td>
                  <td>
                    <v-btn
                      variant="text"
                      icon="mdi-trash-can-outline"
                      @click.stop="deleteAction(report)"
                      title="Delete"
                      aria-label="delete"
                    />
                    <v-btn
                      variant="text"
                      icon="mdi-information-outline"
                      @click.stop="loadDescription(index, i)"
                      title="View this report's description"
                      aria-label="View this report's description"
                    />
                    <v-btn
                      variant="text"
                      icon="mdi-share-variant"
                      @click.stop="share(index, i)"
                      title="Share this report"
                      aria-label="Share this report"
                    />
                  </td>
                </tr>
              </template>
            </template>
            <template #bottom></template>
          </v-data-table>
        </v-card-text>
        <v-card tile>
          <v-card-actions>
            <v-btn
              color="error"
              tile
              variant="elevated"
              title="close list"
              width="90"
              @click="$emit('update:savedReportsDialogue', false)"
              aria-label="close"
            >
              close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { copyToClipboard } from "@/mixins/CopyToClipboard";
export default {
  name: "SavedReportsList",
  data: () => ({
    shareActions: false,
    shareLink: null,
    clipboardMessage: "",
    selectedSavedReport: null,
    selectedReport: null,
    publicReports: false,
    viewDescription: false,
    gettingSavedReports: false,
    manageReportDialogue: false,
    shareThis: null,
    savedReportsList: [],
    searchSavedReports: "",
    searchHits: 0,
    reportDescription: null,
    confirmDeletion: false,
    savedReportsListHeaders: [
      {
        title: "Report for",
        sortable: false,
        value: "comparitor_one",
      },
      {
        title: "Comparator 1",
        sortable: false,
      },
      {
        title: "Comparator 2",
        sortable: false,
        value: "comparitor_three",
      },
      {
        title: "Report type",
        sortable: false,
        value: "summary_report",
      },
      {
        title: "Actions",
        sortable: false,
        value: "name",
      },
    ],
  }),
  props: {
    savedReportsDialogue: {
      required: true,
    },
  },
  computed: {
    viewReport: {
      get() {
        return this.$store.state.viewReport;
      },
      set(value) {
        this.$store.commit("setViewReport", value);
      },
    },
    label: {
      get() {
        return this.publicReports ? "Public Reports" : "Favourites";
      },
    },
    viewReportDialog: {
      get() {
        return this.$store.state.viewReportDialog;
      },
      set(value) {
        this.$store.commit("setViewReportDialog", value);
      },
    },
    manageReportTitle() {
      if (this.shareActions) return "Share Report";
      if (this.viewDescription) return "Report Description";
      if (this.confirmDeletion) return "Confirm Deletion";
      return "";
    },
    comparatorsList: {
      get() {
        return this.$store.state.comparatorsList;
      },
      set(value) {
        this.$store.commit("setComparatorsList", value);
      },
    },
    reportType: {
      get() {
        return this.$store.state.reportType;
      },
      set(value) {
        this.$store.commit("setReportType", value);
      },
    },
    selectedThemes: {
      get() {
        return this.$store.state.selectedThemes;
      },
      set(value) {
        this.$store.commit("setSelectedThemes", value);
      },
    },
    favPubDialogue() {
      return this.savedReportsDialogue === 0 || this.savedReportsDialogue === 1;
    },
    selectedComparators: {
      get() {
        return this.$store.state.selectedComparators;
      },
      set(value) {
        this.$store.commit("setSelectedComparators", value);
      },
    },
    reportMapPrimaryAreaCode: {
      get() {
        return this.$store.state.reportMapPrimaryAreaCode;
      },
      set(value) {
        this.$store.commit("setReportMapPrimaryAreaCode", value);
      },
    },
  },
  methods: {
    listfavPubs(publicReports = 0) {
      if (publicReports !== this.publicReports) {
        this.publicReports = publicReports;
        this.selectedSavedReport = null;
      }
      this.shareActions = false;
      this.viewDescription = false;
      this.viewReport = false;
      this.gettingSavedReports = true;
      this.$axios
        .get("/saved-reports-list/" + publicReports)
        .then(
          function (response) {
            // handle success
            this.savedReportsList = response.data;
            this.gettingSavedReports = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to get saved reports",
              message: error.response.data.message,
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
    next(val) {
      this.selectedComparators = [];
      this.comparatorsList = val;
      this.comparatorsList.unshift(this.selectedReport);
      for (var i = 0; i < this.comparatorsList.length; i++) {
        this.selectedComparators.push(this.comparatorsList[i].area_code);
      }
      this.viewReport = true;
    },
    sendAnalytics() {
      this.$axios.put("/send-analytics", {
        event: "share_reports",
        report_id:
          this.savedReportsList[this.shareThis[0]].reports[this.shareThis[1]]
            .id,
        report_name:
          this.savedReportsList[this.shareThis[0]].reports[this.shareThis[1]]
            .name,
        public_report:
          this.savedReportsList[this.shareThis[0]].reports[this.shareThis[1]]
            .public_report,
        summary_report:
          this.savedReportsList[this.shareThis[0]].reports[this.shareThis[1]]
            .summary_report,
        moduleName: this.$route.path,
        pageName: this.$route.name,
      });
    },
    copyLink() {
      if (copyToClipboard(this.shareLink)) {
        this.clipboardMessage = "The link has been copied to your clipboard";
        setTimeout(() => {
          this.clipboardMessage = "";
        }, 3000);
      } else {
        this.clipboardMessage =
          "The value has NOT been copied to your clipboard";
        setTimeout(() => {
          this.clipboardMessage = "";
        }, 3000);
      }
    },
    share(index, i) {
      this.confirmDeletion = false;
      this.viewDescription = false;
      this.shareActions = true;
      this.manageReportDialogue = true;
      this.shareThis = [index, i];
      this.shareLink = window.location.href.split("#");
      this.shareLink =
        this.shareLink[0] +
        "#/map?report=" +
        this.savedReportsList[this.shareThis[0]].reports[this.shareThis[1]].id;
      // send some analytics
      this.sendAnalytics();
    },
    openFavPubReport(report, isSummary, i) {
      if (report === this.selectedSavedReport) {
        this.viewReportDialog = true;
      } else {
        this.selectedSavedReport = [report, i];
      }

      this.reportType = isSummary ? "Summary" : "Custom";
    },
    loadDescribedReport() {
      if (this.reportDescription === this.selectedSavedReport) {
        this.viewReportDialog = true;
      } else {
        this.selectedSavedReport = this.reportDescription;
      }

      this.reportType = this.savedReportsList[this.reportDescription[0]]
        .reports[this.reportDescription[1]].summary_report
        ? "Summary"
        : "Custom";
    },
    loadDescription(index, i) {
      this.shareActions = false;
      this.confirmDeletion = false;
      this.reportDescription = [index, i];
      this.viewDescription = true;
      this.manageReportDialogue = true;
    },
    deleteAction(report) {
      this.viewDescription = false;
      this.shareActions = false;
      this.confirmDeletion = report;
      this.manageReportDialogue = true;
    },
    deleteSavedReport() {
      this.manageReportDialogue = false;
      this.emit.emit("systemMessage", {
        title: "Deleting report...",
        message: "Please wait",
        timeout: 10000,
        colour: "warning",
      });
      this.$axios
        .delete("/saved-reports/" + this.confirmDeletion.id)
        .then((response) => {
          this.savedReportsList = response.data;
          this.gettingSavedReports = false;
          this.confirmDeletion = false;

          this.emit.emit("systemMessage", {
            title: "Report deleted",
            message: "Report deleted",
            timeout: 2000,
            colour: "success",
          });
        })
        .catch((error) => {
          this.gettingSavedReports = false;
          this.confirmDeletion = false;
          this.emit.emit("systemMessage", {
            title: "Error! Failed to remove user",
            message: error.response.data.message,
            timeout: -1,
            colour: "error",
          });
          this.apiValidationErrors(error.response.data.errors);
        });
    },
    dynamicEnding(array, ending = "s") {
      if (array.length > 1) return ending;
    },
  },
  watch: {
    selectedSavedReport: {
      handler() {
        if (this.selectedSavedReport !== null) {
          const [selectedCategory, selectedReport] = this.selectedSavedReport;
          const selectedReportDetails =
            this.savedReportsList[selectedCategory].reports[selectedReport];

          this.selectedReport = {
            area_code: selectedReportDetails.comparitor_one,
            area_name: selectedReportDetails.comparitorOneName,
          };

          this.selectedThemes = selectedReportDetails.assigned_themes.map(
            (theme) => ({ themeID: theme.theme_id }),
          );

          this.comparatorsList = [
            {
              area_code: selectedReportDetails.comparitor_two,
              area_name: selectedReportDetails.comparitorTwoName,
            },
            {
              area_code: selectedReportDetails.comparitor_three,
              area_name: selectedReportDetails.comparitorThreeName,
            },
          ];

          this.reportMapPrimaryAreaCode = selectedReportDetails.comparitor_one;
          this.reportDownloading = true;
          this.next(this.comparatorsList);
          this.$emit("update:savedReportsDialogue", false);
        }
      },
      deep: true,
    },
    searchSavedReports: function (search) {
      if (search) {
        for (let i = 0; i < this.savedReportsList.length; i++) {
          // Track whether to hide category and its areas
          var allAreasUnchecked = true;
          // For each custom area, set a hidden flag if it doesn't match search
          for (let j = 0; j < this.savedReportsList[i].reports.length; j++) {
            const reportMatches = this.savedReportsList[i].reports[
              j
            ].comparitorOneName
              .toLowerCase()
              .includes(search.toLowerCase());

            // set value
            this.savedReportsList[i].reports[j].hidden = !reportMatches;

            if (reportMatches) {
              allAreasUnchecked = false;
            }
          }

          // set category visibility
          this.savedReportsList[i].hidden = allAreasUnchecked;

          this.searchHits = this.savedReportsList.filter(
            (cac) => !cac.hidden,
          ).length;
        }
      } else {
        // Reset visibility when there's nothing in the filter
        for (let i = 0; i < this.savedReportsList.length; i++) {
          for (let j = 0; j < this.savedReportsList[i].reports.length; j++) {
            this.savedReportsList[i].reports[j].hidden = false;
          }
          this.savedReportsList[i].hidden = false;
        }
      }
    },
    savedReportsDialogue(action) {
      if (typeof action === "number") {
        this.listfavPubs(action);
      }
    },
  },
};
</script>
<style scoped>
.progressText {
  width: 100%;
  text-align: center;
  font-size: 14px;
  z-index: 100000;
}

.progress {
  position: relative;
  top: 22vh;
  width: 30vw;
}
</style>
