<template>
  <v-dialog v-model="showHideDialog" scrollable persistent max-width="800px">
    <v-card>
      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h5 pl-6"
        >Create New User</v-toolbar
      >
      <v-card-text>
        <v-form ref="form" v-model="validation" lazy-validation>
          <v-container>
            <v-row style="padding-top: 15px !important">
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-for="(item, key) in creationFieldSchema"
                :key="key"
                style="padding-top: 0px !important"
              >
                <Field
                  :schema="creationFieldSchema[key]"
                  v-model:value="newUser[key]"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions style="height: 20vh">
            <v-radio-group
              v-model="newUser.users_module_group_membership"
              v-if="
                newUser['client_id'] && !newUser.is_public_site_user_profile
              "
              label="Group Membership"
              hint="Select Module Membership"
              class="ml-3"
              :disabled="!clientUserModuleGroups.length"
            >
              <v-radio
                v-for="group in clientUserModuleGroups"
                :key="group.id"
                :label="group.name"
                :value="group.id"
              ></v-radio>
            </v-radio-group>
          </v-card-actions>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="fieldSchema">
        <v-btn
          variant="elevated"
          color="error"
          tile
          @click="closeDialog()"
          aria-label="Close"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          variant="elevated"
          :disable="this.$store.state.busy"
          color="success"
          tile
          @click="createNewUser()"
          aria-label="Create User"
        >
          Create User
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Field from "@/components/Fields.vue";
export default {
  name: "UserManagerCreate",
  data: () => ({
    fieldSchema: false,
    validation: false,
    showModulesLoader: false,
    newUser: {},
    clonedNewUser: {},
    clientUserModuleGroups: [],
    creationFieldSchema: {},
    selectedClientUserModuleGroupIds: [],
  }),
  components: {
    Field,
  },
  computed: {
    showHideDialog: {
      get() {
        return this.dialog ? true : false;
      },
    },
  },
  props: {
    schema: {
      type: Object,
      required: true,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    createNewUser() {
      this.resetApiValidationErrors();
      // if this passes validation then call the api
      this.$refs.form.validate().then((result) => {
        if (result.valid) {
          // let the user know what's happening
          this.emit.emit("systemMessage", {
            title: "Please wait",
            message: "Creating new User",
            timeout: -1,
            colour: "warning",
          });

          // make the call!
          this.$axios
            .post("/register", this.newUser)
            .then(() => {
              this.emit.emit("systemMessage", {
                title: "User Creation Complete",
                message: "Success!",
                timeout: 4000,
                colour: "green",
              });

              this.newUser = {};
              // close the dialogue
              this.closeDialog();
            })
            .catch((error) => {
              console.error(error);
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! User Creation Failed",
                timeout: -1,
                colour: "red",
              });
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      });
    },
    getClientUserModuleGroups() {
      this.$axios
        .get("/client-user-module-groups-all/" + this.newUser.client_id)
        .then(
          function (response) {
            // handle success
            this.clientUserModuleGroups = response.data;
            this.fieldSchema.client_user_module_groups.selectItems =
              response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get all clients",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    buildNewUser() {
      // clone the schema as we do not want any changes (api errors etc) propergating anywhere where this schema is being used
      this.fieldSchema = this.$cloneDeep(this.schema);

      for (let key in this.fieldSchema) {
        if (this.fieldSchema[key].useInCreationMode) {
          this.newUser[key] = null;
          // fieldSchema but filtered to only have fields that are required for creation
          this.creationFieldSchema[key] = this.fieldSchema[key];
        }
      }
      this.newUser.users_module_group_membership = [];
      this.clonedNewUser = this.$cloneDeep(this.newUser);
    },
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (const field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog && Object.keys(this.newUser).length === 0) {
          this.buildNewUser();
        }
        if (!this.dialog) {
          this.resetApiValidationErrors();
          this.$refs.form.reset();
        }
      },
      deep: true,
    },
    newUser: {
      handler(val) {
        if (val.client_id !== this.clonedNewUser.client_id) {
          this.newUser.users_module_group_membership = [];
          this.clientUserModuleGroups = [];
          this.clonedNewUser = this.$cloneDeep(this.newUser);
          if (val.client_id) {
            this.getClientUserModuleGroups();
          }
        }
      },
      deep: true,
    },
    // Sync the checkbox selections with the user object
    selectedClientUserModuleGroupIds() {
      this.newUser.client_user_module_group_ids =
        this.selectedClientUserModuleGroupIds;
    },
    "newUser.email": function (newVal) {
      if (newVal) {
        this.newUser.email = newVal.toLowerCase();
      }
    },
    "newUser.is_public_site_user_profile": function (newVal) {
      this.creationFieldSchema["client_id"].disabled = !!newVal;
      this.newUser.users_module_group_membership = null;
    },
    "newUser.client_id": function (newVal) {
      if (newVal) {
        const isPublicSite = this.fieldSchema.client_id.selectItems.find(
          (item) => item.id === this.newUser.client_id,
        ).is_public_site;

        this.creationFieldSchema["is_public_site_user_profile"].disabled =
          !isPublicSite;
      }
    },
  },
};
</script>

<style scoped></style>
