<template>
  <v-container fluid :style="'height:' + (height - 50) + 'px; overflow: auto;'">
    <v-card flat>
      <v-card flat class="text-left pa-3">
        <v-card-title><h1 class="h1">Group Settings</h1></v-card-title>
        <v-card-actions class="pb-0">
          <v-divider></v-divider>
        </v-card-actions>
        <v-alert
          text="Group Admins can customise their Group within this module. Please note that any changes made will affect all users within this Group."
          type="warning"
          variant="tonal"
        ></v-alert>
      </v-card>
      <v-card v-if="loading" flat :style="'height:' + (height - 258) + 'px;'">
        <div class="progress">
          <v-progress-circular
            :size="190"
            :width="2"
            color="#51627C"
            indeterminate
          >
            Loading settings
          </v-progress-circular>
        </div>
      </v-card>
      <v-card v-else flat style="border: solid #dfdfdf 2px">
        <v-card
          class="text-left pa-4"
          flat
          style="width: 40%; min-width: 516px !important"
        >
          <!--   map  colour scheme   -->
          <div>
            <v-card-subtitle class="pl-0 pb-1">
              The colours currently used for map
              visualisations.</v-card-subtitle
            >
            <div>
              <div
                v-for="colour in allColourSchemes
                  .filter(
                    (colourScheme) =>
                      colourScheme.id === clientObject.map_colour_scheme_id,
                  )[0]
                  .scheme.split('-')"
                :key="colour.id"
                class="colour-block"
              >
                <div
                  style=""
                  class="inner-block"
                  :style="{ backgroundColor: '#' + colour }"
                ></div>
              </div>

              <v-tooltip text="Change the colours used for map visualisations">
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    aria-label="edit"
                    style="cursor: pointer"
                    @click="selectColourScheme(1)"
                    class="mb-5 ml-2"
                    >mdi-pencil
                  </v-icon>
                </template>
              </v-tooltip>
            </div>
          </div>
          <!--   dashboard colour scheme   -->
          <div>
            <v-card-subtitle class="pl-0 pb-1">
              The current dashboard colour scheme.</v-card-subtitle
            >
            <div
              v-for="colour in allColourSchemes
                .filter(
                  (colourScheme) =>
                    colourScheme.id === clientObject.dashboard_colour_scheme_id,
                )[0]
                .scheme.split('-')"
              style=""
              :key="colour.id"
              class="colour-block"
            >
              <div
                style=""
                class="inner-block"
                :style="{ backgroundColor: '#' + colour }"
              ></div>
            </div>
            <v-tooltip text="Change the dashboard colour scheme">
              <template v-slot:activator="{ props }">
                <v-icon
                  v-bind="props"
                  aria-label="edit"
                  style="cursor: pointer"
                  @click="selectColourScheme(2)"
                  class="mb-5 ml-2"
                  >mdi-pencil
                </v-icon>
              </template>
            </v-tooltip>
          </div>
          <v-card-text class="pl-0 mb-6">
            <v-card-subtitle class="pl-0 pb-1">
              Set your optional map settings, such as zoom, center, and boundary
            </v-card-subtitle>
            <DefaultZoomLevel></DefaultZoomLevel>
          </v-card-text>
        </v-card>
        <v-card style="border-top: solid #dfdfdf 2px">
          <v-card-actions>
            <v-btn
              color="success"
              tile
              title="Save changes"
              @click="saveSettings"
              variant="elevated"
              >save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-card>

    <v-dialog v-model="colorSchemeDialog" width="400" persistent>
      <v-card>
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6 text-center"
        >
          <v-spacer>Color scheme for {{ selectedColourScheme.key }}</v-spacer>
        </v-card-title>
        <v-card-text
          class="selectColorScheme"
          style="height: 52vh; overflow: auto"
        >
          <v-radio-group v-model="selectedColourScheme.id" class="mb-4">
            <div
              v-for="colourScheme in allColourSchemes"
              :key="colourScheme.id"
            >
              <v-radio
                :value="colourScheme.id"
                :key="colourScheme.id"
                style="display: flex; justify-content: center"
              >
                <template v-slot:label>
                  <v-card-actions class="pb-0">
                    <div
                      v-for="(colour, index) in colourScheme.scheme.split('-')"
                      :key="index"
                      class="colour-block"
                    >
                      <div
                        class="inner-block"
                        :style="{
                          backgroundColor: '#' + colour,
                        }"
                      ></div>
                    </div>
                  </v-card-actions>
                </template>
              </v-radio>
            </div>
          </v-radio-group>
        </v-card-text>
        <v-card>
          <v-card-actions>
            <v-btn
              variant="elevated"
              color="error"
              tile
              @click="selectColourScheme(null)"
              aria-label="cancel"
            >
              cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              variant="elevated"
              color="success"
              tile
              @click="selectColourScheme(3)"
              aria-label="confirm"
            >
              confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { useDisplay } from "vuetify";
import DefaultZoomLevel from "@/components/DefaultZoomLevel.vue";

export default {
  name: "GroupSettings",
  data: () => ({
    height: useDisplay().height,
    clientObject: null,
    allColourSchemes: [],
    colorSchemeDialog: false,
    selectedColourScheme: {
      id: null,
      key: null,
    },
    showAreaSelector: false,
    defaultMapZoomDialog: false,
  }),
  mounted() {
    this.getSettings();
    this.getColourSchemes();
  },
  computed: {
    loading() {
      return !this.clientObject || this.allColourSchemes.length === 0;
    },
  },
  components: {
    DefaultZoomLevel,
  },
  methods: {
    getSettings() {
      this.$axios
        .get("/client-settings")
        .then(
          function (response) {
            // handle success
            this.clientObject = response.data;
            this.loading = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              title: "Error! Failed to get client settings",
              message: error.response.data.message,
              timeout: 0,
              colour: "error",
            });
          }.bind(this),
        );
    },
    getColourSchemes() {
      this.$axios
        .get("/all-colour-schemes")
        .then(
          function (response) {
            // handle success
            this.allColourSchemes = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get colour schemes",
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
    saveSettings() {
      this.emit.emit("systemBusy", true);
      this.$axios
        .post("/save-settings", this.clientObject)
        .then(
          function (response) {
            // handle success
            this.clientObject = response.data;
            this.emit.emit("systemMessage", {
              title: "Settings saved successfully!",
              message: "",
              timeout: 3000,
              colour: "success",
            });
            this.emit.emit("systemBusy", false);
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemBusy", false);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to save the settings",
              timeout: 0,
              colour: "error",
            });
          }.bind(this),
        );
    },
    selectColourScheme(item) {
      // grab all colour schemes if we haven't already
      if (!this.allColourSchemes.length) this.getColourSchemes();

      switch (item) {
        case 1:
          // map colour scheme
          this.selectedColourScheme = {
            key: "Map",
            id: this.clientObject.map_colour_scheme_id,
          };
          this.colorSchemeDialog = true;
          break;
        case 2:
          // dashboard colour scheme
          this.selectedColourScheme = {
            key: "Dashboard",
            id: this.clientObject.dashboard_colour_scheme_id,
          };
          this.colorSchemeDialog = true;
          break;
        case 3:
          // confirm colour scheme
          if (this.selectedColourScheme.key === "Map") {
            this.clientObject.map_colour_scheme_id =
              this.selectedColourScheme.id;
          } else {
            this.clientObject.dashboard_colour_scheme_id =
              this.selectedColourScheme.id;
          }
          this.saveSettings();
          this.colorSchemeDialog = false;
          break;
        default:
          // cancel colour scheme
          this.colorSchemeDialog = false;
      }
    },
  },
  watch: {},
};
</script>
<style scoped>
.colour-block {
  display: inline-block;
  margin-top: 5px;
}

.inner-block {
  width: 50px;
  height: 25px;
}
</style>
<style>
.selectColorScheme .v-label {
  opacity: 1 !important;
}
</style>
