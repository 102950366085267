<template>
  <v-dialog
    v-model="computedDrawCustomAreaDialog"
    scrollable
    persistent
    hide-overlay
    fullscreen
  >
    <template v-slot:activator="{ props }">
      <v-btn
        tile
        class="mx-4"
        color="primary"
        variant="elevated"
        id="drawOnMapButton"
        v-bind="props"
        @click="buildCustomArea()"
        aria-label="Draw on Map"
      >
        Draw on Map</v-btn
      >
    </template>
    <v-card>
      <v-dialog v-model="unsavedDialog" width="550" class="save-dialog">
        <v-card>
          <v-card-title
            :style="
              'background-color: ' +
              this.$store.state.config.siteConfig.toolbar_colour
            "
            class="text-h6 text-white"
          >
            Custom Area Not saved!
          </v-card-title>
          <v-card-actions>
            <v-card-text style="text-align: center">
              Your Custom Area is not saved, exiting now will lose your Custom
              Area definition!
            </v-card-text>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              tile
              color="success"
              variant="elevated"
              @click="unsavedDialog = false"
              aria-label="Return to Custom Area"
            >
              Return to Custom Area
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              tile
              color="error"
              variant="elevated"
              @click="(unsavedDialog = false), closeDialog()"
              aria-label="Do Not Save Custom Area"
            >
              Do Not Save Custom Area
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h5"
        max-height="64px"
      >
        <v-btn
          tile
          id="closeDrawOnMapDialogButton"
          icon
          dark
          @click="closeConfirmation"
          aria-label="Close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="customAreasIndex != null"
          >Edit Custom Area</v-toolbar-title
        >
        <v-toolbar-title v-else>Create Custom Area</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-0 no-scroll">
        <v-row style="height: 94vh">
          <v-col cols="9" class="pa-0">
            <!-- the map container -->
            <div
              ref="mapCanvas"
              id="mapCanvas"
              class="map-canvas"
              style="width: 100%; height: 100%"
            ></div>
          </v-col>
          <v-col cols="3">
            <v-row>
              <v-col class="pb-0 ma-3">
                <v-form
                  ref="form"
                  v-model="validation"
                  lazy-validation
                  v-if="computedDrawCustomAreaDialog"
                >
                  <Field
                    :schema="fieldSchema.name"
                    v-model:value="customArea.name"
                    id="customAreaNameField"
                    :name="customArea.name"
                    class="mb-1"
                  />
                  <div
                    v-if="customAreaNameLength >= 80"
                    :class="customAreaNameLength > 100 ? 'text-red' : ''"
                    class="customAreaNameLength"
                    style="
                      position: relative;
                      max-width: 10vh;
                      right: -84%;
                      bottom: 20px;
                    "
                  >
                    {{ customAreaNameLength }}/100
                  </div>
                  <Field
                    :schema="fieldSchema.custom_area_category_id"
                    v-model:value="customArea.custom_area_category_id"
                  />
                  <Field
                    :schema="fieldSchema.description"
                    v-model:value="customArea.description"
                  />
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert class="mx-3 d-flex" :type="alertType" rounded="0">
                  {{ alertMessage }}
                </v-alert></v-col
              >
            </v-row>
            <v-row>
              <v-col>
                <v-row cols="12" class="mx-1">
                  <v-col
                    cols="6"
                    class="d-flex flex-column justify-center align-center pr-1"
                  >
                    <v-btn
                      width="190"
                      :disabled="drawingMode"
                      color="success"
                      tile
                      @click="startDrawing(null)"
                      aria-label="Start"
                      v-tooltip.bottom="'Start Drawing'"
                    >
                      Start
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex flex-column justify-center align-center pl-1"
                  >
                    <v-btn
                      tile
                      width="190"
                      :disabled="!drawingMode || bestfitting"
                      color="warning"
                      @click="resetDrawing()"
                      aria-label="Redraw"
                      v-tooltip.bottom="'Redraw Polygon'"
                    >
                      Redraw
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex flex-column justify-center align-center pr-1"
                  >
                    <v-btn
                      tile
                      width="190"
                      :disabled="!drawingMode || bestfitting"
                      color="error"
                      @click="cancelDrawing()"
                      aria-label="Cancel"
                      v-tooltip.bottom="'Exit Drawing Mode'"
                    >
                      Cancel
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex flex-column justify-center align-center pl-1"
                  >
                    <v-btn
                      tile
                      width="190"
                      :disabled="!(drawingMode && completePoly)"
                      color="info"
                      @click="bestFitAreas()"
                      aria-label="bestfit polygon"
                      v-tooltip.bottom="'Bestfit Polygon'"
                    >
                      <template v-if="bestfitting || loadGeoms">
                        <v-progress-circular
                          indeterminate
                          size="24"
                        ></v-progress-circular>
                      </template>
                      <template v-else> Bestfit </template>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row class="ma-1">
              <v-col>
                <!-- NEW-->
                <v-expansion-panels :disabled="!bestfitComplete">
                  <v-expansion-panel rounded="0">
                    <v-expansion-panel-title
                      style="height: 35px"
                      class="dialog-title header-hover text-left"
                      disable-icon-rotate
                      @click="togglePanelCloseIcon()"
                    >
                      <div class="subheader" :style="{ color: headerColour }">
                        Constituent Areas
                      </div>
                      <template v-slot:actions>
                        <div v-if="!panelToggle">
                          <v-icon color="success" size="32"
                            >mdi-arrow-down-drop-circle-outline</v-icon
                          >
                        </div>
                        <div v-else>
                          <v-icon color="error" size="32"
                            >mdi-arrow-up-drop-circle-outline</v-icon
                          >
                        </div>
                      </template>
                    </v-expansion-panel-title>
                    <v-divider></v-divider>
                    <v-expansion-panel-text class="text-left px-0 mx-0">
                      <v-card-text style="height: 18vh; overflow-y: auto">
                        <v-list two-line>
                          <v-list-item
                            class="pa-0"
                            v-for="item in bestfitAreaList"
                            :key="item.id"
                          >
                            <v-list-item-title>{{
                              item.area_name
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              item.area_code
                            }}</v-list-item-subtitle>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
                <!-- NEW -->
              </v-col>
            </v-row>
            <v-row cols="12" class="ml-1 pt-0">
              <v-col>
                <template v-if="!this.saving">
                  <v-btn
                    tile
                    width="93"
                    :disabled="disabledSaveButton"
                    color="success"
                    @click="upsertCustomArea()"
                    aria-label="Save"
                  >
                    Save
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    tile
                    width="93"
                    color="success"
                    disabled
                    aria-label="save button disabled"
                  >
                    <v-progress-circular
                      indeterminate
                      color="white"
                      size="20"
                    ></v-progress-circular>
                  </v-btn>
                </template>
              </v-col>
              <v-card-subtitle
                v-if="this.$store.state.displayOnsSource"
                class="source"
              >
                <p>
                  Source: Office for National Statistics licensed under the Open
                  Government Licence v.3.0
                </p>
                <p>
                  Contains OS data © Crown copyright and database right
                  {{ year }}
                </p>
              </v-card-subtitle>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* global google */

import Field from "@/components/Fields.vue";
import CustomAreaSchema from "@/schemas/CustomAreaManager";
import { loadGoogleMaps } from "@/mixins/LoadGoogleMaps";
import { toRaw } from "vue";

let map = null;

export default {
  name: "DrawOnMap",
  data: () => ({
    fieldSchema: {},
    schema: CustomAreaSchema,
    alertType: "info",
    alertMessage: "Press 'Start' to begin drawing",
    customArea: {
      area_ids: [],
      areas: [],
      poly: [],
      type_id: 2,
    },
    customAreasClone: [],
    validation: false,
    zoom: 6,
    center: { lat: null, lng: null },
    drawingManager: null,
    path: null,
    drawnPoly: null,
    drawingMode: false,
    completePoly: false,
    bestfitting: false,
    bestfitComplete: false,
    loadGeoms: false,
    unsaved: false,
    saving: false,
    unsavedDialog: false,
    editModeCustomAreaid: null,
    existingPolyExample: null,
    mapOptions: {
      gestureHandling: "greedy",
      scaleControl: true,
    },
    bestfitPolyArray: [],
    bestfitAreaList: [],
    panelToggle: false,
  }),
  components: {
    Field,
  },

  computed: {
    computedDrawCustomAreaDialog: {
      get() {
        return this.drawCustomAreaDialog;
      },
      set(val) {
        this.$emit("update:drawCustomAreaDialog", val);
      },
    },
    customAreasIndex: {
      get() {
        return this.areaIndex;
      },
      set(val) {
        this.$emit("update:areaIndex", val);
      },
    },
    year() {
      return new Date().getFullYear();
    },
    headerColour() {
      return this.$store.state.config.siteConfig.toolbar_colour;
    },
    customAreas: {
      get() {
        return this.$store.state.customAreas;
      },
      set(value) {
        this.$store.commit("setCustomAreas", value);
      },
    },
    disabledSaveButton: {
      get() {
        return (
          !this.bestfitAreaList.length ||
          (this.customArea.description
            ? this.customArea.description.length > 250
            : false)
        );
      },
    },
    editMode() {
      return this.customAreasIndex !== null ? true : false;
    },
    customAreaNameLength() {
      return this.customArea.name ? this.customArea.name.length : 0;
    },
    height() {
      let header = document.querySelector("#appBar");

      if (header) {
        return window.innerHeight - header.getBoundingClientRect().bottom - 260;
      }
      return 1000;
    },
  },
  props: {
    drawCustomAreaDialog: {
      type: Boolean,
      default: false,
      required: true,
    },
    categories: Array,
    customAreaToEdit: Object,
    areaIndex: {},
  },
  mounted() {
    this.zoom = this.$store.state.config.siteConfig.initialMapZoom.zoom;
    this.center.lat = this.$store.state.config.siteConfig.initialMapZoom.lat;
    this.center.lng = this.$store.state.config.siteConfig.initialMapZoom.lng;
  },
  methods: {
    togglePanelCloseIcon() {
      this.panelToggle = !this.panelToggle;
    },
    closeConfirmation() {
      if (this.unsaved) {
        this.unsavedDialog = true;
      } else {
        this.closeDialog();
      }
    },
    // Clear dialog and close it
    closeDialog() {
      if (this.unsaved) {
        this.unsavedDialog = true;
      }
      // Reset page to initial state
      this.computedDrawCustomAreaDialog = false;
      this.bestfitAreaList = [];
      this.customAreasIndex = null;
      this.loadGeoms = false;
      this.unsaved = false;

      // Restore originals
      this.customAreas = this.customAreasClone;
      this.customArea.area_ids = [];
      this.customArea.areas = [];
      this.customArea.poly = [];

      // reset the map to the original center & zoom
      map.setZoom(this.zoom);
      map.setCenter(this.center);

      // reset the drawing manager
      this.cancelDrawing();

      // tell it to clear the edit area
      this.$emit("clearEditArea");
    },
    // If there is a selected custom area, call the api for updating, else call the api for creating
    upsertCustomArea() {
      // make sure the area_ids are unique
      this.customArea.area_ids = [...new Set(this.customArea.area_ids)];
      this.resetApiValidationErrors();
      // if this passes validation then call the api
      if (this.$refs.form.validate()) {
        this.saving = true;
        if (this.customAreasIndex != null) {
          // Custom area update
          this.emit.emit("systemMessage", {
            title: "Updating",
            message: "Updating Custom Area",
            timeout: 4000,
            colour: "warning",
          });

          // make the call!
          this.$axios
            .put(
              "/custom-areas/" + this.customAreas[this.customAreasIndex].id,
              this.customArea,
            )
            .then(() => {
              this.emit.emit("systemMessage", {
                title: "Success!",
                message: "Custom Area Update Complete",
                timeout: 4000,
                colour: "green",
              });
              // Update with new data
              this.customAreasClone = this.$cloneDeep(this.customAreas);
              this.saving = false;
              this.unsaved = false;
              this.closeDialog();
            })
            .catch((error) => {
              this.emit.emit("systemMessage", {
                title: "Error! Custom Area Update Failed",
                message: error.response.data.message,
                timeout: 4000,
                colour: "red",
              });
              this.saving = false;
              this.apiValidationErrors(error.response.data.errors);
            });
        } else {
          // Custom area create
          this.emit.emit("systemMessage", {
            title: "Creating",
            message: "Creating Custom Area",
            timeout: 4000,
            colour: "warning",
          });

          // make the call!
          this.$axios
            .post("/custom-areas", this.customArea)
            .then(() => {
              this.emit.emit("systemMessage", {
                title: "Success!",
                message: "Custom Area Created",
                timeout: 4000,
                colour: "green",
              });

              this.customAreasClone = this.$cloneDeep(this.customAreas);
              // Refresh custom areas table
              this.$emit("getCustomAreasByCategory");
              this.saving = false;
              this.unsaved = false;
              this.closeDialog();
            })
            .catch((error) => {
              this.emit.emit("systemMessage", {
                title: "Error! Custom Area Creation Failed",
                message: error.response.data.message,
                timeout: 4000,
                colour: "red",
              });
              this.saving = false;
              this.apiValidationErrors(error.response.data.errors);
            });
        }
      }
    },
    // Build empty fields for creation
    buildCustomArea() {
      for (const key in this.fieldSchema) {
        if (this.fieldSchema[key].useInCreationMode) {
          this.customArea[key] = "";
        }
      }
      this.computedDrawCustomAreaDialog = true;
    },
    apiValidationErrors(errors) {
      for (let field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (let field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
    bestFitAreas() {
      this.bestfitting = true;

      this.alertMessage = "Bestfitting...";
      this.alertType = "info";

      this.$axios
        .post("/single-bestfit", [this.bestfitPolyArray])
        .then(
          function (response) {
            // handle success
            this.bestfitAreaList = response.data.areas;
            this.bestfitting = false;
            this.bestfitComplete = true;
            this.alertMessage = response.data.message;
            this.alertType = response.data.status;
            this.unsaved = true;
            // reset the custom area area_ids
            this.customArea.area_ids = [];
            this.customArea.area_ids = this.bestfitAreaList.map(
              (area) => area.id,
            );

            if (this.bestfitAreaList.length > 0) {
              this.getStandardAreas();
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.bestfitAreaList = [];
            this.bestfitting = false;
            this.bestfitComplete = true;
            //this.bestfitError = true;
            this.alertMessage = "Error while bestfitting your polygon";
            this.alertType = "error";
          }.bind(this),
        );
    },
    getStandardAreas() {
      this.loadGeoms = true;
      // save the original message to set it back to
      var oldMessage = this.alertMessage;
      this.alertMessage = "Loading Areas...";
      this.alertType = "info";

      this.$axios
        .post("/standard-geometries", this.bestfitAreaList)
        .then(
          function (response) {
            // handle success
            this.loadGeoms = false;

            // whack'em on the map
            // remove old polygons beforeadding the new
            let features = map.data;
            features.forEach(function (feature) {
              features.remove(feature);
            });

            map.data.addGeoJson(response.data);
            map.data.setStyle({ fillOpacity: 0, zIndex: 10 });

            console.log(map.data);
            this.zoomToAreas();
            // edit message
            if (this.editModeCustomAreaid) {
              this.alertMessage = "Edit your area";
              this.alertType = "info";
            } else {
              this.alertType = "success";
              this.alertMessage = oldMessage;
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
    cancelDrawing() {
      //clear everything out
      this.drawingMode = false;
      this.completePoly = false;
      this.bestfitComplete = false;
      this.loadGeoms = false;
      this.alertMessage = "Press 'Start' to begin drawing";
      this.alertType = "info";
      this.bestfitting = false;
      this.editModeCustomAreaid = null;
      this.unsaved = false;

      // Remove overlay from map
      if (this.drawnPoly) {
        toRaw(this.drawnPoly).overlay.setMap(null);
      }

      // Disable drawingManager
      if (this.drawingManager) {
        this.drawingManager.setDrawingMode(null);
      }

      // remove data
      let dataFeatures = map.data;
      if (dataFeatures) {
        dataFeatures.forEach(function (dataFeature) {
          dataFeatures.remove(dataFeature);
        });
      }

      this.bestfitPolyArray = [];
      this.bestfitAreaList = [];
    },
    resetDrawing() {
      this.cancelDrawing();
      this.startDrawing(null);
    },
    async startDrawing(existingPoly) {
      this.alertMessage = "Draw a complete polygon";
      this.drawingMode = true;

      const { DrawingManager } = await google.maps.importLibrary("drawing");

      this.drawingManager = new DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        polygonOptions: {
          fillColor: "#000000",
          fillOpacity: 0.25,
          strokeColor: "#000000",
          strokeWeight: 4,
          editable: true,
        },
      });
      this.drawingManager.setMap(map);

      google.maps.event.addListener(
        toRaw(this.drawingManager),
        "overlaycomplete",
        (event) => {
          // Changed to an arrow function
          this.completePoly = true;
          this.alertMessage =
            "'Bestfit' your polygon to find constituent areas";

          // Get overlay paths
          this.path = event.overlay.getPath();

          // store the polygon in case we want to cancel it
          this.drawnPoly = event;

          // update the poly array to kick things off
          const polygonChanged = () => {
            this.bestfitPolyArray = [];
            this.path.getArray().forEach((value) => {
              this.bestfitPolyArray.push(value);
            });
          };

          // set the polygon array
          polygonChanged();

          // add listeners for change
          google.maps.event.addListener(this.path, "insert_at", polygonChanged);
          google.maps.event.addListener(this.path, "set_at", polygonChanged);
          google.maps.event.addListener(this.path, "remove_at", polygonChanged);

          // Disable drawingManager
          this.drawingManager.setDrawingMode(null);
        },
      );

      // if we have a poly already..
      if (existingPoly) {
        // Create it
        var polygon = new google.maps.Polygon({
          paths: existingPoly,
          fillColor: "#000000",
          fillOpacity: 0.25,
          strokeColor: "#000000",
          strokeWeight: 4,
          editable: true,
        });

        // Add the polygon to the map
        polygon.setMap(map);

        // Manually trigger the overlaycomplete event
        google.maps.event.trigger(this.drawingManager, "overlaycomplete", {
          type: "polygon",
          overlay: polygon,
        });
      }
    },
    loadEditableDrawArea() {
      this.alertMessage = "Loading Custom Area";
      this.alertType = "info";

      this.editModeCustomAreaid = this.customAreas[this.customAreasIndex].id;
      this.getConstituentAreas();
      this.loadPreviousPoly();
    },
    loadPreviousPoly() {
      this.$axios
        .get("/custom-area-latlng/" + this.editModeCustomAreaid)
        .then(
          function (response) {
            // handle success
            this.startDrawing(response.data);
            this.existingPolyExample = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
    getConstituentAreas() {
      this.$axios
        .get("/custom-areas-constituent-areas/" + this.editModeCustomAreaid)
        .then(
          function (response) {
            // handle success
            this.bestfitAreaList = response.data;
            this.customArea.area_ids = this.bestfitAreaList.map(
              (area) => area.id,
            );
            this.getStandardAreas();
          }.bind(this),
        )
        .catch(
          function (error) {
            // handle error
            console.error(error);
          }.bind(this),
        );
    },
    zoomToAreas() {
      var bounds = new google.maps.LatLngBounds();

      // Process each feature
      map.data.forEach(function (feature) {
        var geometry = feature.getGeometry();
        geometry.forEachLatLng(function (latlng) {
          bounds.extend(latlng);
        });
      });

      // Adjust map bounds
      map.fitBounds(bounds);
    },
  },
  watch: {
    customAreaToEdit: {
      handler() {
        if (this.customAreaToEdit.type_id === 2) {
          this.customArea = this.customAreaToEdit;
          this.computedDrawCustomAreaDialog = true;
        }
      },
      deep: true,
    },
    drawCustomAreaDialog(val) {
      if (val) {
        // Backup custom areas
        this.customAreasClone = this.$cloneDeep(this.customAreas);
        this.fieldSchema = this.$cloneDeep(this.schema);

        if (this.editMode) {
          this.loadEditableDrawArea();
          this.customArea = this.customAreas[this.customAreasIndex];
        }
        loadGoogleMaps(this.center, this.zoom).then((loadedMap) => {
          map = loadedMap;
        });
      }
    },
    categories: {
      handler(val) {
        if (val && Object.keys(this.fieldSchema).length > 0) {
          this.fieldSchema.custom_area_category_id.selectItems = val;
        }
      },
      deep: true,
    },
    bestfitPolyArray: function () {
      if (this.customArea) {
        this.customArea.poly = this.bestfitPolyArray;
      }
    },
    bestfitAreaList: function () {
      for (let i = 0; i < this.bestfitAreaList.length; i++) {
        this.customArea.area_ids.push(this.bestfitAreaList[i].id);
      }
    },
  },
};
</script>

<style scoped>
.fixed-height-alert {
  height: 60px;
}

.save-dialog {
  z-index: 9999;
}

.no-scroll {
  overflow: hidden;
}

.source {
  font-size: 10px;
  position: "absolute";
  bottom: "0";
}

.v-alert :deep(.v-alert__prepend) {
  align-self: center;
}
</style>
