<template>
  <v-dialog
    v-model="computedCategoryDialog"
    max-width="500px"
    @keyup.enter="upsertCategory()"
  >
    <v-card>
      <v-toolbar
        :color="this.$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h5 text-center"
        max-height="64px"
      >
        <v-spacer>
          <v-toolbar-title v-if="!createMode"
            >Edit Category Name</v-toolbar-title
          >
          <v-toolbar-title v-else>Create category</v-toolbar-title>
        </v-spacer>
      </v-toolbar>
      <v-form ref="form" v-model="validation" lazy-validation>
        <v-container>
          <v-row style="padding-top: 35px !important">
            <template v-if="!createMode">
              <v-col style="padding-top: 0px !important">
                <Field
                  id="editing-category-name-field"
                  :schema="fieldSchema.name"
                  v-model:value="selectedCategory.name"
                />
              </v-col>
            </template>
            <template v-else>
              <v-col style="padding-top: 0px !important">
                <Field
                  id="creation-category-name-field"
                  :schema="fieldSchema.name"
                  v-model:value="category.name"
                />
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="error"
          variant="elevated"
          tile
          @click="closeDialog()"
          aria-label="cancel"
        >
          cancel
        </v-btn>
        <v-spacer />
        <v-btn
          variant="elevated"
          color="success"
          tile
          id="saveCategory"
          @click="upsertCategory()"
          aria-label="Save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomAreaCategoriesSchema from "@/schemas/CustomAreaCategories";
import Field from "@/components/Fields.vue";
export default {
  name: "CustomAreaCategoriesUpsert",
  data: () => ({
    fieldSchema: CustomAreaCategoriesSchema,
    validation: false,
    category: {},
    computedFilterCategoriesClone: {},
  }),
  components: {
    Field,
  },
  computed: {
    computedCategoryIndex: {
      get() {
        return this.categoryIndex;
      },
      set(val) {
        this.$emit("update:categoryIndex", val);
      },
    },
    computedCategoryDialog: {
      get() {
        return this.categoryDialog;
      },
      set(val) {
        this.$emit("update:categoryDialog", val);
      },
    },
    computedFilterCategories: {
      get() {
        return this.filterCategories;
      },
      set(val) {
        this.$emit("update:filterCategories", val);
      },
    },
    // Helper for shortening code
    selectedCategory: {
      get() {
        return this.computedFilterCategories[this.computedCategoryIndex];
      },
      set(val) {
        this.computedFilterCategories[this.computedCategoryIndex] = val;
      },
    },
  },
  props: {
    createMode: {
      type: Boolean,
      required: true,
      default: false,
    },
    categoryIndex: {
      type: Number,
      required: false,
      default: null,
    },
    categoryDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    filterCategories: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
  },
  mounted() {
    //this.fieldSchema = this.$cloneDeep(this.schema);
  },
  methods: {
    apiValidationErrors(errors) {
      for (const field in errors) {
        this.fieldSchema[field].apiResponseError = errors[field];
      }
    },
    resetApiValidationErrors() {
      for (var field in this.fieldSchema) {
        this.fieldSchema[field].apiResponseError = null;
      }
    },
    closeDialog() {
      this.computedCategoryIndex = null;
      this.computedCategoryDialog = false;
      this.computedFilterCategories = this.$cloneDeep(
        this.computedFilterCategoriesClone,
      );
      this.category = {};
    },
    // If there is a selected category, call the api for updating, else call the api for creating
    upsertCategory() {
      this.resetApiValidationErrors();
      // if this passes validation then call the api
      this.$refs.form.validate().then(({ valid: isValid }) => {
        if (isValid) {
          if (!this.createMode) {
            this.emit.emit("systemMessage", {
              title: "Updating Category",
              message: "Updating",
              timeout: 4000,
              colour: "warning",
            });

            // make the call!
            this.$axios
              .put(
                "/custom-areas/category/" + this.selectedCategory.id,
                this.selectedCategory,
              )
              .then(() => {
                this.emit.emit("systemMessage", {
                  title: "Category Update Complete",
                  message: "Success!",
                  timeout: 4000,
                  colour: "green",
                });

                // Update the clone
                this.computedFilterCategoriesClone = this.$cloneDeep(
                  this.computedFilterCategories,
                );
                // close the dialogue
                this.closeDialog();
              })
              .catch((error) => {
                this.emit.emit("systemMessage", {
                  message: error.response.data.message,
                  title: "Error! Category Update Failed",
                  timeout: -1,
                  colour: "red",
                });
                this.apiValidationErrors(error.response.data.errors);
              });
          } else {
            this.emit.emit("systemMessage", {
              title: "Creating New Category",
              message: "Creating",
              timeout: 4000,
              colour: "warning",
            });
            // make the call!
            this.$axios
              .post("/custom-areas/category", this.category)
              .then((response) => {
                this.emit.emit("systemMessage", {
                  title: "Category Creation Complete",
                  message: "Success!",
                  timeout: 4000,
                  colour: "green",
                });
                this.$emit("getCategories");
                this.computedFilterCategories.push(response.data);
                this.computedFilterCategoriesClone = this.$cloneDeep(
                  this.computedFilterCategories,
                );
                this.closeDialog();
              })
              .catch((error) => {
                this.emit.emit("systemMessage", {
                  message: error.response.data.message,
                  title: "Error! Category Creation Failed",
                  timeout: -1,
                  colour: "red",
                });
                this.apiValidationErrors(error.response.data.errors);
              });
          }
        }
      });
    },
  },
  watch: {
    computedCategoryDialog: {
      handler() {
        if (this.computedCategoryDialog) {
          this.computedFilterCategoriesClone = this.$cloneDeep(
            this.computedFilterCategories,
          );
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
