<template>
  <v-container>
    <v-row>
      <v-col class="pt-8">
        <v-card class="mx-auto" width="40%" min-width="320" max-width="600">
          <v-card-text><h1 class="h1">User profile editor</h1></v-card-text>
          <v-form ref="form" class="pa-3" v-model="valid">
            <v-text-field
              density="compact"
              rounded="0"
              variant="outlined"
              class="fields"
              v-model="user_object.name"
              :rules="rules.name"
              :error-messages="nameIsSameError"
              label="Name*"
              required
            ></v-text-field>
          </v-form>
          <v-card class="pa-0 ma-0">
            <v-card-actions>
              <v-btn
                color="success"
                variant="elevated"
                @click="checkDetails()"
                aria-label="Update name"
                rounded="0"
              >
                Update name
              </v-btn>
              <v-spacer />
              <v-btn
                color="warning"
                variant="elevated"
                @click="
                  dialog = true;
                  action = 2;
                "
                aria-label="reset password"
                rounded="0"
              >
                reset password
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" scrollable persistent max-width="500px">
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h6 text-center"
          max-height="64px"
        >
          <v-spacer>
            <v-toolbar-title
              >{{ action === 1 ? "Confirm your password" : "Reset password" }}
            </v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row style="">
              <v-card-text v-if="action === 1" class="text-center mt-4 pb-1">
                <v-text-field
                  :type="showPW ? 'text' : 'password'"
                  v-model="user_object.password"
                  :rules="passwordRules"
                  :append-icon="showPW ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Password"
                  required
                  density="compact"
                  rounded="0"
                  variant="outlined"
                  class="fields"
                  @click:append="showPW = !showPW"
                  @keyup.enter="submit()"
                ></v-text-field>
              </v-card-text>
              <v-card-text
                v-else
                class="text-center mt-4 mb-4"
                style="font-size: 1.07em"
              >
                You will be sent an email with a link to reset your password.
                Are you sure you want to continue?
              </v-card-text>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions v-if="action === 1">
          <v-btn
            color="success"
            variant="elevated"
            tile
            @click="
              dialog = false;
              user_object.password = '';
            "
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="warning"
            variant="elevated"
            tile
            @click="submit()"
            aria-label="confirm"
          >
            confirm
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn
            color="success"
            variant="elevated"
            rounded="0"
            @click="dialog = false"
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="info"
            variant="elevated"
            rounded="0"
            @click="resetPassword()"
            aria-label="reset"
            :disabled="requestNewPWSpinner"
            :loading="requestNewPWSpinner"
          >
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
            Send Link
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "UserEditor",
  data: () => ({
    requestNewPWSpinner: false,
    user_object: {},
    valid: false,
    dialog: false,
    cloneName: "",
    action: 0,
    nameIsSameError: "",
    showPW: false,
    passwordRules: [(v) => !!v || "password required"],
  }),
  components: {},
  computed: {
    userProfile: {
      get() {
        return this.$store.getters.userProfile;
      },
      set(value) {
        this.$store.commit("setUserProfile", value);
      },
    },
    rules() {
      return {
        name: [
          (value) => !!value || "Required.",
          (value) =>
            (value && value.length <= 255) || "Maximum of 255 characters",
          (value) => (value && value.length >= 3) || "Minimum of 2 characters",
        ],
      };
    },
  },
  props: {
    model: null,
    item: {},
    disable: {
      type: Boolean,
      required: false,
      default: false,
    },
    relatedModelResults: {},
  },
  mounted() {
    this.updateForm();

    // create a clone of the name to check it against later
    this.cloneName = this.user_object.name;
  },
  methods: {
    checkDetails() {
      // check if the name has changed
      if (this.cloneName === this.user_object.name) {
        this.nameIsSameError = "Name has not changed";
      } else {
        this.action = 1;
        this.dialog = true;
        this.nameIsSameError = "";
      }
    },
    submit() {
      // snackbar
      this.emit.emit("systemMessage", {
        title: "Updating your name",
        message: "Updating",
        timeout: 4000,
        colour: "warning",
      });

      // send the data to the API
      this.$axios
        .put("/update-user-details", this.user_object)
        .then((response) => {
          this.dialog = false;
          this.emit.emit("systemMessage", {
            title: "User profile updated",
            message: "Success!",
            timeout: 4000,
            colour: "success",
          });

          // update the data
          this.userProfile = response.data;

          // and update the form in the template
          this.updateForm();

          // create a clone
          this.cloneName = this.user_object.name;
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error updating user profile",
            timeout: -1,
            colour: "error",
          });
        })
        .finally(() => {
          // reset the dialog
          this.user_object.password = "";
        });
    },
    updateForm() {
      this.user_object = Object.assign({}, this.userProfile);
    },
    resetPassword() {
      this.requestNewPWSpinner = true;
      let resetPasswordEmail = {
        email: this.$store.state.config.userProfile.email,
      };
      // make the call!
      this.$axios
        .post("/user-reset-password", resetPasswordEmail)
        .then(() => {
          this.emit.emit("systemMessage", {
            message:
              "Check email: " +
              resetPasswordEmail.email +
              " for password reset link",
            title: "Check email",
            timeout: -1,
            colour: "info",
          });
          this.requestNewPWSpinner = false;
        })
        .catch((error) => {
          this.emit.emit("systemMessage", {
            message: error.response.data.message,
            title: "Error! Password reset failed",
            timeout: -1,
            colour: "error",
          });
          this.requestNewPWSpinner = false;
        })
        .finally(() => {
          this.dialog = false;
        });
    },
  },
};
</script>

<style scoped></style>
