<template>
  <v-dialog
    v-model="computedCreateOrEditPageDialog"
    scrollable
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        v-if="selectedClientId"
        color="primary"
        variant="elevated"
        tile
        @click="computedCreateOrEditPageDialog = true"
        aria-label="Create New Page"
        class="mt-3"
      >
        Create New Page
        <v-icon class="ml-0" right>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        :color="$store.state.config.siteConfig.toolbar_colour"
        dark
        class="text-h6 text-center"
        max-height="70px"
      >
        <v-spacer>
          <div v-if="pageToEdit.id">Edit page</div>
          <div v-else>Create New Page for {{ siteName }}</div>
        </v-spacer>
      </v-toolbar>
      <v-card-text v-if="pageToEdit.id" class="pb-0">
        <v-text-field
          v-model="pageToEdit.name"
          label="Page Name"
          maxLength="45"
          :error-messages="errorMessages.name"
          clearable
          density="compact"
          rounded="0"
          variant="outlined"
          class="mt-5"
        ></v-text-field>
        <v-select
          v-model="pageToEdit.icon"
          label="Icon"
          variant="outlined"
          density="compact"
          rounded="0"
          :items="icons"
          item-value="icon"
          item-title="title"
          :error-messages="errorMessages.icon"
          clearable
          class="mt-3"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true,
          }"
        >
          <template v-slot:item="{ item }">
            <v-list-item
              :title="item.title"
              :prepend-icon="'mdi-' + item.value"
              @click="pageToEdit.icon = item.value"
            >
            </v-list-item>
          </template>

          <template v-slot:selection="{ item }">
            <v-row>
              <v-icon>{{ "mdi-" + item.value }}</v-icon>
              <div>{{ item.title }}</div>
            </v-row>
          </template>
        </v-select>
        <v-checkbox
          v-model="pageToEdit.show_at_header"
          label="Show the button at the header"
          title="This will show the button at the header of the page instead of the left side menu"
          class="mt-3"
        ></v-checkbox>
      </v-card-text>
      <v-card-text v-else class="pb-0">
        <v-text-field
          v-model="newPageData.name"
          label="Page Name"
          maxLength="45"
          variant="outlined"
          clearable
          class="mt-5"
          density="compact"
          rounded="0"
        ></v-text-field>
        <v-select
          v-model="newPageData.icon"
          :items="icons"
          label="Icon"
          variant="outlined"
          item-title="title"
          item-value="icon"
          clearable
          density="compact"
          rounded="0"
          class="mt-3"
        >
          <template v-slot:item="{ props }">
            <v-list-item v-bind="props" :prepend-icon="'mdi-' + props.value" />
          </template>
          <template v-slot:selection="{ item }">
            <div style="display: flex">
              <v-icon class="mr-2">{{ "mdi-" + item.value }}</v-icon>
              {{ item.title }}
            </div>
          </template>
        </v-select>
        <v-checkbox
          v-model="newPageData.show_at_header"
          label="Show the button at the header"
          title="This will show the button at the header of the page instead of the left side menu"
          class="mt-3"
        ></v-checkbox>
      </v-card-text>
      <v-card v-if="pageToEdit.id">
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="error"
            tile
            @click="computedCreateOrEditPageDialog = false"
            aria-label="Close"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            color="success"
            tile
            @click="updateBasic()"
            aria-label="edit"
          >
            edit
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-actions>
          <v-btn
            variant="elevated"
            color="error"
            tile
            @click="
              computedCreateOrEditPageDialog = false;
              newPageData = {};
            "
            aria-label="Close"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            variant="elevated"
            :disabled="!newPageData.name || !newPageData.icon"
            color="success"
            tile
            @click="createNewPage()"
            aria-label="Create"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CreateOrEditExtraPage",
  data: () => ({
    icons: [
      { title: "Spin", icon: "lifebuoy" },
      { title: "Face agent", icon: "face-agent" },
      { title: "Information", icon: "information" },
      { title: "Post", icon: "post" },
      { title: "Web", icon: "web" },
      { title: "Application", icon: "application-outline" },
      { title: "Settings", icon: "cogs" },
    ],
    errorMessages: {
      name: "",
      icon: "",
    },
    newPageData: {},
  }),
  props: {
    siteName: String,
    selectedClientId: Number,
    createOrEditPageDialog: {
      type: Boolean,
      default: false,
    },
    dataToEdit: {
      type: Object,
      required: false,
    },
  },
  computed: {
    computedCreateOrEditPageDialog: {
      get() {
        return this.createOrEditPageDialog;
      },
      set(val) {
        this.$emit("update:createOrEditPageDialog", val);
      },
    },
    // edit pageToEdit
    pageToEdit: {
      get() {
        return this.dataToEdit;
      },
      set(val) {
        this.pageToEdit = val;
      },
    },
  },
  methods: {
    createNewPage() {
      this.computedCreateOrEditPageDialog = false;
      this.emit.emit("systemMessage", {
        title: "Creating New Page",
        message: "Creating",
        timeout: 4000,
        colour: "warning",
      });

      // add the client id
      this.newPageData.client_id = this.selectedClientId;
      this.$axios
        .post("/page", this.newPageData)
        .then(
          function () {
            // clear the form
            this.computedCreateOrEditPageDialog = false;
            this.newPageData = {};

            // refresh the page list
            this.$emit("refreshExtraPages");

            this.emit.emit("systemMessage", {
              title: "Report Theme Creation Complete",
              message: "Success!",
              timeout: 4000,
              colour: "green",
            });
          }.bind(this),
        )
        .catch(
          function (error) {
            if (error.response.status === 400) {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Failed",
                timeout: -1,
                colour: "blue",
              });
            } else {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Report Theme Creation Failed",
                timeout: -1,
                colour: "red",
              });
            }
          }.bind(this),
        );
    },
    updateBasic() {
      // validation
      for (const field of ["name", "icon"]) {
        if (!this.pageToEdit[field]) {
          this.errorMessages[field] = "Please fill out all required fields";
          return;
        } else {
          this.errorMessages[field] = "";
        }
      }

      this.computedCreateOrEditPageDialog = false;

      this.emit.emit("systemMessage", {
        title: "Updating Page Data",
        message: "Updating",
        timeout: 4000,
        colour: "warning",
      });
      this.$axios
        .put("/page/" + this.pageToEdit.id, this.pageToEdit)
        .then(
          function () {
            this.emit.emit("systemMessage", {
              title: "Page Data Updated",
              message: "Success!",
              timeout: 4000,
              colour: "green",
            });
            // refresh the page list
            this.$emit("refreshExtraPages");
          }.bind(this),
        )
        .catch(
          function (error) {
            if (error.response.status === 400) {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Failed",
                timeout: -1,
                colour: "blue",
              });
            } else {
              this.emit.emit("systemMessage", {
                message: error.response.data.message,
                title: "Error! Page data save failed",
                timeout: -1,
                colour: "red",
              });
            }
          }.bind(this),
        );
    },
  },
  watch: {},
};
</script>

<style scoped></style>
