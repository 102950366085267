<template>
  <v-card tile class="ma-4">
    <v-card-title class="mb-3 text-left"
      ><h1 class="h1">Extra pages</h1></v-card-title
    >
    <v-card-subtitle class="text-left mt-4 mb-4"
      >Select a public site
    </v-card-subtitle>
    <v-card-actions>
      <v-autocomplete
        style="width: 100%; max-width: 600px !important"
        v-model="selectedClientId"
        :items="publicSites"
        label="Select a site"
        :item-color="'#1975d0'"
        density="compact"
        rounded="0"
        variant="outlined"
        item-title="client_name"
        item-value="id"
        align-center
        clearable
      >
      </v-autocomplete>
    </v-card-actions>
    <v-divider></v-divider>
    <h3
      v-if="!pages.length && selectedClientId && !isLoadingPages"
      class="text-center noPages"
    >
      You have no extra pages on {{ siteName }}
    </h3>
    <v-card-text
      v-if="selectedClientId"
      class="text-left pa-0"
      :style="'height:' + (height - 300) + 'px; overflow: overlay;'"
    >
      <v-card elevation="0">
        <v-row class="pl-3" style="width: 98vw">
          <v-col cols="3">
            <!--   Create or Edit   -->
            <CreateOrEditExtraPage
              v-model:createOrEditPageDialog="createOrEditPageDialog"
              @refreshExtraPages="getSitesPages"
              :dataToEdit="pageToEdit"
              :siteName="siteName"
              :selectedClientId="selectedClientId"
              :pages="pages"
            />
            <!--   List of pages   -->
            <div
              v-if="
                (isLoadingPages && !pages.length) ||
                (pages.length && !isLoadingPages)
              "
              class="column-with-border mt-5"
            >
              <v-list v-if="pages.length" class="py-0">
                <Draggable v-model="pages" item-key="id" @change="updateOrder">
                  <template v-slot:item="{ element }">
                    <v-list-item
                      style="border: 1px solid #e6e6e7"
                      class="v-list-item-hover"
                      :class="{
                        selectedItem: selectedPage === element,
                      }"
                      @click="
                        selectedPage = element;
                        getPageData(element.id);
                      "
                    >
                      <v-list-item-action>
                        <v-icon class="mr-3"
                          >{{ "mdi-" + element.icon }}
                        </v-icon>
                        <v-list-item-title class="mt-0">
                          {{ element.name }}
                        </v-list-item-title>
                        <v-spacer />
                        <v-icon
                          aria-label="edit"
                          class="edit-icon mr-1"
                          @click.stop="editMode(element)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          class="delete-icon"
                          aria-label="delete"
                          @click.stop="
                            deletePageDialog = true;
                            pageToDelete = element;
                          "
                          >mdi-delete
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </Draggable>
              </v-list>
              <div v-else>
                <v-card-actions tile elevation="0" class="mt-5">
                  <div class="progressText">
                    <v-progress-circular
                      :size="130"
                      :width="2"
                      color="primary"
                      indeterminate
                      >Loading pages
                    </v-progress-circular>
                  </div>
                </v-card-actions>
              </div>
            </div>
          </v-col>

          <v-col cols="9">
            <!--   Tabs   -->
            <v-card-actions v-if="pageData.id">
              <div
                v-for="tab in tabs"
                :key="tab.id"
                @click="pageData.type_id = tab.id"
                class="tab-item"
                :style="{
                  color: pageData.type_id === tab.id ? '#1866be' : '',
                }"
              >
                <v-icon v-if="clonePageData.type_id === tab.id" class="mr-2"
                  >mdi-circle
                </v-icon>
                {{ tab.text }}
              </div>
            </v-card-actions>
            <v-window v-model="pageData.type_id" v-if="pageData.id">
              <!--   Code   -->
              <v-window-item :value="0">
                <v-card
                  v-if="pageData.id"
                  min-height="60vh"
                  id="tipTapSpace"
                  class="mt-4"
                  tile
                  style="border: solid #dededf 1px"
                >
                  <VuetifyTiptap
                    v-model="content"
                    rounded
                    :min-height="500"
                    :min-width="500"
                    :extensions="extensions"
                  />
                  <!--   Preview     -->
                  <!--                    <VuetifyViewer :value="content" class="mt-5" />-->
                </v-card>
              </v-window-item>
              <!--   Link mode   -->
              <v-window-item :value="1">
                <v-card
                  class="mt-4"
                  tile
                  style="height: 40vh; border: solid #dededf 1px"
                >
                  <v-card-text style="font-size: 15px">
                    <b> Paste your link: </b>
                  </v-card-text>
                  <v-card-actions class="pt-0" style="width: 60%">
                    <v-card-actions style="width: 80%">
                      <v-text-field
                        v-model="pageData.link"
                        label="Paste a link"
                        variant="outlined"
                        density="compact"
                        rounded="0"
                        class="mt-4 ml-3"
                        align-center
                      ></v-text-field>
                      <v-btn
                        @click="savePageData()"
                        :disabled="setLinkDisabled"
                        class="mt-4 ml-4 mb-6"
                        color="primary"
                        variant="elevated"
                        tile
                        aria-label="set"
                      >
                        set
                      </v-btn>
                    </v-card-actions>
                    <v-card-actions style="width: 20%">
                      <v-progress-circular
                        v-if="isSavingDataProcess"
                        class="ml-2 mb-2"
                        indeterminate
                        :size="25"
                        width="3"
                        color="primary"
                      ></v-progress-circular>
                      <v-icon
                        v-if="isLinkSet"
                        class="ml-1 mb-3"
                        color="green"
                        right
                        >mdi-check
                      </v-icon>
                    </v-card-actions>
                  </v-card-actions>
                </v-card>
              </v-window-item>
            </v-window>

            <!--  Spinner   -->
            <v-col v-else-if="isLoadingCode" cols="9">
              <v-card-actions tile elevation="0" class="progress">
                <div class="progressText">
                  <v-progress-circular
                    :size="200"
                    :width="3"
                    color="primary"
                    indeterminate
                    >Loading code
                  </v-progress-circular>
                </div>
              </v-card-actions>
            </v-col>
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>
    <v-card v-if="pageData.type_id === 0" elevation="2">
      <v-card-actions>
        <v-btn
          :disabled="!pageData.id || isSavingDataProcess"
          color="success"
          variant="elevated"
          tile
          @click="savePageData()"
          aria-label="save"
        >
          save
        </v-btn>
        <v-progress-circular
          v-if="isSavingDataProcess"
          class="ml-3"
          indeterminate
          :size="25"
          width="3"
          color="primary"
        ></v-progress-circular>
        <v-icon v-if="isGreenCheckmark" class="ml-3" color="green" right
          >mdi-check
        </v-icon>
      </v-card-actions>
    </v-card>
    <!--   Delete a page dialog     -->
    <v-dialog
      v-model="deletePageDialog"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card>
        <v-toolbar
          :color="this.$store.state.config.siteConfig.toolbar_colour"
          dark
          class="text-h6 text-center"
          max-height="64px"
        >
          <v-spacer>
            <v-toolbar-title>Confirm Delete page</v-toolbar-title>
          </v-spacer>
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row style="padding: 23px">
              <v-card-text class="text-center" style="font-size: 1.07em">
                Are you sure you want to delete the
                <b>{{ pageToDelete.name }}</b> page? There will be no way to
                recover it.
              </v-card-text>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="success"
            tile
            variant="elevated"
            @click="
              deletePageDialog = false;
              pageToDelete = {};
            "
            aria-label="cancel"
          >
            cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            tile
            variant="elevated"
            @click="deleteCustomPage()"
            aria-label="delete"
          >
            delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { ref } from "vue";
import "vuetify-pro-tiptap/style.css";
import {
  BaseKit,
  Bold,
  Italic,
  Underline,
  Strike,
  Color,
  Highlight,
  Heading,
  Link,
  Image,
  Video,
  Table,
  Fullscreen,
  History,
  BulletList,
  OrderedList,
  TaskList,
  TextAlign,
  FontSize,
  Clear,
} from "vuetify-pro-tiptap";
import CreateOrEditExtraPage from "@/components/CreateOrEditExtraPage.vue";
import Draggable from "vuedraggable";
import { useDisplay } from "vuetify";

export default {
  name: "ExtraPages",
  data: () => ({
    height: useDisplay().height,
    selectedClientId: null,
    publicSites: [],
    content: ref(""),
    extensions: [
      BaseKit.configure({
        placeholder: {
          placeholder: "Enter some text...",
        },
      }),
      Bold,
      Italic,
      Underline,
      Strike,
      Color,
      Highlight,
      Heading.configure({
        levels: [2, 3, 4, 5, 6],
      }),
      BulletList,
      OrderedList,
      TaskList,
      Link,
      Image,
      Video,
      Table,
      Fullscreen,
      History,
      TextAlign,
      FontSize,
      Clear,
    ],
    pages: [],
    deletePageDialog: false,
    pageData: {},
    clonePageData: {},
    siteName: "",
    pageToDelete: {},
    selectedPage: {},
    isLoadingCode: false,
    isLoadingPages: false,
    isSavingDataProcess: false,
    isGreenCheckmark: false,
    createOrEditPageDialog: false,
    tabs: [
      { id: 0, text: "CODE" },
      { id: 1, text: "LINK" },
    ],
    pageToEdit: { id: null, name: "", icon: "" },
  }),
  components: {
    CreateOrEditExtraPage,
    Draggable,
  },
  computed: {
    setLinkDisabled() {
      return (
        (this.clonePageData.type_id === 1 &&
          this.clonePageData.link === this.pageData.link) ||
        this.isSavingDataProcess
      );
    },
    isLinkSet() {
      return (
        this.clonePageData.type_id === 1 &&
        this.clonePageData.link === this.pageData.link
      );
    },
  },
  mounted() {
    this.getPublicSites();
  },
  methods: {
    updateOrder() {
      this.pages.forEach((page, index) => {
        page.order = index + 1;
      });

      // save the order
      this.$axios
        .put("/update-extra-pages-order", this.pages)
        .then(() => {
          this.emit.emit("systemMessage", {
            title: "Pages order successfully updated",
            message: "Done",
            timeout: 1000,
            colour: "green",
          });
        })
        .catch(
          function (error) {
            // handle error
            console.error(error);
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Failed to save order",
              timeout: -1,
              colour: "error",
            });
          }.bind(this),
        );
    },
    getPublicSites() {
      this.$axios
        .get("/public-clients")
        .then(
          function (response) {
            this.publicSites = response.data;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get public sites",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getSitesPages() {
      this.pages = [];

      this.isLoadingPages = true;
      this.$axios
        .get("/pages/" + this.selectedClientId)
        .then(
          function (response) {
            this.pages = response.data;
            this.isLoadingPages = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Failed to get pages",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    getPageData(id) {
      // if data is already being loaded, don't load it again
      if (this.pageData.id === id) return;

      this.pageData = {};
      this.isLoadingCode = true;
      this.$axios
        .get("/page/" + id)
        .then(
          function (response) {
            this.pageData = response.data;

            // create a clone of the page data
            this.clonePageData = JSON.parse(JSON.stringify(this.pageData));

            this.content = ref(this.pageData.page_html);

            this.isLoadingCode = false;
          }.bind(this),
        )
        .catch(
          function (error) {
            console.log(error);
            this.emit.emit("systemMessage", {
              message: "",
              title: "Error! Page data retrieval failed",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    savePageData() {
      this.isSavingDataProcess = true;
      this.isGreenCheckmark = false;

      // if it's the link mode then handle checking if the link is valid
      if (this.pageData.type_id === 1) {
        let url = this.pageData.link;

        if (!url) {
          url = "http://example.com";
        }

        if (!url.includes("http://") && !url.includes("https://")) {
          url = "http://" + url;
        }

        this.pageData.link = url;
      } else {
        this.pageData.page_html = this.content;
      }

      this.$axios
        .put("/page/" + this.selectedPage.id, this.pageData)
        .then(
          function () {
            // show the green checkmark for a few seconds
            this.isGreenCheckmark = true;
            // apply the css
            // applyCss(this.pageData.page_css, "htmlEditor", "editor");
            setTimeout(() => {
              this.isGreenCheckmark = false;
            }, 3000);

            // if user switched a mode then indicate it
            if (this.clonePageData.type_id !== this.pageData.type_id) {
              this.emit.emit("systemMessage", {
                message:
                  "Page successfully was switched to " +
                  this.tabs[this.pageData.type_id].text +
                  " mode",
                title: "Page mode was switched",
                timeout: 4000,
                colour: "green",
              });
            }

            // update the clone
            this.clonePageData = JSON.parse(JSON.stringify(this.pageData));
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Page data save failed",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        )
        .finally(() => {
          this.isSavingDataProcess = false;
        });
    },
    deleteCustomPage() {
      this.deletePageDialog = false;
      this.emit.emit("systemMessage", {
        message: "",
        title: "Deleting Custom Area",
        timeout: 4000,
        colour: "warning",
      });

      // make the call!
      this.$axios
        .delete("/page/" + this.pageToDelete.id)
        .then(
          function (response) {
            // now update frontend
            this.emit.emit("systemMessage", {
              title: "Page Deleted",
              message: "Success!",
              timeout: 4000,
              colour: "green",
            });

            this.pages = response.data;

            // if it was the last page, then show to user that three are no pages
            if (this.pages.length === 1) {
              this.pageData = {};
            }
          }.bind(this),
        )
        .catch(
          function (error) {
            this.emit.emit("systemMessage", {
              message: error.response.data.message,
              title: "Error! Page deletion failed",
              timeout: -1,
              colour: "red",
            });
          }.bind(this),
        );
    },
    editMode(page) {
      // add properties we need
      Object.assign(this.pageToEdit, {
        id: page.id,
        name: page.name,
        icon: page.icon,
        type_id: page.type_id,
        client_id: this.selectedClientId,
        show_at_header: page.show_at_header,
      });

      this.createOrEditPageDialog = true;
    },
  },
  watch: {
    selectedClientId: {
      handler() {
        if (this.selectedClientId) {
          this.pageData = {};
          // refresh the page list
          this.getSitesPages();

          // get the site name
          this.siteName = this.publicSites.find(
            (site) => site.id === this.selectedClientId,
          ).client_name;
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.column-with-border {
  border: 1px solid #dededf;
  min-height: 30vh;
  position: relative;
  right: 16px;
}

.v-list-item-hover:hover {
  background-color: #d5d5d5;
  transition: background-color 0.2s;
  cursor: grab;
}

.selectedItem {
  background-color: #d5d5d5;
  transition: background-color 0.3s;
}

.icon {
  font-size: 1.4rem;
  display: inline-block;
}

.delete-icon:hover {
  transform: scale(1.1);
  color: #e74c3c;
  transition:
    color 0.2s,
    transform 0.2s;
}

.edit-icon:hover {
  transform: scale(1.1);
  color: #257de1;
  transition:
    color 0.2s,
    transform 0.2s;
}

.noPages {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.tab-item:hover {
  background: #f3f3f3;
}

.tab-item {
  display: flex;
  cursor: pointer;
  width: 50%;
  padding-top: 7px;
  padding-bottom: 7px;
  justify-content: center;
}
</style>
<style>
/* styles to get the tip tap tables looking good */
#tipTapSpace table th,
#tipTapSpace table td {
  padding: 6px 13px;
  border: 1px solid #d0d7de;
}
.tiptap .table-wrapper {
  display: contents;
}
/* grayed rows of the table */
#tipTapSpace table tr:nth-child(even) {
  background-color: #f4f6f8;
}
#tipTapSpace ul,
#tipTapSpace ol {
  margin-left: 30px !important;
}
</style>
