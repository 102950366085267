<template>
  <div>
    <v-dialog
      v-if="editService"
      v-model="editDialog"
      width="500"
      persistent
      id="editServiceTypeDialog"
    >
      <v-card>
        <v-card-title
          :style="
            'background-color: ' +
            this.$store.state.config.siteConfig.toolbar_colour
          "
          class="text-h6 text-white"
        >
          {{ confirmDeletion ? "Confirm Deletion?" : "Edit Service Type" }}
        </v-card-title>
        <v-card-text v-if="confirmDeletion" class="text-center">
          <v-row class="ma-0 mt-10 mb-10 text-h6">
            <div style="width: 100%">Delete {{ editService.name }}?</div>
          </v-row>
          <v-row>
            <v-btn
              tile
              variant="elevated"
              color="warning"
              @click="confirmDeletion = false"
              aria-label="cancel"
              >cancel</v-btn
            ><v-spacer />
            <v-btn
              tile
              variant="elevated"
              color="error"
              @click="deleteServiceType"
              :loading="saving"
              aria-label="delete"
            >
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>mdi-cached</v-icon>
                </span> </template
              >delete</v-btn
            >
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-form v-model="isFormValid">
            <v-row no-gutters class="pt-5">
              <v-text-field
                v-model="editService.name"
                label="Service Type Name"
                variant="outlined"
                density="compact"
                rounded="0"
                class=""
                :readonly="editService.name === 'Unclassified' ? true : false"
                :rules="[maxchars, minchars]"
                :error-messages="nameError"
                single-line
                counter
                @focus="nameError = null"
                id="editTypeName"
              ></v-text-field>
              <v-btn
                tile
                color="error"
                class="ml-1"
                style="height: 40px"
                title="delete services type"
                @click="confirmDeletion = true"
                :disabled="editService.name === 'Unclassified' ? true : false"
                aria-label="delete services type"
                id="deleteServiceType"
              >
                <v-icon center> mdi-trash-can-outline </v-icon>
              </v-btn>
            </v-row>
            <v-row no-gutters>
              <v-select
                v-model="editService.icon"
                :items="icons"
                label="Map Marker"
                variant="outlined"
                density="compact"
                rounded="0"
                item-value="value"
                item-title="text"
                :menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                id="editTypeIcon"
              >
                <template v-slot:item="{ item }">
                  <v-list-item
                    :title="item.title"
                    :prepend-icon="'mdi-' + item.value"
                    @click="editService.icon = item.value"
                  >
                  </v-list-item>
                </template>

                <template v-slot:selection="{ item }">
                  <v-row>
                    <v-icon>{{ "mdi-" + item.value }}</v-icon>
                    <div>{{ item.title }}</div>
                  </v-row>
                </template>
              </v-select>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" lg="2" md="2" sm="3">
                <v-img
                  :width="75"
                  :height="105"
                  :src="require('@/assets/minMap.png')"
                  class="mb-5"
                >
                  <v-icon
                    :style="
                      'left:13px;top:14px;font-size: 50px; color: ' +
                      editService.colour
                    "
                    >{{ "mdi-" + editService.icon }}</v-icon
                  ></v-img
                >
              </v-col>
              <v-col cols="12" lg="2" md="2" sm="3">
                <v-btn
                  tile
                  color="primary"
                  class="ml-1"
                  style="height: 40px"
                  aria-label="edit colour"
                  @click="toggleEditColour"
                >
                  <v-icon center>mdi-palette</v-icon>
                </v-btn>

                <v-expand-x-transition>
                  <v-card
                    v-show="editColour"
                    ref="colourCard"
                    :style="{
                      position: 'absolute',
                      zIndex: 1000,
                      top: '50px',
                      left: '0',
                      right: '0',
                      margin: '0 auto',
                    }"
                    max-width="300"
                    @focusin="addEscapeListener"
                    @focusout="removeEscapeListener"
                    tabindex="0"
                  >
                    <v-btn
                      tile
                      prepend-icon="mdi-close"
                      variant="text"
                      @click="editColour = false"
                    >
                      Close
                    </v-btn>

                    <v-color-picker
                      v-model="editService.colour"
                      show-swatches
                    ></v-color-picker>
                  </v-card>
                </v-expand-x-transition>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-textarea
                v-model="editService.description"
                label="Description"
                variant="outlined"
                density="compact"
                rounded="0"
                class="fields"
                id="editTypeDescription"
              ></v-textarea>
            </v-row>

            <v-row no-gutters>
              <v-checkbox
                v-model="editService.show_on_map"
                label="Show on map by default"
                color="success"
                density="compact"
                rounded="0"
                class="mb-1"
                id="editTypeShowOnMap"
              ></v-checkbox>
            </v-row>
            <v-row
              no-gutters
              v-if="$store.state.config.siteConfig.is_public_site"
            >
              <v-checkbox
                v-model="editService.show_on_public_site"
                label="Show on public site"
                color="success"
                density="compact"
                rounded="0"
                class="mb-5"
                id="editTypeShowOnPublicSite"
              ></v-checkbox>
            </v-row>
            <v-row no-gutters>
              <v-btn
                tile
                color="warning"
                @click="editDialog = false"
                aria-label="cancel"
                id="cancelEditServiceType"
                >cancel</v-btn
              >
              <v-spacer />
              <v-btn
                :disabled="!isFormValid"
                tile
                color="success"
                @click="update(editService)"
                :loading="saving"
                aria-label="save changes"
                id="saveEditServiceType"
              >
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span> </template
                >save changes</v-btn
              >
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card
      elevation="0"
      :style="'height: ' + (height - 224) + 'px; overflow-y: auto'"
    >
      <v-row v-if="!items.length">
        <v-col cols="12" lg="8" md="10" sm="12">
          <v-alert
            text
            icon="mdi-alert-circle"
            prominent
            color="info"
            class="ma-5"
          >
            <v-row>
              <v-col class="grow">{{
                loading
                  ? "Loading Services..."
                  : "You currently have no Services imported."
              }}</v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>

      <Draggable
        v-model="items"
        item-key="id"
        :animation="300"
        @change="updateOrder"
        :options="{ scroll: true }"
      >
        <template v-slot:item="{ element, index }">
          <v-list-item
            class="service-items"
            @click="selectService(element)"
            style="cursor: pointer"
            height="85px"
            tabindex="-1"
            id="serviceType"
          >
            <template #prepend>
              <v-btn
                variant="text"
                icon="mdi-drag"
                :id="'dragIcon_' + index"
                aria-label="drag to re-order"
                label="drag to re-order"
                title="drag to re-order"
                @keydown="updateOrderByKey($event, index)"
              />
              <v-avatar class="ml-3">
                <v-icon :color="element.colour" size="x-large">
                  {{ "mdi-" + element.icon }}
                </v-icon>
              </v-avatar>
            </template>

            <div>
              <div class="text-start mb-1">
                <v-icon aria-label="edit" class="text-h5 mr-2"
                  >mdi-pencil-outline</v-icon
                >
                <span id="typeName">{{ element.name }}</span>
              </div>
              <div v-if="element.description" class="text-start mb-1 ml-4">
                <v-card-subtitle id="typeDescription">{{
                  element.description
                }}</v-card-subtitle>
              </div>
            </div>
          </v-list-item>
        </template>
      </Draggable>
    </v-card>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import { useDisplay } from "vuetify";

export default {
  name: "ManageServiceTypes",
  data: () => ({
    height: useDisplay().height,
    editColour: false,
    loading: true,
    isFormValid: false,
    maxchars: (v) =>
      v.length <= 40 || "Too many character! Maximum limit of 40 please.",
    minchars: (v) =>
      v.length === 0 ? "Service Types must have a name." : true,
    nameError: null,
    confirmDeletion: false,
    saving: false,
    editService: null,
    items: [],
    itemKeyMoveInterval: null,
    editDialog: false,
    icons: null,
    loadingTypes: false,
    breakpoint: useDisplay().name,
  }),
  components: {
    Draggable,
  },
  props: {
    refreshServiceTypes: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getAllServiceTypes();
    this.fetchIcons();
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside, true);
  },
  methods: {
    handleClickOutside(event) {
      // Check if the click is outside the colourCard and editColour is true
      if (
        this.editColour &&
        this.$refs.colourCard &&
        !this.$refs.colourCard.$el.contains(event.target)
      ) {
        this.editColour = false;
      }
    },
    updateOrderByKey(event, index) {
      clearTimeout(this.itemKeyMoveInterval);

      let direction = false;

      switch (event.keyCode) {
        case 38:
          direction = "up";
          break;
        case 40:
          direction = "down";
          break;
      }

      if (direction) {
        this.items = this.reorderArray(this.items, index, direction);
        this.$nextTick(() => {
          const newIndex = direction === "up" ? index - 1 : index + 1;
          const elementId =
            newIndex < 0 || newIndex >= this.items.length
              ? `dragIcon_${index}`
              : `dragIcon_${newIndex}`;

          document.getElementById(elementId).focus();
        });

        // give it a second to update the key
        this.itemKeyMoveInterval = setTimeout(() => {
          this.updateOrder();
        }, 1000);
      }
    },
    reorderArray(arr, index, direction) {
      if (index < 0 || index >= arr.length) {
        return arr;
      }
      if (direction !== "up" && direction !== "down") {
        return arr;
      }
      if (direction === "up" && index === 0) {
        return arr;
      }
      if (direction === "down" && index === arr.length - 1) {
        return arr;
      }
      const elementToMove = arr.splice(index, 1)[0];
      if (direction === "up") {
        arr.splice(index - 1, 0, elementToMove);
      } else if (direction === "down") {
        arr.splice(index + 1, 0, elementToMove);
      }
      return arr;
    },
    toggleEditColour() {
      this.editColour = !this.editColour;
      if (this.editColour) {
        this.$nextTick(() => {
          this.addEscapeListener();
        });
      } else {
        this.removeEscapeListener();
      }
    },
    handleEscapeKey(event) {
      if (event.key === "Escape") {
        this.editColour = false;
        this.removeEscapeListener();
      }
    },
    addEscapeListener() {
      window.addEventListener("keyup", this.handleEscapeKey);
    },
    removeEscapeListener() {
      window.removeEventListener("keyup", this.handleEscapeKey);
    },
    deleteServiceType() {
      this.saving = true;
      this.$axios
        .delete("/service-type/" + this.editService.id)
        .then((response) => {
          this.items = response.data;
          this.emit.emit("systemMessage", {
            title: "Success!",
            message: this.editService.name + " Service Type Deleted",
            timeout: 3000,
            colour: "green",
          });
          this.saving = false;
          this.confirmDeletion = false;
          this.editDialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.saving = false;
          this.confirmDeletion = false;
          this.emit.emit("systemMessage", {
            message: error.data.message,
            title: "Failed to Delete Service Type",
            timeout: -1,
            colour: "red",
          });
        });
    },
    selectService(item) {
      this.editService = JSON.parse(JSON.stringify(item));
      this.editDialog = true;
    },
    update() {
      this.saving = true;
      (this.nameError = null),
        // make the call!
        this.$axios
          .put("/update-service", this.editService)
          .then((response) => {
            this.emit.emit("systemMessage", {
              title: "Update Complete",
              message: "Success!",
              timeout: 3000,
              colour: "green",
            });
            for (var i = 0; i < this.items.length; i++) {
              if (this.items[i].id === this.editService.id) {
                this.items[i] = response.data;
                break;
              }
            }
            this.saving = false;
            this.editDialog = false;
          })
          .catch((error) => {
            this.saving = false;
            if (typeof error.response.data.errors !== "undefined") {
              if (typeof error.response.data.errors.name !== "undefined") {
                this.nameError = error.response.data.errors.name[0];
              }
            }
            this.emit.emit("systemMessage", {
              title: "Please check fields for errors",
              message: "Update Failed",
              timeout: -1,
              colour: "red",
            });
          });
    },
    updateOrder() {
      let order = [];
      this.items.forEach((item, index) => {
        item.order = index + 1;
        order.push({ id: item.id, order: item.order });
      });

      this.$axios
        .put("/update-services-order", order)
        .then(() => {
          this.emit.emit("systemMessage", {
            title: "Update Complete",
            message: "Success!",
            timeout: 1000,
            colour: "green",
          });
        })
        .catch((error) => {
          this.emit.emit("systemBusy", false);
          this.emit.emit(
            "systemMessage",
            error.response.data,
            "Update Failed",
            0,
            "red",
          );
        });
    },
    getAllServiceTypes() {
      this.loading = true;
      this.emit.emit("systemBusy", true);
      this.loadingTypes = true;

      this.$axios
        .get("/get-all-service-types")
        .then((response) => {
          this.emit.emit("systemBusy", false);
          this.loadingTypes = true;
          this.items = response.data;
          this.items.sort((a, b) => a.order - b.order);
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loadingTypes = true;
          this.emit.emit("systemBusy", false);
          this.loading = false;
        });
    },
    fetchIcons() {
      this.$axios
        .get("/get-map-markers")
        .then((response) => {
          this.icons = response.data;
        })
        .catch((error) => {
          console.error(error);
          this.emit.emit("systemBusy", false);
        });
    },
  },
  watch: {
    editColour(newValue) {
      if (newValue) {
        document.addEventListener("click", this.handleClickOutside, true);
      } else {
        document.removeEventListener("click", this.handleClickOutside, true);
      }
    },
    editService: {
      handler() {
        this.nameError = null;
      },
    },
    refreshServiceTypes(newVal) {
      if (newVal) {
        this.getAllServiceTypes();
      }
    },
  },
};
</script>

<style scoped>
.list-item {
  cursor: grab;
}
.service-items {
  cursor: grab;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  transition: background-color 0.3s;
}
.icon-list {
  font-size: 40px;
}
</style>
